import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import BookAppointmentModal from "../sections/BookAppointment";
import { SearchIcon, CheckIcon } from "@chakra-ui/icons";
import LeftSideBar from "../authLayouts/LeftSideBar";
import {
  ChakraProvider,
  VStack,
  Button,
  Image,
  Box,
  Text,
  Flex,
  extendTheme,
  Tabs,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Skeleton,
} from "@chakra-ui/react";
import familyIcon from "../../assets/Family.svg";
import SearchAppointmentsModal from "../sections/SearchAppointmentByDate";
import NavBar from "../authLayouts/NavBar";
import AppointmentTab from "../authLayouts/AllAppointmentTab";
import PendingApp from "../authLayouts/PendingAppointmentTab";
import MobileFooter from "../authLayouts/MobileFooter";
import ActiveApp from "../authLayouts/ActiveAppTab";
import CompletedApp from "../authLayouts/CompletedAppTab";
import { useSwipeable } from "react-swipeable";

const customTheme = extendTheme({
  components: {
    Link: {
      baseStyle: {
        _focus: {
          boxShadow: "none",
        },
      },
    },
  },
  fonts: {
    body: "Montserrat, sans-serif",
    heading: "Gill Sans MT, sans-serif",
  },
});

const AppointmentPage = () => {
  const [loading, setLoading] = useState(true);
  const { user } = useSelector((state) => state.userReducer);
  const [showAppointmentModal, setShowAppointmentModal] = useState(false);
  const [showSearchAppointmentsModal, setShowSearchAppointmentsModal] =
    useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const handleOpenAppointmentModal = () => {
    setShowAppointmentModal(true);
  };

  const handleOpenSearchAppointmentsModal = () => {
    setShowSearchAppointmentsModal(true);
  };

  const handleCloseSearchAppointmentsModal = () => {
    setShowSearchAppointmentsModal(false);
  };

  const handleCloseAppointmentModal = () => {
    setShowAppointmentModal(false);
  };

  const settingsContainerStyle = {
    animation: "slideInUp 0.9s ease-in-out",
  };

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => setTabIndex((prev) => (prev + 1) % 4),
    onSwipedRight: () => setTabIndex((prev) => (prev - 1 + 4) % 4),
    trackMouse: true, // Optionally enable mouse swipe for testing on desktop
  });

  return (
    <ChakraProvider theme={customTheme}>
      <Flex height="100vh" overflow="hidden">
        <LeftSideBar />
        <VStack
          style={settingsContainerStyle}
          ml={{ md: "260px", lg: "310px" }}
          w={{ base: "100%", md: "80%" }}
          h="100vh"
          {...swipeHandlers} // Add swipe handlers here
        >
          <Box w="100%" position="sticky" top="0" zIndex="1000">
            <NavBar />
          </Box>
          <Box
            w="100%"
            h="100%"
            overflowY="auto"
            overflowX={{ base: "auto", md: "hidden" }}
            paddingX="1rem"
            paddingBottom="1rem"
          >
            {loading ? (
              <Skeleton
                justifyContent="center"
                w={{ base: "375px", md: "70vw" }}
                h={{ base: "189px", md: "40vh" }}
                startColor="#E552FF"
                endColor="#870DA5"
                fadeDuration={0.6}
                borderRadius="20px"
              />
            ) : (
              <Box>
                <Box
                  marginTop="10px"
                  border="1px solid gray"
                  borderRadius="md"
                  padding="3px"
                  w={{ base: "88vw", md: "908px" }}
                  h={{ base: "7vw", md: "5vh" }}
                  sx={{
                    "@media screen and (max-width: 736px) and (max-height: 414px)": {
                      h: "7vh",
                    },
                  }}
                >
                  <Flex>
                    <SearchIcon
                      boxSize={4}
                      marginRight="10px"
                      marginTop="5px"
                    />
                    <Text
                      fontSize={{ base: "10px", md: "14px" }}
                      mt={{ base: "3px" }}
                      fontFamily="body"
                      style={{
                        fontStyle: "italic",
                        cursor: "pointer",
                      }}
                      _hover={{ color: "#A210C6" }}
                      onClick={handleOpenSearchAppointmentsModal}
                    >
                      Search Appointment by date
                    </Text>
                  </Flex>
                </Box>
                <Flex
                  className="componentBox"
                  bg="linear-gradient(80deg, #A210C6, #E552FF)"
                  ml={{ base: "-5px" }}
                  w={{ base: "90vw", md: "910px" }}
                  h={{
                    base: "20vh",
                    md: "200px",
                  }}
                  mt={{ base: "5px", md: "15px" }}
                  borderRadius="20px"
                  justifyContent="space-between"
                  boxShadow="lg"
                  p="4"
                  sx={{
                    "@media screen and (max-width: 736px) and (max-height: 414px)": {
                      h: "32vh",
                    },
                  }}
                >
                  <Box
                    pt={{ base: "5px", md: "10px" }}
                    pb={{ base: "5px", md: "10px" }}
                    justify="left"
                    color="white"
                  >
                    <Text
                      fontSize={{ base: "12px", md: "20px" }}
                      fontFamily="heading"
                      marginTop={{ base: "0", md: "15px" }}
                      ml={{ base: "30px", md: "60px" }}
                      textAlign="left"
                    >
                      Hello {user?.firstName},
                    </Text>
                    <Text
                      fontFamily="body"
                      fontSize={{ base: "10px", md: "15px" }}
                      ml={{ base: "30px", md: "60px" }}
                      mt={{ md: "5px" }}
                      textAlign="left"
                    >
                      Would you like to book an appointment <br /> for yourself
                      or a loved one?
                    </Text>

                    <Button
                      onClick={handleOpenAppointmentModal}
                      bg="white"
                      color="#A210C6"
                      fontFamily="body"
                      mt={{ base: "15px", md: "30px" }}
                      _hover={{ color: "" }}
                      padding={{ base: "5px", md: "0" }}
                      w={{ base: "140px", md: "190px" }}
                      h={{ base: "25px", md: "40px" }}
                      fontSize={{ base: "12px", md: "16px" }}
                      borderRadius="15px"
                      ml={{ base: "-10px", md: "0px" }}
                      leftIcon={<CheckIcon />}
                    >
                      Book appointment
                    </Button>
                  </Box>
                  <Box mr={{ md: "20px" }}>
                    <Image
                      src={familyIcon}
                      alt="family icon"
                      w={{ base: "80px", md: "150px" }}
                      h={{ base: "80px", md: "150px" }}
                      mt={{ base: "15px", md: "30px" }}
                      mb={{ base: "0", md: "50px" }}
                      mr={{ base: "30px", md: "30px" }}
                    />
                  </Box>
                </Flex>

                <Flex
                  w={{ base: "90vw", md: "65vw" }}
                  p="4px"
                  mt={{ base: "-5px", md: "-5px" }}
                  justifyContent="center"
                  className="appointment-tabs"
                  overflow={{ base: "hidden", md: "hidden" }}
                >
                  <VStack w="90vw">
                    <Tabs
                      mb="20px"
                      colorScheme="purple.100"
                      mt={{ base: "", md: "5px" }}
                      index={tabIndex} // Set the current tab index
                      onChange={setTabIndex} // Update the tab index on change
                    >
                      <TabList justifyContent="space-between">
                        <Tab
                          fontSize={{ base: "11px", md: "16px" }}
                          color="#A210C6"
                          fontWeight="bold"
                        >
                          All
                        </Tab>

                        <Tab
                          fontSize={{ base: "11px", md: "16px" }}
                          color="yellow.500"
                          fontWeight="bold"
                        >
                          Pending
                        </Tab>

                        <Tab
                          fontSize={{ base: "11px", md: "16px" }}
                          color="green.500"
                          fontWeight="bold"
                        >
                          Active
                        </Tab>

                        <Tab
                          fontSize={{ base: "11px", md: "16px" }}
                          color="blue.500"
                          fontWeight="bold"
                        >
                          Completed
                        </Tab>
                      </TabList>
                      <TabPanels ml={{ base: "0px", md: "0px" }}>
                        <TabPanel>
                          <AppointmentTab />
                        </TabPanel>
                        <TabPanel>
                          <PendingApp />
                        </TabPanel>
                        <TabPanel>
                          <ActiveApp />
                        </TabPanel>
                        <TabPanel>
                          <CompletedApp />
                        </TabPanel>
                      </TabPanels>
                    </Tabs>
                  </VStack>
                </Flex>

                <BookAppointmentModal
                  isOpen={showAppointmentModal}
                  onClose={handleCloseAppointmentModal}
                />
                <SearchAppointmentsModal
                  isOpen={showSearchAppointmentsModal}
                  onClose={handleCloseSearchAppointmentsModal}
                />
              </Box>
            )}
            <MobileFooter />
          </Box>
        </VStack>
      </Flex>
    </ChakraProvider>
  );
};

export default AppointmentPage;
