import React, { useState } from "react";
import axios from "axios";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Box,
  Button,
  Flex,
  Divider,
  Image,
  extendTheme,
  useToast,
  useBreakpointValue,
  Stack,
} from "@chakra-ui/react";
import { FaStar } from "react-icons/fa";
import { baseUrl } from "../../apiCalls/config";

const customTheme = extendTheme({
  components: {
    Link: {
      baseStyle: {
        _focus: {
          boxShadow: "none",
        },
      },
    },
  },
  fonts: {
    body: "Montserrat, sans-serif",
    heading: "Gill Sans MT, sans-serif",
  },
});

const MatchedAppointmentsModal = ({ isOpen, onClose, matchedAppointments }) => {
  const toast = useToast();
  const modalSize = useBreakpointValue({ base: "full", md: "3xl" });
  const [appointmentDetails, setAppointmentDetails] = useState(null);
  const [loadingAppointmentId, setLoadingAppointmentId] = useState(null);

  const handleAcceptAppointment = async (appointmentId, medicId, customerId) => {
    setLoadingAppointmentId(appointmentId); 
    try {
      const token = localStorage.getItem("token");
      const apiUrl = `${baseUrl}/appointment/accept`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.post(
        apiUrl,
        { appointmentId, medicId, customerId },
        { headers }
      );
  
      if (response.data.success) {
        toast({
          title: response.data.message,
          status: "success",
          duration: 6000,
          position: "top-right"
        });
        setAppointmentDetails(response.data.data);
        onClose();
      } else {
        if (response.data.message === "You already accepted this medic.") {
          toast({
            title: "Warning",
            description: response.data.message,
            status: "warning",
            duration: 6000,
            position: "top-left"
          });
        } else {
          toast({
            title: "Error accepting appointment",
            description: response.data.message,
            status: "error",
            duration: 6000,
            position: "top-left"
          });
        }
      }
    } catch (error) {
      console.error("An error occurred while accepting the appointment:", error);
  
      const errorMessage = error.response?.data?.message || "An error occurred. Please try again.";
      if (errorMessage === "You already accepted this medic.") {
        toast({
          title: "Warning",
          description: "You already accepted this medic.",
          status: "warning",
          duration: 6000,
          position: "top-left"
        });
      } else {
        toast({
          title: "Error accepting appointment",
          description: errorMessage,
          status: "error",
          duration: 6000,
          position: "top-left"
        });
      }
    } finally {
      setLoadingAppointmentId(null);
    }
  };
  

  return (
    <>
      <Modal
        theme={customTheme}
        isOpen={isOpen}
        onClose={onClose}
        size={modalSize}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center">
            Your Appointment(s) have been matched
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {matchedAppointments &&
              matchedAppointments.length > 0 &&
              matchedAppointments.map((appointmentWrapper) => (
                <Box
                  key={appointmentWrapper.appointment.id}
                  p="5"
                  borderWidth="1px"
                  borderRadius="md"
                  mb="4"
                >
                  <Flex
                    direction={{ base: "column", md: "row" }}
                    justifyContent="space-between"
                  >
                    <Box>
                      <Flex marginTop="5px">
                        <Text fontWeight="bold" color="black">
                          Beneficiary:
                        </Text>
                        <Text marginLeft="5px" color="black">
                          {`${appointmentWrapper.appointment.customerAppointment.recipientFirstname} ${appointmentWrapper.appointment.customerAppointment.recipientLastname}`}
                        </Text>
                      </Flex>
                      <Flex marginTop="5px">
                        <Text fontWeight="bold" color="black">
                          Service Plan:
                        </Text>
                        <Text marginLeft="5px" color="black">
                          {
                            appointmentWrapper.appointment.customerAppointment
                              .servicePlan
                          }
                        </Text>
                      </Flex>
                      <Flex marginTop="5px">
                        <Text fontWeight="bold" color="black">
                          Caegiver Type:
                        </Text>
                        <Text marginLeft="5px" color="black">
                          {
                            appointmentWrapper.appointment.customerAppointment
                              .medicSpecialization
                          }
                        </Text>
                      </Flex>
                      <Flex marginTop="5px">
                        <Text fontWeight="bold" color="black">
                          Preferred Gender:
                        </Text>
                        <Text marginLeft="5px" color="black">
                          {
                            appointmentWrapper.appointment.customerAppointment
                              .preferredMedicGender
                          }
                        </Text>
                      </Flex>
                      <Flex marginTop="5px">
                        <Text fontWeight="bold" color="black">
                          Preferred Religion:
                        </Text>
                        <Text marginLeft="5px" color="black">
                          {
                            appointmentWrapper.appointment.customerAppointment
                              .preferredReligion
                          }
                        </Text>
                      </Flex>
                      <Flex marginTop="5px">
                        <Text fontWeight="bold" color="black">
                          Preferred Language/Tribe:
                        </Text>
                        <Text marginLeft="5px" color="black">
                          {
                            appointmentWrapper.appointment.customerAppointment
                              .preferredLanguage
                          }
                        </Text>
                      </Flex>
                      <Flex marginTop="5px">
                        <Text fontWeight="bold" color="black">
                          Booked on:
                        </Text>
                        <Text marginLeft="5px" color="black">
                          {new Date(
                            appointmentWrapper.appointment.customerAppointment.createdAt
                          ).toLocaleString()}
                        </Text>
                      </Flex>
                    </Box>
                  </Flex>
                  <Divider my={4} borderColor="gray.500" />
                  <Text fontWeight="bold" color="black" marginTop="5px">
                    Available Caregiver(s):
                  </Text>
                  {appointmentWrapper.matchedMedics.map((medic) => (
                    <Box
                      key={medic.id}
                      p="4"
                      borderWidth="1px"
                      borderRadius="md"
                      mt="5px"
                    >
                      <Flex
                        direction={{ base: "column", md: "row" }}
                        alignItems="center"
                      >
                        <Box flex="1">
                          <Flex marginTop="5px">
                            <Text fontWeight="bold" color="black">
                              Full Name:
                            </Text>
                            <Text marginLeft="5px" color="black">
                              {medic.fullName}
                            </Text>
                          </Flex>
                          <Flex marginTop="5px">
                            <Text fontWeight="bold" color="black">
                              Caregiver Type:
                            </Text>
                            <Text marginLeft="5px" color="black">
                              {medic.specialization}
                            </Text>
                          </Flex>
                          <Flex marginTop="5px">
                            <Text fontWeight="bold" color="black">
                              Location:
                            </Text>
                            <Text marginLeft="5px" color="black">
                              {medic.currentLocation}
                            </Text>
                          </Flex>
                          {/* <Flex marginTop="5px">
                            <Text fontWeight="bold" color="black">
                              Years of Experience:
                            </Text>
                            <Text marginLeft="5px" color="black">
                              {medic.yearsOfExp}
                            </Text>
                          </Flex> */}
                          <Flex marginTop="5px">
                            <Text fontWeight="bold" color="black">
                              Religion:
                            </Text>
                            <Text marginLeft="5px" color="black">
                              {medic?.preferredReligion}
                            </Text>
                          </Flex>
                          <Flex marginTop="5px">
                            <Text fontWeight="bold" color="black">
                              Gender:
                            </Text>
                            <Text marginLeft="5px" color="black">
                              {medic?.gender}
                            </Text>
                          </Flex>
                          <Flex marginTop="5px">
                            <Text fontWeight="bold" color="black">
                              Language:
                            </Text>
                            <Text marginLeft="5px" color="black">
                              {medic.preferredLanguage}
                            </Text>
                          </Flex>
                          <Flex marginTop="5px" alignItems="center">
                            <Text fontWeight="bold" color="black">
                              Rating:
                            </Text>
                            <Flex marginLeft="5px" color="gold">
                              {[...Array(5)].map((_, i) => (
                                <FaStar key={i} />
                              ))}
                            </Flex>
                          </Flex>
                        </Box>

                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          display={{ base: "flex", md: "none" }}
                          spacing={10}
                        >
                          <Image
                            src={medic.image}
                            alt={medic.fullName}
                            boxSize={{ base: "100px", md: "150px" }}
                            borderRadius="100px"
                            mt={{ base: "10px", md: "0" }}
                          />
                          <Button
                            isLoading={
                              loadingAppointmentId ===
                              appointmentWrapper.appointment.id
                            }
                            loadingText="Loading..."
                            colorScheme="green"
                            onClick={() =>
                              handleAcceptAppointment(
                                appointmentWrapper.appointment.id,
                                medic.medicId,
                                appointmentWrapper.appointment
                                  .customerAppointment.customerId 
                              )
                            }
                            mt="10px"
                          >
                            Accept
                          </Button>
                        </Stack>

                        <Box
                          display={{ base: "none", md: "block" }}
                          textAlign="center"
                        >
                          <Image
                            src={medic.image}
                            alt={medic.fullName}
                            boxSize={{ base: "100px", md: "150px" }}
                            borderRadius="100px"
                            mt={{ base: "10px", md: "0" }}
                          />
                          <Button
                            isLoading={
                              loadingAppointmentId ===
                              appointmentWrapper.appointment.id
                            }
                            loadingText="Loading..."
                            colorScheme="green"
                            onClick={() =>
                              handleAcceptAppointment(
                                appointmentWrapper.appointment.id,
                                medic.medicId
                              )
                            }
                            mt="10px"
                          >
                            Accept
                          </Button>
                        </Box>
                      </Flex>
                    </Box>
                  ))}
                </Box>
              ))}
          </ModalBody>
        </ModalContent>
      </Modal>

      {appointmentDetails && (
        <Modal
          isOpen={true}
          onClose={() => setAppointmentDetails(null)}
          size={{ base: "sm", md: "md" }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              color="#A210C6"
              fontFamily="heading"
              textAlign="center"
            >
              Notification!
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text
                textAlign="center"
                fontSize="lg"
                fontWeight="bold"
                fontFamily="body"
                color="green.500"
              >
                Congratulations! You have accepted a Caregiver.
              </Text>
              <Text
                fontFamily="body"
                textAlign="center"
                fontSize="md"
                color="gray.700"
                mt="2"
              >
                Please wait while the caregiver confirms the appointment.
              </Text>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default MatchedAppointmentsModal;
