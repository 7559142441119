import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  InputGroup,
  InputLeftElement,
  Th,
  Td,
  Center,
  Avatar,
  Spinner,
} from "@chakra-ui/react";
import axios from "axios";
import { SearchIcon } from "@chakra-ui/icons";
import MedicDetailsDrawer from "./MedicDetailsDrawer ";
import { baseUrl } from "../../../apiCalls/config";

const getInitials = (name) => {
  const names = name.split(" ");
  const initials = names.map((n) => n[0]).join("");
  return initials.toUpperCase();
};

const UnverifiedMedics = () => {
  const [medics, setMedics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchEmail, setSearchEmail] = useState("");
  const [selectedMedic, setSelectedMedic] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); 

  const fetchMedics = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, redirecting to login.");
      window.location.href = "/login";
      return;
    }
  
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
  
      const response = await axios.get(
        `${baseUrl}/api/admin/medics/unverified`,
        config
      );
      const data = response.data.data.content.map((item) => ({
        id: item.id,
        name: `${item.bioData.firstName} ${item.bioData.lastName}`,
        phoneNumber: item.phoneNumber,
        email: item.bioData.email,
        gender: item.bioData.gender,
        medicType: item.medicType,
        image: item.bioData.image,
        fullDetails: item,
        yearsOfExp: item.yearsOfExp,
      }));
      setMedics(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching medics:", error);
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchMedics();
  }, []);

  useEffect(() => {
    if (searchEmail.trim() === "") {
      fetchMedics();
    }
  }, [searchEmail]);

  const handleSearch = async () => {
    if (!searchEmail.trim()) return;
  
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, redirecting to login.");
      window.location.href = "/login";
      return;
    }
  
    setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
  
      let response;
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
      // Check if search term is an email
      if (emailPattern.test(searchEmail)) {
        // Search by email
        response = await axios.get(
          `${baseUrl}/api/admin/medics/search?identifier=${searchEmail}`,
          config
        );
      } else {
        // Assume the search term is a recipient name (first name and last name)
        const [firstName, lastName] = searchEmail.split(" ");
        response = await axios.get(`${baseUrl}/api/admin/findByMedicName`, {
          params: {
            firstName: firstName || "",
            lastName: lastName || "",
          },
          ...config,
        });
      }
  
      // Extract and set the search results, assuming it might return an array
      const data = response.data.data;
      if (Array.isArray(data)) {
        // If it's an array of medics
        const medics = data.map((item) => ({
          id: item.id,
          name: `${item.bioData.firstName} ${item.bioData.lastName}`,
          phoneNumber: item.bioData.phoneNumber,
          email: item.bioData.email,
          gender: item.bioData.gender,
          medicType: item.medicType,
          image: item.bioData.image,
          fullDetails: item,
          matchedToAppointment: item.matchedToAppointment,
        }));
        setMedics(medics);
      } else if (data) {
        // If it's a single medic object
        const medic = {
          id: data.id,
          name: `${data.bioData.firstName} ${data.bioData.lastName}`,
          phoneNumber: data.bioData.phoneNumber,
          email: data.bioData.email,
          gender: data.bioData.gender,
          medicType: data.medicType,
          image: data.bioData.image,
          fullDetails: data,
          matchedToAppointment: data.matchedToAppointment,
        };
        setMedics([medic]);
      }
    } catch (error) {
      console.error("Error searching medic:", error);
    } finally {
      setLoading(false);
    }
  };  

  const openDrawer = (medic) => {
    setSelectedMedic(medic);
    setIsDrawerOpen(true);
  };

  const handleRowClick = (medic) => {
    openDrawer(medic.fullDetails);
  };

 
  return (
    <Box bg="#4B4B4B" borderRadius="10px" p={4} color="white" w="100%">
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Text fontSize="md" fontWeight="bold">
          Unverified Medics
        </Text>
      </Flex>
      <Flex mb={4}>
        <InputGroup>
          <Input
            placeholder="Search medic by name, or email"
            backgroundColor="#4B4B4B"
            color="white"
            borderRadius="10px"
            width="500px"
            value={searchEmail}
            onChange={(e) => setSearchEmail(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleSearch();
            }}
          />
          <InputLeftElement
            children={<SearchIcon color="white" />}
            pointerEvents="none"
          />
        </InputGroup>
        <Box ml={4}>
          <Text
            as="button"
            bg="#00C6F7"
            color="white"
            borderRadius="10px"
            fontSize="12px"
            p={2}
            onClick={handleSearch}
          >
            Search
          </Text>
        </Box>
      </Flex>
      {loading ? (
        <Flex justifyContent="center" alignItems="center" h="200px">
          <Spinner color="#00C6F7" />
        </Flex>
      ) : (
        <Box maxH="400px" overflowY="auto">
          <Table variant="simple" colorScheme="whiteAlpha">
            <Thead
              css={{
                position: "sticky",
                top: 0,
                background: "#4B4B4B",
                zIndex: 1,
              }}
            >
              <Tr>
                <Th color="purple.200">Avatar</Th>
                <Th color="purple.200">Name</Th>
                <Th color="purple.200">Phone Number</Th>
                <Th color="purple.200">Email</Th>
                <Th color="purple.200">Gender</Th>
                <Th color="purple.200">Medic Type</Th>
                <Th color="purple.200">Years of Experience</Th>
              </Tr>
            </Thead>
            <Tbody>
              {medics.map((medic, index) => (
                <Tr
                  fontSize="13px"
                  _hover={{ bg: "#3B3B3B" }}
                  key={index}
                  onClick={() => handleRowClick(medic)}
                  cursor="pointer"
                >
                  <Td>
                    {medic.image ? (
                      <Avatar
                        borderRadius="full"
                        boxSize="40px"
                        src={medic.image}
                        alt={medic.name}
                      />
                    ) : (
                      <Center
                        borderRadius="full"
                        boxSize="40px"
                        bg="white"
                        color="black"
                        fontWeight="bold"
                      >
                        {getInitials(medic.name)}
                      </Center>
                    )}
                  </Td>
                  <Td>{medic.name}</Td>
                  <Td>{medic.phoneNumber}</Td>
                  <Td>{medic.email}</Td>
                  <Td>{medic.gender}</Td>
                  <Td>{medic.medicType}</Td>
                  <Td>{medic.yearsOfExp}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}
      {selectedMedic && (
        <MedicDetailsDrawer
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          medic={selectedMedic}
        />
      )}
    </Box>
  );
};

export default UnverifiedMedics;
