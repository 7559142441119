import React, { useState } from "react";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Avatar,
  Text,
  VStack,
  Divider,
  Flex,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Select,
} from "@chakra-ui/react";
import EditCustomerModal from "./EditCustomerModal";
import SuspendCustomerModal from "./SuspendCustomerModal";
import axios from "axios";
import { baseUrl } from "../../../apiCalls/config";

const AdminDetailsDrawer = ({ isOpen, onClose, admin, onUpdateAdmin }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isSuspendModalOpen, setIsSuspendModalOpen] = useState(false);
  const [isUpdateRoleModalOpen, setIsUpdateRoleModalOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [isSuspended, setIsSuspended] = useState(admin.bioData.suspended);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const handleOpenEditModal = () => {
    setIsEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
  };

  const handleOpenSuspendModal = () => {
    setIsSuspendModalOpen(true);
  };

  const handleCloseSuspendModal = () => {
    setIsSuspendModalOpen(false);
  };

  const handleOpenUpdateRoleModal = () => {
    setIsUpdateRoleModalOpen(true);
  };

  const handleCloseUpdateRoleModal = () => {
    setIsUpdateRoleModalOpen(false);
  };

  const handleSuspensionChange = (newSuspendedState) => {
    setIsSuspended(newSuspendedState);
    onUpdateAdmin({
      ...admin,
      bioData: {
        ...admin.bioData,
        suspended: newSuspendedState,
      },
    });
    toast({
      title: `Admin ${newSuspendedState ? "Suspended" : "Unsuspended"}`,
      description: `The admin has been ${
        newSuspendedState ? "suspended" : "unsuspended"
      } successfully.`,
      status: "success",
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  const handleUpdateRole = async () => {
    if (!selectedRole) {
      toast({
        title: "Select a role",
        description: "Please select a role to update.",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    setIsLoading(true);

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, redirecting to login.");
      window.location.href = "/login";
      return;
    }

    try {
      const response = await axios.post(
        `${baseUrl}/api/admin/updateAdminRole/${admin.id}`,
        {},
        {
          params: { newRole: selectedRole },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        toast({
          title: "Role Updated",
          description: "Admin role has been updated successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });

        handleCloseUpdateRoleModal();
      } else {
        toast({
          title: "Update Failed",
          description: "Failed to update admin role. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (error) {
      toast({
        title: "Update Failed",
        description: "There was an error updating the admin role.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Drawer
        blockScrollOnMount={false}
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent bg="#4B4B4B" color="white">
          <DrawerCloseButton />
          <DrawerHeader>Admin Details</DrawerHeader>
          <DrawerBody>
            <VStack align="left" spacing={4}>
              <Box>
                <Flex justifyContent="center">
                  <Avatar
                    size="xl"
                    name={admin.name}
                    src={admin.bioData.image}
                    alt={`${admin.bioData.firstName} ${admin.bioData.lastName}`}
                  />
                </Flex>
                <Flex alignItems="center" justifyContent="space-between" mt={2}>
                  <Button
                    variant="outline"
                    colorScheme="yellow"
                    onClick={handleOpenUpdateRoleModal}
                  >
                    Update Role
                  </Button>
                </Flex>
              </Box>

              <Box>
                <Text fontSize="2xl" fontWeight="bold">
                  {admin.bioData.firstName} {admin.bioData.lastName}
                </Text>
                <Text>Email: {admin.bioData.email}</Text>
                <Text>Phone Number: {admin.bioData.phoneNumber}</Text>
                <Text>Gender: {admin.bioData.gender}</Text>
                <Text>
                  Date of Birth:{" "}
                  {admin.bioData.dob
                    ? new Date(admin.bioData.dob).toLocaleDateString()
                    : "N/A"}
                </Text>
                <Text>Role: {admin.bioData.roles.join(", ")}</Text>
                <Text>
                  Created At:{" "}
                  {new Date(admin.bioData.createdAt).toLocaleDateString()}
                </Text>
                <Text>
                  Updated At:{" "}
                  {admin.bioData.updatedAt
                    ? new Date(admin.bioData.updatedAt).toLocaleDateString()
                    : "N/A"}
                </Text>
                <Divider mt={4} />
                <Text fontSize="lg" fontWeight="bold" mt={4}>
                  Additional Information
                </Text>
                <Text>Active: {admin.active ? "Yes" : "No"}</Text>
                <Text>
                  Wallet Created: {admin.walletCreated ? "Yes" : "No"}
                </Text>
                <Text>
                  Transaction Pin Created:{" "}
                  {admin.transactionPinCreated ? "Yes" : "No"}
                </Text>
              </Box>
            </VStack>
          </DrawerBody>
          <DrawerFooter justifyContent="space-between">
            <Button
              variant="outline"
              colorScheme="blue"
              onClick={handleOpenEditModal}
            >
              Edit Admin Details
            </Button>
            <Button
              variant="outline"
              colorScheme={isSuspended ? "green" : "red"}
              onClick={handleOpenSuspendModal}
            >
              {isSuspended ? "Activate Admin" : "Suspend Admin"}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <EditCustomerModal
        isOpen={isEditModalOpen}
        onClose={handleCloseEditModal}
        customer={admin}
      />
      <SuspendCustomerModal
        isOpen={isSuspendModalOpen}
        onClose={handleCloseSuspendModal}
        customerId={admin.id}
        isSuspended={isSuspended}
        onSuspensionChange={handleSuspensionChange}
      />

      {/* Update Role Modal */}
      <Modal
        isOpen={isUpdateRoleModalOpen}
        onClose={handleCloseUpdateRoleModal}
      >
        <ModalOverlay />
        <ModalContent bg="#4B4B4B">
          <ModalHeader color="white">Update Admin Role</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Select
              placeholder="Select new role"
              value={selectedRole}
              onChange={(e) => setSelectedRole(e.target.value)}
            >
              <option value="SUPER_ADMIN">SUPER_ADMIN</option>
              <option value="ADMIN">ADMIN</option>
              <option value="ADMIN_SUPPORT">ADMIN_SUPPORT</option>
            </Select>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              onClick={handleUpdateRole}
              isLoading={isLoading}
              loadingText="Updating..."
            >
              Confirm Role Update
            </Button>
            <Button onClick={handleCloseUpdateRoleModal} ml={3}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AdminDetailsDrawer;
