import React, { useState, useEffect } from "react";
import { Box, Flex, Text, HStack } from "@chakra-ui/react";
import {
  AiOutlineUser,
  AiOutlineCalendar,
  AiOutlineCheckCircle,
  AiOutlineClockCircle,
  AiOutlineCloseCircle,
} from "react-icons/ai";  // Updated icons
import axios from "axios";
import { useSpring, animated } from "@react-spring/web";
import { baseUrl } from "../../../apiCalls/config";

const AppStatistics = () => {
  const [statistics] = useState([
    {
      id: 1,
      title: "All",
      count: 0,
      percentage: "0%",
      bgColor: "#00C6F7",
      lastMonth: "Last Month",
      icon: AiOutlineUser,
    },
    {
      id: 2,
      title: "Pending",
      count: 0,
      percentage: "0%",
      bgColor: "yellow.400",
      lastMonth: "Last Month",
      icon: AiOutlineClockCircle,  // Pending icon
    },
    {
      id: 3,
      title: "Active",
      count: 0,
      percentage: "0%",
      bgColor: "green.400",
      lastMonth: "Last Month",
      icon: AiOutlineCalendar,  // Active icon
    },
    {
      id: 4,
      title: "Completed",
      count: 0,
      percentage: "0%",
      bgColor: "linear-gradient(80deg, #A210C6, #E552FF)",
      lastMonth: "Last Month",
      icon: AiOutlineCheckCircle,  // Completed icon
    },
    {
      id: 5,
      title: "Canceled",
      count: 0,
      percentage: "0%",
      bgColor: "red.400",
      lastMonth: "Last Month",
      icon: AiOutlineCloseCircle,  // Canceled icon
    },
  ]);

  const [fetchedData, setFetchedData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found, redirecting to login.");
        window.location.href = "/login";
        return;
      }
  
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
  
        const response = await axios.get(`${baseUrl}/api/statistics`, config);
        const data = response.data[0];
  
        setFetchedData({
          totalAppointments: data.totalAppointments,
          pendingAppointments: data.pendingAppointments,
          activeAppointments: data.activeAppointments,
          completedAppointments: data.completedAppointments,
          canceledAppointments: data.canceledAppointments,
        });
      } catch (error) {
        console.error("Error fetching statistics:", error);
      }
    };
  
    fetchData();
  }, []);
  
  const totalAppointmentsSpring = useSpring({ from: { value: 0 }, value: fetchedData ? fetchedData.totalAppointments : 0 });
  const pendingAppointmentsSpring = useSpring({ from: { value: 0 }, value: fetchedData ? fetchedData.pendingAppointments : 0 });
  const activeAppointmentsSpring = useSpring({ from: { value: 0 }, value: fetchedData ? fetchedData.activeAppointments : 0 });
  const completedAppointmentsSpring = useSpring({ from: { value: 0 }, value: fetchedData ? fetchedData.completedAppointments : 0 });
  const canceledAppointmentsSpring = useSpring({ from: { value: 0 }, value: fetchedData ? fetchedData.canceledAppointments : 0 });

  return (
    <Flex justifyContent="space-between" w="100%" p={4}>
      {statistics.map((stat, index) => {
        const springValue =
          index === 0
            ? totalAppointmentsSpring.value
            : index === 1
            ? pendingAppointmentsSpring.value
            : index === 2
            ? activeAppointmentsSpring.value
            : index === 3
            ? completedAppointmentsSpring.value
            : canceledAppointmentsSpring.value;

        return (
          <Box
            key={stat.id}
            bg={stat.bgColor}
            borderRadius="20px"
            p={4}
            w="19%"
            color="white"
          >
            <Flex justifyContent="space-between" alignItems="center">
              <Text fontSize="lg" fontWeight="bold">
                {stat.title}
              </Text>
              <stat.icon size={24} />
            </Flex>
            <Text fontSize="2xl" fontWeight="bold" mt={2}>
              <animated.span>
                {springValue.to((val) => Math.floor(val))}
              </animated.span>
            </Text>
            <HStack justifyContent="space-between" mt={2}>
              <Text bg="rgba(255, 255, 255, 0.3)" borderRadius="10px" p={1}>
                {stat.percentage}
              </Text>
              <Text>{stat.lastMonth}</Text>
            </HStack>
          </Box>
        );
      })}
    </Flex>
  );
};

export default AppStatistics;
