import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Button,
  ModalBody,
  ModalFooter,
  Input,
  Box,
  Select,
  Flex,
  InputGroup,
  InputRightElement,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { FaPhoneAlt } from "react-icons/fa";
import { baseUrl } from "../../apiCalls/config";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditBeneficiaryModal = ({ isOpen, onClose, beneficiary, onSave }) => {
  const [formData, setFormData] = useState({
    beneficiaryId: beneficiary?.id || "",
    recipientFirstname: beneficiary?.recipientFirstName || "",
    recipientLastname: beneficiary?.recipientLastName || "",
    recipientPhoneNumber: beneficiary?.recipientPhoneNumber || "",
    recipientDOB: beneficiary?.recipientDOB || "",
    recipientGender: beneficiary?.recipientGender || "",
    relationship: beneficiary?.relationship || "",
  });

  useEffect(() => {
    if (beneficiary) {
      setFormData({
        beneficiaryId: beneficiary.id,
        recipientFirstname: beneficiary.recipientFirstName,
        recipientLastname: beneficiary.recipientLastName,
        recipientPhoneNumber: beneficiary.recipientPhoneNumber,
        recipientDOB: beneficiary.recipientDOB,
        recipientGender: beneficiary.recipientGender,
        relationship: beneficiary.relationship,
      });
    }
  }, [beneficiary]);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      await axios.post(
        `${baseUrl}/appointment/editBeneficiary`,
        formData,
        config
      );
      toast.success("Beneficiary updated successfully");
      onSave();
    } catch (error) {
      toast.error("Failed to update beneficiary");
      console.error("Error updating beneficiary:", error);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={{ base: "sm", md: "xl" }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="#A210C6">Edit Beneficiary</ModalHeader>
          <ModalCloseButton />
          <ModalBody justifyContent={{base: "center", Md: ""}} alignContent={{base: "center", Md: ""}}>
            <FormControl>
              <Flex display={{ base: "block", md: "flex" }}>
                <InputGroup>
                  <Input
                    name="recipientFirstname"
                    placeholder="first name"
                    value={formData.recipientFirstname}
                    onChange={handleInputChange}
                    w={{ base: "full", md: "250px" }}
                  />
                </InputGroup>
                <InputGroup>
                  <Input
                    name="recipientLastname"
                    ml={{ md: "5px" }}
                    mt={{ base: "20px", md: "0" }}
                    placeholder="last name"
                    value={formData.recipientLastname}
                    onChange={handleInputChange}
                    w={{ base: "full", md: "250px" }}
                  />
                </InputGroup>
              </Flex>
            </FormControl>

            <Flex display={{ base: "block", md: "flex" }}>
              <Box>
                <FormLabel fontWeight="bold" marginTop="20px">
                  Gender{" "}
                </FormLabel>
                <Select
                  name="recipientGender"
                  placeholder="select gender"
                  w={{ base: "full", md: "250px" }}
                  value={formData.recipientGender}
                  onChange={handleInputChange}
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </Select>
              </Box>

              <Box>
                <FormLabel fontWeight="bold" marginTop="20px">
                  Date of Birth
                </FormLabel>
                <Input
                  ml={{ md: "5px" }}
                  mt={{ base: "20px", md: "0" }}
                  w={{ base: "full", md: "250px" }}
                  type="date"
                  name="recipientDOB"
                  value={formData.recipientDOB}
                  onChange={handleInputChange}
                />
              </Box>
            </Flex>

            <Flex display={{ base: "block", md: "flex" }}>
              <Box>
                <FormLabel fontWeight="bold" marginTop="20px">
                  Contact Number{" "}
                </FormLabel>
                <InputGroup>
                  <Input
                    name="recipientPhoneNumber"
                    type="tel"
                    placeholder="recipient phone number"
                    value={formData.recipientPhoneNumber}
                    onChange={handleInputChange}
                    w={{ base: "full", md: "250px" }}
                  />
                  <InputRightElement pointerEvents="none">
                    <FaPhoneAlt color="gray.300" />
                  </InputRightElement>
                </InputGroup>
              </Box>
              <Box ml={{ md: "5px" }}>
                <FormLabel fontWeight="bold" marginTop="20px">
                  Relationship with beneficiary{" "}
                </FormLabel>
                <Select
                  value={formData.relationship}
                  name="relationship"
                  placeholder="select the appropriate relationship type"
                  w={{ base: "full", md: "250px" }}
                  onChange={handleInputChange}
                >
                  <option value="Mum">Mum</option>
                  <option value="Dad">Dad</option>
                  <option value="Wife">Wife</option>
                  <option value="Husband">Husband</option>
                  <option value="Sister">Sister</option>
                  <option value="Brother">Brother</option>
                  <option value="Uncle">Uncle</option>
                  <option value="Aunt">Aunt</option>
                  <option value="Son">Son</option>
                  <option value="Daughter">Daughter</option>
                  <option value="Niece">Niece</option>
                  <option value="Nephew">Nephew</option>
                  <option value="Cousin">Cousin</option>
                  <option value="Friend">Friend</option>
                  <option value="Colleague">Colleague</option>
                  <option value="Neighbour">Neighbour</option>
                  <option value="Mother In-Law">Mother in-law</option>
                  <option value="Father In-Law">Father in-law</option>
                  <option value="Grandmother">Grand mother</option>
                  <option value="Grandfather">Grand father</option>
                </Select>
              </Box>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleSave}>
              Save changes
            </Button>
            <Button onClick={onClose} ml={3}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditBeneficiaryModal;
