import React, { useState } from "react";
import { UpdateCustomer } from "../../apiCalls/UserApis";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { FaCamera } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NavBar from "../authLayouts/NavBar";
import LeftSideBar from "../authLayouts/LeftSideBar";
import {
  ChakraProvider,
  VStack,
  Input,
  Icon,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Avatar,
  Image,
  Box,
  Text,
  Flex,
  FormControl,
  extendTheme,
  FormLabel,
  Select,
} from "@chakra-ui/react";
import LoadingSpinner from "../../utils/Spiner";
import UpdatePhoneNumber from "../sections/UpdatePhoneNumber";
import SettingsSideBar from "../authLayouts/SettingsSideBar";
import MobileFooter from "../authLayouts/MobileFooter";

const customTheme = extendTheme({
  components: {
    Link: {
      baseStyle: {
        _focus: {
          boxShadow: "none",
        },
      },
    },
  },
  fonts: {
    body: "Montserrat, sans-serif",
    heading: "Gill Sans MT, sans-serif",
  },
});

const EdithProfilePage = () => {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userReducer);
  const [isPhoneModalOpen, setPhoneModalOpen] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [image] = useState();
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  // const { isOpen, onOpen, onClose } = useDisclosure();
  const inputRef = React.useRef();

  const handleClick = () => {
    inputRef.current.click();
  };

  const [formData, setFormData] = useState({
    firstName: user?.firstName,
    lastName: user?.lastName,
    dob: user?.dob.split("T")[0],
    email: user?.email,
    phoneNumber: user?.phoneNumber,
    address: user?.address,
    gender: user?.gender,
    image: user?.image,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleDOBChange = (e) => {
    const dateValue = e.target.value;
    setFormData({ ...formData, dob: dateValue });
  };

  const handleOpenConfirmationModal = () => {
    setConfirmationModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };

  const handlePhoneModalOpen = () => {
    setPhoneModalOpen(true);
  };

  const handlePhoneModalClose = () => {
    setPhoneModalOpen(false);
  };

  const handleImageChange = async (image, formData, setFormData) => {
    setImageLoading(true);

    if (image === undefined) {
      // toast.error("Please select an image")
      return;
    }

    console.log(image);

    if (image.type === "image/jpeg" || image.type === "image/png") {
      const data = new FormData();
      data.append("file", image);
      data.append("upload_preset", "profileImage");
      data.append("cloud_name", "dmfewrwla");

      try {
        const response = await fetch(
          "https://api.cloudinary.com/v1_1/dmfewrwla/image/upload",
          {
            method: "post",
            body: data,
          }
        );

        const imageData = await response.json();

        setFormData({
          ...formData,
          image: imageData.url.toString(),
        });
        setImageLoading(false);
        console.log(imageData.url.toString());
      } catch (err) {
        console.log(err);
        setImageLoading(false);
      }
    } else {
      return;
    }
  };

  const formatDateToUTC = (selectedDate) => {
    if (!selectedDate) return "";

    if (isNaN(new Date(selectedDate))) {
      console.error("Invalid date:", selectedDate);
      return "";
    }

    const adjustedDate = new Date(selectedDate);
    adjustedDate.setDate(adjustedDate.getDate() + 1);

    return adjustedDate.toISOString().split("T")[0];
  };

  const handleSubmit = async () => {
    handleCloseConfirmationModal();
    setLoading(true);
    try {
      await handleImageChange(image, formData, setFormData);

      const formatDateWithDayAdjustment = (selectedDate) =>
        formatDateToUTC(new Date(selectedDate));

      const formDataWithDate = {
        ...formData,
        startDate: formatDateWithDayAdjustment(formData.dob),
      };

      const response = await UpdateCustomer(
        formDataWithDate,
        toast,
        setLoading,
        "You will be re-directed to the dashboard"
      );

      if (response.success) {
        setLoading(false);
        toast.success("Update Successfull");
        setTimeout(() => {
          navigate("/client-dashboard");
        }, 5000);
      } else {
        console.error("Failed to update user details:", response.error);
        toast.error("Failed to update user details");
      }
    } catch (error) {
      console.error("Failed to update user details:", error);
      toast.error("Error updating user details");
    }
  };
  const settingsContainerStyle = {
    animation: "slideInUp 0.9s ease-in-out",
  };

  const handleback = () => {
    navigate("/settings");
  };

  return (
    <ChakraProvider theme={customTheme}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <LeftSideBar />
      <VStack
        style={settingsContainerStyle}
        position="fixed"
        ml={{ base: "10px", md: "140px" }}
        w="90%"
        h="100vh"
      >
        <Box w="80%" position="sticky" top="0" zIndex="1000">
          <NavBar />
        </Box>
        <Flex
          display={{ base: "none", md: "flex" }}
          mt={{ md: "30px" }}
          ml={{ base: "50px", md: "170px" }}
        >
          <SettingsSideBar />

          <Box ml="5px" spacing={-10}>
            <Text fontFamily="heading" fontWeight="bold" fontSize="20px">
              Edit profile
            </Text>
            <FormControl>
              <FormLabel fontFamily="body" fontSize="16px">
                First Name
              </FormLabel>
              <Input
                type="text"
                name="firstName"
                value={formData?.firstName}
                onChange={handleInputChange}
                borderColor="black"
                _hover={{ color: "" }}
              />
            </FormControl>
            <FormControl marginTop="15px">
              <FormLabel fontFamily="body" fontSize="16px">
                Last Name
              </FormLabel>
              <Input
                type="text"
                name="lastName"
                value={formData?.lastName}
                onChange={handleInputChange}
                borderColor="black"
                _hover={{ color: "" }}
              />
            </FormControl>
            <FormControl
              fontSize={{ base: "16px", md: "20px" }}
              fontFamily="body"
              isRequired
            >
              <FormLabel>Date of birth</FormLabel>
              <Input
                type="date"
                value={formData.dob}
                onChange={handleDOBChange}
                max={new Date().toISOString().split("T")[0]}
                name="dob"
                borderColor="black"
              />
            </FormControl>
            <FormControl marginTop="15px">
              <FormLabel fontFamily="body" fontSize="16px">
                Email Address
              </FormLabel>
              <Input
                type="email"
                name="email"
                value={formData?.email}
                onChange={handleInputChange}
                borderColor="black"
                _hover={{ color: "" }}
              />
            </FormControl>
            <FormControl marginTop="15px">
              <FormLabel fontSize="16px">Home Address</FormLabel>
              <Input
                type="text"
                name="address"
                value={formData?.address}
                onChange={handleInputChange}
                borderColor="black"
                _hover={{ color: "" }}
              />
            </FormControl>
            <FormControl marginTop="15px">
              <FormLabel>Gender </FormLabel>
              <Select
                name="gender"
                placeholder="Select your gender"
                w="240px"
                borderColor="black"
                value={formData?.gender}
                onChange={handleInputChange}
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </Select>
            </FormControl>
            <Button
              marginTop="10px"
              color="white"
              fontFamily="body"
              mb="50px"
              bg="#A210C6"
              onClick={handleOpenConfirmationModal}
              _hover={{ color: "white" }}
            >
              Save changes
            </Button>
          </Box>

          <Box marginLeft="30px" width="20%">
            <Text fontFamily="body" textAlign="left">
              Click on the image to select a new picture before the change
              picture button
            </Text>
            <Box
              borderRadius="10px"
              marginTop="30px"
              marginLeft="40px"
              p={3}
              h="150px"
              w="180px"
              bg="white"
              boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
            >
              <Avatar
                cursor="pointer"
                h="120px"
                w="100px"
                src={formData?.image}
                name={formData?.firstName}
                bg="#A210C6"
                onClick={handleClick}
              />
              <Input
                ref={inputRef}
                type="file"
                accept="image/*"
                onChange={(e) =>
                  handleImageChange(e.target.files[0], formData, setFormData)
                }
                hidden
                id="fileInput"
                name="image"
              />

              <Box mt="30px">
                {imageLoading && <LoadingSpinner size={20} />}
                <Button
                  fontSize="15px"
                  ml="10px"
                  borderColor="#A210C6"
                  borderWidth="2px"
                  bg="white"
                  fontFamily="body"
                  _hover={{
                    bg: "gray.100",
                    borderColor: "purple.800",
                  }}
                  onClick={handleOpenConfirmationModal}
                >
                  Change picture
                </Button>
              </Box>
              <Button
                borderColor="#A210C6"
                borderWidth="2px"
                bg="white"
                mt="10px"
                ml="-25px"
                fontFamily="body"
                style={{}}
                _hover={{
                  bg: "gray.100",
                  borderColor: "purple.800",
                }}
                onClick={handlePhoneModalOpen}
              >
                Change phone number
              </Button>
            </Box>
          </Box>
        </Flex>

        <Flex
          overflow="scroll"
          display={{ base: "block", md: "none" }}
          mt={{ md: "30px" }}
          ml={{ base: "30px" }}
          mb={{ base: "60px" }}
          w="360px"
        >
          <Flex justifyContent="space-between" margin="20px">
            <Box></Box>
            <Button
              onClick={handleback}
              borderColor="#A210C6"
              borderWidth="1px"
              fontFamily="body"
              color="#A210C6"
              _hover={{ color: "" }}
              fontSize={{ base: "12px" }}
              h="4vh"
              borderRadius="100px"
            >
              Back
            </Button>
          </Flex>
          <Flex
            alignItems="center"
            p={3}
            h="80px"
            w="250px"
            bg="white"
            position="relative"
          >
            <Box position="relative" w="80px" h="80px">
              <Image
                cursor="pointer"
                border="2px solid #A210C6"
                h="80px"
                w="80px"
                src={formData?.image}
                name={formData?.firstName}
                bg="#A210C6"
                onClick={handleClick}
                borderRadius="full"
              />
              <Icon
                as={FaCamera}
                boxSize={5}
                color="white"
                bg="#A210C6"
                borderRadius="full"
                position="absolute"
                bottom={1}
                right={1}
                transform="translate(25%, 25%)"
              />
              <Input
                ref={inputRef}
                type="file"
                accept="image/*"
                onChange={(e) =>
                  handleImageChange(e.target.files[0], formData, setFormData)
                }
                hidden
                id="fileInput"
                name="image"
              />
            </Box>
            {imageLoading && <LoadingSpinner size={20} />}
            <Flex flexDirection="row" ml={4}>
              <Text fontWeight="bold" fontSize="18px">
                {formData?.firstName}
              </Text>
              <Text ml="5px" fontWeight="bold" fontSize="18px">
                {formData?.lastName}
              </Text>
            </Flex>
          </Flex>
          {/* <Flex marginTop="10px">
              {imageLoading && <LoadingSpinner size={20} />}
              <Button
                fontSize="15px"
                borderColor="#A210C6"
                borderWidth="2px"
                bg="white"
                fontFamily="body"
                _hover={{
                  bg: "gray.100",
                  borderColor: "purple.800",
                }}
                onClick={handleOpenConfirmationModal}
              >
                Change picture
              </Button> */}

          {/* <Divider orientation="vertical" borderColor="black" my={1} />
              <Button
                _hover={{ color: "" }}
                bg="none"
                fontSize="15px"
                color="red"
              >
                Delete picture
              </Button> */}
          {/* </Flex> */}
          <Text
            textAlign="left"
            fontSize={{ base: "18px" }}
            marginTop="-40px"
            marginBottom="20px"
            fontWeight="bold"
            fontFamily="heading"
          >
            Edit Profile
          </Text>
          {/* <Text fontFamily="body" textAlign="left">
                Click on the image to select a new picture before the change
                picture button
              </Text> */}
          <VStack spacing={-10}>
            <Box w="full">
              <FormControl w="full">
                <FormLabel fontFamily="body" fontSize="16px">
                  First Name
                </FormLabel>
                <Input
                  type="text"
                  name="firstName"
                  value={formData?.firstName}
                  onChange={handleInputChange}
                  borderColor="black"
                  _hover={{ color: "" }}
                />

                <FormLabel fontFamily="body" marginTop="15px" fontSize="16px">
                  Last Name
                </FormLabel>
                <Input
                  type="text"
                  name="lastName"
                  value={formData?.lastName}
                  onChange={handleInputChange}
                  borderColor="black"
                  _hover={{ color: "" }}
                />

                <FormControl
                  fontSize={{ base: "16px", md: "20px" }}
                  fontFamily="body"
                  isRequired
                >
                  <FormLabel>Date of birth</FormLabel>
                  <Input
                    type="date"
                    value={formData.dob}
                    onChange={handleDOBChange}
                    max={new Date().toISOString().split("T")[0]}
                    name="dob"
                    borderColor="black"
                  />
                </FormControl>
                <FormLabel fontFamily="body" marginTop="15px" fontSize="16px">
                  Email Address
                </FormLabel>
                <Input
                  w="full"
                  type="email"
                  name="email"
                  value={formData?.email}
                  onChange={handleInputChange}
                  borderColor="black"
                  _hover={{ color: "" }}
                />

                <FormLabel marginTop="15px" fontSize="16px">
                  Home Address
                </FormLabel>
                <Input
                  type="text"
                  name="address"
                  w="full"
                  value={formData?.address}
                  onChange={handleInputChange}
                  borderColor="black"
                  _hover={{ color: "" }}
                />
              </FormControl>
              <FormControl marginTop="15px">
                <FormLabel>Gender </FormLabel>
                <Select
                  name="gender"
                  placeholder="Select your gender"
                  w="full"
                  borderColor="black"
                  value={formData?.gender}
                  onChange={handleInputChange}
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </Select>
              </FormControl>
              <Button
                borderColor="#A210C6"
                borderWidth="2px"
                marginTop="20px"
                bg="white"
                fontFamily="body"
                w="full"
                style={{}}
                _hover={{
                  bg: "gray.100",
                  borderColor: "purple.800",
                }}
                onClick={handlePhoneModalOpen}
              >
                Change phone number
              </Button>
              <Button
                marginTop="10px"
                marginBottom="150px"
                color="white"
                fontFamily="body"
                bg="#A210C6"
                onClick={handleOpenConfirmationModal}
                _hover={{ color: "white" }}
              >
                Save changes
              </Button>
            </Box>
          </VStack>
        </Flex>
        <MobileFooter />
      </VStack>

      <Modal
        isOpen={isConfirmationModalOpen}
        onClose={handleCloseConfirmationModal}
        size="sm"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontFamily="heading">Confirm Changes</ModalHeader>
          <ModalCloseButton />
          <ModalBody fontFamily="body">
            Are you sure you want to save the changes?
          </ModalBody>
          <Box display="flex" justifyContent="flex-end" p="2">
            <Button
              mr={3}
              onClick={handleCloseConfirmationModal}
              colorScheme="gray"
              color="#A210C6"
              fontFamily="body"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              color="white"
              fontFamily="body"
              bg="#A210C6"
              isLoading={loading}
            >
              Confirm
            </Button>
          </Box>
        </ModalContent>
      </Modal>
      <UpdatePhoneNumber
        isOpen={isPhoneModalOpen}
        onClose={handlePhoneModalClose}
      />
      <VStack />
    </ChakraProvider>
  );
};
export default EdithProfilePage;
