import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Switch,
  Box,
  Flex,
  Text,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { baseUrl } from "../../../apiCalls/config";

const UpdatePaymentAmountModal = ({ isOpen, onClose, appointmentId }) => {
  const [formData, setFormData] = useState({
    appointmentId: "",
    amount: "",
    fullPayment: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();

  // Set the appointmentId when the component mounts
  React.useEffect(() => {
    setFormData((prevData) => ({ ...prevData, appointmentId }));
  }, [appointmentId]);

  const formatAmount = (value) => {
    const cleanedValue = value.replace(/[^0-9.]/g, "");
    const formattedValue = Number(cleanedValue).toLocaleString();
    return formattedValue;
  };

  const handleAmountChange = (e) => {
    const inputValue = e.target.value;
    const formattedValue = formatAmount(inputValue);
    setFormData((prevData) => ({
      ...prevData,
      amount: formattedValue,
    }));
  };

  const handleToggleFullPayment = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      fullPayment: e.target.checked,
    }));
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const token = localStorage.getItem("token");
    const formattedAmount = Number(formData.amount.replace(/,/g, ""));
    
    console.log("Payload sent to server:", {
        appointmentId: formData.appointmentId,
        amount: formattedAmount,
        fullPayment: formData.fullPayment,
      }); 

    try {
      const response = await axios.post(
        `${baseUrl}/api/wallets/update-payment`,
        {
          appointmentId: formData.appointmentId,
          amount: formattedAmount,
          fullPayment: formData.fullPayment,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

     

      if (response.data.success) {
        toast({
          title: "Success",
          description: "Payment amount updated successfully",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        onClose();
      } else {
        toast({
          title: "Error",
          description: response.data.message || "Update failed",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.response?.data?.message || "Something went wrong",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="#4B4B4B" borderRadius="10px" p={4} color="white">
        <ModalHeader>Update Payment Amount</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isRequired>
            <FormLabel>Amount</FormLabel>
            <Input
              name="amount"
              type="text"
              value={formData.amount}
              onChange={handleAmountChange}
            />
          </FormControl>
          <Box mt={4}>
            <Flex alignItems="center" justifyContent="space-between">
              <Text fontWeight="bold" mb={0}>
                Full Payment
              </Text>
              <Switch
                isChecked={formData.fullPayment}
                onChange={handleToggleFullPayment}
                colorScheme="green"
              />
            </Flex>
            <Text fontStyle="italic" fontSize="sm" color="yellow.200">
              Toggle on if this is a full payment.
            </Text>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button
            bg="green.500"
            color="white"
            onClick={handleSubmit}
            isLoading={isSubmitting}
          >
            Submit
          </Button>
          <Button color="white" bg="red.500" ml={3} onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UpdatePaymentAmountModal;
