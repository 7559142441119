import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../apiCalls/config";

const WalletModal = ({ isOpen, onClose }) => {
  const [bvn, setBvn] = useState("");
  const [nin, setNin] = useState("");
  const [bankList, setBankList] = useState([]);
  const [selectedBank, setSelectedBank] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [bvnWarning, setBvnWarning] = useState("");
  const [ninWarning, setNinWarning] = useState("");
  const [accountNumberWarning, setAccountNumberWarning] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // New state for button disable
  const toast = useToast();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.userReducer);
  const userId = user?.userId;

  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const token = localStorage.getItem("token");
        const link = `${baseUrl}/api/wallets/bank-list`;
        const response = await axios.get(link, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.status) {
          setBankList(response.data.data);
        } else {
          throw new Error("Failed to fetch bank list");
        }
      } catch (error) {
        toast.error("Error: " + error.message, {
          duration: 5000,
          isClosable: true,
        });
      }
    };

    if (isOpen) {
      fetchBanks();
    }
  }, [isOpen, toast]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const fetchAccountName = async () => {
      if (accountNumber.length === 10 && selectedBank) {
        setIsFetching(true);
        try {
          const response = await axios.get(
            `${baseUrl}/api/wallets/name-enquiry`,
            {
              params: {
                bankCode: selectedBank,
                accountNumber: accountNumber,
              },
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (response.status || response.data.data.account_name) {
            setAccountName(response.data.data.account_name);
            setHasError(false);
          } else {
            setAccountName(
              "Account name not found, please double check the number"
            );
            setHasError(true);
          }
        } catch (error) {
          toast.error("Error: " + error.message, {
            duration: 5000,
            isClosable: true,
          });
          setHasError(true);
        } finally {
          setIsFetching(false);
        }
      }
    };

    fetchAccountName();
  }, [accountNumber, selectedBank, toast]);

  const handleBvnChange = (e) => {
    const value = e.target.value;
    if (/^[0-9]{0,11}$/.test(value)) {
      setBvn(value);
      if (value.length !== 11) {
        setBvnWarning("BVN must be exactly 11 digits");
      } else {
        setBvnWarning("");
      }
    }
  };

  const handleNinChange = (e) => {
    const value = e.target.value;
    if (/^[0-9]{0,11}$/.test(value)) {
      setNin(value);
      if (value.length !== 11) {
        setNinWarning("NIN must be exactly 11 digits");
      } else {
        setNinWarning("");
      }
    }
  };

  const handleAccountNumberChange = (e) => {
    const value = e.target.value;
    if (/^[0-9]{0,10}$/.test(value)) {
      setAccountNumber(value);
      if (value.length !== 10) {
        setAccountNumberWarning("Account number must be exactly 10 digits");
      } else {
        setAccountNumberWarning("");
      }
    }
  };

  const handleSubmit = async () => {
    console.log("user: ", user);
    if (bvn.length !== 11) {
      toast({
        title: "Error",
        description: "BVN must be exactly 11 digits",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    if (nin.length !== 11) {
      toast({
        title: "Error",
        description: "NIN must be exactly 11 digits",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    if (accountNumber.length !== 10) {
      toast({
        title: "Error",
        description: "Account number must be exactly 10 digits",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsButtonDisabled(true); // Disable the button immediately
    setTimeout(() => setIsButtonDisabled(false), 5000); // Re-enable after 5 seconds

    const selectedBankDetails = bankList.find(
      (bank) => bank.code === selectedBank
    );

    const payload = {
      userId,
      bankName: selectedBankDetails ? selectedBankDetails.name : "",
      accountNumber,
      accountName,
      bvn,
      nin,
      bankCode: selectedBank,
    };

    setIsLoading(true);
    try {
      const roles = user?.setOfRole;
      let apiUrl = "";
      let dashboardUrl = "";
      const token = localStorage.getItem("token");
      if (roles.includes("NEW_MEDIC") || roles.includes("VERIFIED_MEDIC")) {
        apiUrl = `${baseUrl}/api/wallets/add-medic-bank`;
        dashboardUrl = "/medic-dashboard";
      } else if (roles.includes("VERIFIED_CUSTOMER")) {
        apiUrl = `${baseUrl}/api/wallets/add-customer-bank`;
        dashboardUrl = "/client-dashboard";
      } else {
        throw new Error("User role not supported for wallet creation");
      }
      const response = await axios.post(apiUrl, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200 && response.data.success) {
        toast({
          title: "Success",
          description:
            "Your wallet creation is being processed and will be ready in a few minutes: You would receive an email once your wallet is created",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        onClose();
        setTimeout(() => {
          navigate(dashboardUrl);
          window.location.reload();
        }, 4000);
      } else {
        throw new Error(response.data.message || "Failed to add bank details");
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || "Failed to add bank details",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setIsLoading(false);
      setIsButtonDisabled(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        maxW={{ base: "90%", md: "500px" }}
        mx={{ base: "10px", md: "auto" }}
      >
        <ModalHeader color="#A210C6">Create your wallet</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontSize="sm" color="gray.500" mb={4}>
            We need your BVN and NIN to create a virtual wallet for secure and
            seamless transactions.
          </Text>
          <FormControl id="bvn" isRequired>
            <FormLabel>BVN</FormLabel>
            <Input
              type="text"
              value={bvn}
              onChange={handleBvnChange}
              title="BVN must be 11 digits"
            />
            {bvnWarning && (
              <Text fontSize="sm" color="red.500" fontStyle="italic">
                {bvnWarning}
              </Text>
            )}
          </FormControl>
          <FormControl id="nin" isRequired mt={4}>
            <FormLabel>NIN</FormLabel>
            <Input
              type="text"
              value={nin}
              onChange={handleNinChange}
              title="NIN must be 11 digits"
            />
            {ninWarning && (
              <Text fontSize="sm" color="red.500" fontStyle="italic">
                {ninWarning}
              </Text>
            )}
          </FormControl>
          <FormControl id="bank-name" isRequired mt={4}>
            <FormLabel>Bank Name</FormLabel>
            <Select
              placeholder="Select bank"
              value={selectedBank}
              onChange={(e) => setSelectedBank(e.target.value)}
            >
              {bankList.map((bank) => (
                <option key={bank.code} value={bank.code}>
                  {bank.name}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl id="account-number" isRequired mt={4}>
            <FormLabel>Account Number</FormLabel>
            <Input
              type="text"
              value={accountNumber}
              onChange={handleAccountNumberChange}
              title="Account number must be 10 digits"
            />
            {accountNumberWarning && (
              <Text fontSize="sm" color="red.500" fontStyle="italic">
                {accountNumberWarning}
              </Text>
            )}
          </FormControl>
          {isFetching ? (
            <Spinner size="sm" mt={4} />
          ) : (
            accountName && (
              <Text
                mt={4}
                fontSize="sm"
                color={hasError ? "red.500" : "green.500"}
              >
                {accountName}
              </Text>
            )
          )}
        </ModalBody>
        <ModalFooter>
          {!hasError && accountName && (
            <Button
              bg="#A210C6"
              color="white"
              onClick={handleSubmit}
              isLoading={isLoading}
              spinner={<Spinner color="white" />}
              ml={3}
              disabled={isFetching || isButtonDisabled}
            >
              Submit
            </Button>
          )}
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default WalletModal;
