import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import LeftSideBar from "../authLayouts/LeftSideBar";
import NavBar from "../authLayouts/NavBar";
import {
  ChakraProvider,
  VStack,
  Image,
  Box,
  Flex,
  extendTheme,
} from "@chakra-ui/react";

import SettingsSideBar from "../authLayouts/SettingsSideBar";
import BigSettingsIcon from "../../assets/BigSettingsIcon.svg";
import MobileFooter from "../authLayouts/MobileFooter";

const customTheme = extendTheme({
  components: {
    Link: {
      baseStyle: {
        _focus: {
          boxShadow: "none",
        },
      },
    },
  },
  fonts: {
    body: "Montserrat, sans-serif",
    heading: "Gill Sans MT, sans-serif",
  },
});

const SettingsPage = () => {
  const settingsContainerStyle = {
    animation: "slideInUp 0.9s ease-in-out",
  };

  return (
    <ChakraProvider theme={customTheme}>
      <Flex height="100vh" overflow="hidden">
        <LeftSideBar />
        <VStack
          style={settingsContainerStyle}
          
          ml={{md: "250px", lg: "310px" }}
          w={{ base: "100%", md: "70%" }}
          h="100vh"
        >
          <Box w="100%" position="sticky" top="0" zIndex="1000">
            <NavBar />
          </Box>
          <Flex
            w="100%"
            h="100%"
            mt={{ md: "30px" }}
            ml={{ base: "-60px", md: "90px" }}
            paddingBottom="1rem"
            overflowY="auto"
          >
            <SettingsSideBar />
            <Box display={{ base: "none", md: "block" }} mt={{ md: "20px" }}>
              <Image
                src={BigSettingsIcon}
                alt="Settings Icon"
                boxSize={{ base: "24px", md: "50px" }}
                h={{ base: "156px", md: "456px" }}
                w={{ base: "188px", md: "488px" }}
              />
            </Box>
          </Flex>
          <MobileFooter />
        </VStack>
      </Flex>
    </ChakraProvider>
  );
};

export default SettingsPage;
