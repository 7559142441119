import React, { useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  ChakraProvider,
  FormControl,
  FormLabel,
  Input,
  Image,
  Text,
  useToast,
  Select,
  FormErrorMessage, // Add Select component
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/Logo.svg";
import { extendTheme } from "@chakra-ui/react";
import { baseUrl, loginBaseUrl } from "../../apiCalls/config";

const customTheme = extendTheme({
  components: {
    Link: {
      baseStyle: {
        _focus: {
          boxShadow: "none",
        },
      },
    },
  },
  fonts: {
    body: "Gill Sans MT, sans-serif",
    heading: "Gill Sans MT, sans-serif",
  },
});

const VerifyMedicForm = () => {
  const [formData, setFormData] = useState({
    guarantorFirstName: "",
    guarantorLastName: "",
    guarantorEmail: "",
    guarantorPhone: "",
    guarantorHomeAddress: "",
    relationshipWithGuarantor: "",
    lastEmployerFullName: "",
    lastEmployerPhoneNumber: "",
    lastEmployerEmail: "",
    lastEmployerAddress: "",
    phoneNumber: localStorage.getItem("phoneNumber"),
    medicHomeAddress: "",
  });

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();

  const [emailErrors, setEmailErrors] = useState({
    guarantorEmailError: "",
    lastEmployerEmailError: "",
  });

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    // Handle email validation in real-time
    if (name === "guarantorEmail" || name === "lastEmployerEmail") {
      if (!validateEmail(value)) {
        setEmailErrors((prevErrors) => ({
          ...prevErrors,
          [`${name}Error`]: "Invalid email format",
        }));
      } else {
        setEmailErrors((prevErrors) => ({
          ...prevErrors,
          [`${name}Error`]: "",
        }));
      }
    }
  };

  const sendOtp = async () => {
    try {
      const number = localStorage.getItem("phoneNumber");
      const response = await axios.post(
        `${loginBaseUrl}/api/v1/sms/verify-number`,
        { phoneNumber: number },
        { headers: { "Content-Type": "application/json" } }
      );
  
      if (response.data && response.data.data) {
        const reference = response.data.data.split('reference: ')[1]; 
        if (reference) {
          localStorage.setItem("otpReference", reference);
        }
      }
      console.log(response);
      toast({
        title: "OTP has been sent to your phone number for verification.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      toast({
        title: "Unable to resend OTP at this time. Please try again later.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !validateEmail(formData.guarantorEmail) ||
      !validateEmail(formData.lastEmployerEmail)
    ) {
      toast({
        title: "Validation Error",
        description: "Please provide valid email addresses.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }
    setLoading(true);

    try {
      const response = await axios.post(
        `${baseUrl}/angel/medicIdentity`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response);
      toast({
        title: "Saved Successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      await sendOtp();
      setTimeout(() => {
        navigate("/verify-medic-number");
      }, 5000);
    } catch (error) {
      toast({
        title: "An error occurred",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <ChakraProvider theme={customTheme}>
      <Box
        overflowX={{ base: "auto", md: "hidden" }}
        overflowY={{ base: "hidden", md: "auto" }}
        align="center"
        justify="center"
        minHeight="100vh"
      >
        <Box
          mb={{ base: "50px", md: "100px" }}
          mt={{ base: "50px", md: "100px" }}
          width={{ base: "90%", sm: "500px" }}
          h={{ base: "auto", md: "auto" }}
          p="6"
          bg="white"
          boxShadow="lg"
        >
          <Box
            top={{ base: "10px", md: "20px" }}
            left={{ base: "10px", md: "20px" }}
          >
            <a href="/">
              <Image
                src={logo}
                alt="Logo"
                h={{ base: "40px", md: "58px" }}
                w={{ base: "150px", md: "200px" }}
              />
            </a>
          </Box>
          <Text
            fontFamily="heading"
            fontSize="2xl"
            color="#A210C6"
            mb="4"
            textAlign="center"
          >
            Verify Your Identity
          </Text>
          <form onSubmit={handleSubmit}>
            <FormControl color="#00000080" isRequired marginTop="20px">
              <Box display="flex" marginBottom="20px">
                <Box flex="1" marginRight="10px">
                  <FormLabel>Guarantor's First Name</FormLabel>
                  <Input
                    name="guarantorFirstName"
                    placeholder="First Name"
                    onChange={handleInputChange}
                    isRequired
                  />
                </Box>
                <Box flex="1" marginLeft="10px">
                  <FormLabel>Guarantor's Last Name</FormLabel>
                  <Input
                    name="guarantorLastName"
                    placeholder="Last Name"
                    onChange={handleInputChange}
                    isRequired
                  />
                </Box>
              </Box>

              <Box display="flex" marginBottom="20px">
                <Box flex="1" marginRight="10px">
                  <FormLabel>Guarantor's Email Address</FormLabel>
                  <Input
                    name="guarantorEmail"
                    placeholder="Email Address"
                    onChange={handleInputChange}
                    type="email"
                    isInvalid={emailErrors.guarantorEmailError}
                    isRequired
                  />
                  <FormErrorMessage>
                    {emailErrors.guarantorEmailError}
                  </FormErrorMessage>
                </Box>
                <Box flex="1" marginLeft="10px">
                  <FormLabel>Guarantor's Phone Number</FormLabel>
                  <Input
                    name="guarantorPhone"
                    placeholder="Phone Number"
                    onChange={handleInputChange}
                    isRequired
                  />
                </Box>
              </Box>

              {/* Relationship with Guarantor Dropdown */}
              <Box marginBottom="20px">
                <FormLabel>Relationship with Guarantor</FormLabel>
                <Select
                  name="relationshipWithGuarantor"
                  placeholder="Select Relationship"
                  onChange={handleInputChange}
                  isRequired
                >
                  <option value="Parent">Parent</option>
                  <option value="Sibling">Sibling</option>
                  <option value="Friend">Friend</option>
                  <option value="Spouse">Spouse</option>
                  <option value="Uncle/Aunt">Uncle/Aunt</option>
                  <option value="Cousin">Cousin</option>
                  <option value="Colleague">Colleague</option>
                  <option value="Mentor">Mentor</option>
                  <option value="Employer">Employer</option>
                  <option value="Other">Other</option>
                </Select>
              </Box>

              <FormLabel>Guarantor's Home Address</FormLabel>
              <Input
                name="guarantorHomeAddress"
                placeholder="Home Address"
                onChange={handleInputChange}
                marginBottom="20px"
                isRequired
              />

              <Text fontSize="lg" mt="6" mb="4" fontWeight="bold">
                Last Employer Details
              </Text>
              <Box marginBottom="20px">
                <FormLabel>Last Employer's Full Name</FormLabel>
                <Input
                  name="lastEmployerFullName"
                  placeholder="Full Name"
                  onChange={handleInputChange}
                  isRequired
                />
              </Box>

              <Box display="flex" marginBottom="20px">
                <Box flex="1" marginRight="10px">
                  <FormLabel>Phone Number</FormLabel>
                  <Input
                    name="lastEmployerPhoneNumber"
                    placeholder="Phone Number"
                    onChange={handleInputChange}
                    isRequired
                  />
                </Box>
                <Box flex="1" marginLeft="10px">
                  <FormLabel>Email</FormLabel>
                  <Input
                    name="lastEmployerEmail"
                    placeholder="Email Address"
                    onChange={handleInputChange}
                    type="email"
                    isInvalid={emailErrors.lastEmployerEmailError}
                    isRequired
                  />
                  <FormErrorMessage>
                    {emailErrors.lastEmployerEmailError}
                  </FormErrorMessage>
                </Box>
              </Box>

              <FormLabel>Employer/Hospital/Company Address</FormLabel>
              <Input
                name="lastEmployerAddress"
                placeholder="Address"
                onChange={handleInputChange}
                marginBottom="20px"
                isRequired
              />

              <FormLabel>Your Home Address</FormLabel>
              <Input
                name="medicHomeAddress"
                placeholder="Home Address"
                onChange={handleInputChange}
                marginBottom="20px"
                isRequired
              />

              <Button
                type="submit"
                w="100%"
                bg="linear-gradient(80deg, #A210C6, #E552FF)"
                color="white"
                isLoading={loading}
                loadingText="Submitting..."
              >
                {loading ? "Submitting..." : "Submit"}
              </Button>
            </FormControl>

            
          </form>
        </Box>
      </Box>
    </ChakraProvider>
  );
};

export default VerifyMedicForm;
