import React from "react";
import FBIcon from "../../assets/FaceBookIcon.svg";
import IGIcon from "../../assets/InstagramIcon.svg";
import WHIcon from "../../assets/WAIcon.svg";
// import { ArrowUpIcon } from "@chakra-ui/icons";
import {
  Box,
  Link as ChakraLink,
  Divider,
  Flex,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import TermsModal from "../sections/TermsModal";

const Footer = ({ onFaqsClick }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const handleClick = () => {
  //   // Scroll to the top of the page
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // };

  return (
    <>
      <Box
        // bg="#A210C6"
        bg="linear-gradient(80deg, #A210C6, #E552FF)"
        color="white"
        px={{ base: "4", md: "8" }}
        py={{ base: "6", md: "10" }}
        textAlign={{ base: "center", md: "center" }}
      >
        <Flex
          maxWidth="1280px"
          margin="0 auto"
          direction={{ base: "column", md: "row" }}
          justify="space-between"
          // align="start"
          flexWrap="wrap"
        >
          <Box mb={{ base: "6", md: "0" }}>
            <Text
              textAlign="left"
              mb={{ base: "30px", md: "40px" }}
              fontSize={{ base: "2xl", md: "3xl" }}
              fontWeight="bold"
            >
              MIKUL HEALTH
            </Text>
            <Text
              textAlign="left"
              mt={{ base: "1", md: "2" }}
              fontSize={{ base: "lg", md: "xl" }}
              maxWidth={{ md: "400px" }}
            >
              We harness the power of cutting-edge technology to ensure that you
              and your loved ones receive the highest quality of homecare at the
              comfort of your home.
            </Text>
            <Flex mt={{ base: "10px", md: "30px" }}>
              <ChakraLink href="https://web.facebook.com/mikulhealthcare/?_rdc=1&_rdr:/">
                <Image src={FBIcon} alt="Facebook" w="32px" h="32px" mr="4" />
              </ChakraLink>
              <ChakraLink href="https://www.instagram.com/mikulhealth/">
                <Image src={IGIcon} alt="Instagram" w="32px" h="32px" mr="4" />
              </ChakraLink>
              <ChakraLink href="https://wa.me/message/7NHXMWT4I54JC1/">
                <Image src={WHIcon} alt="WhatsApp" w="32px" h="32px" />
              </ChakraLink>
            </Flex>
          </Box>
          <Box mb={{ base: "6", md: "0" }} ml={{ base: "0", md: "-200px" }}>
            <Text
              fontSize={{ base: "2xl", md: "3xl" }}
              fontWeight="bold"
              textAlign={{ base: "left", md: "" }}
              mb={{ base: "30px", md: "40px" }}
            >
              Quick Links
            </Text>
            <Box
              fontSize={{ base: "lg", md: "xl" }}
              textAlign="left"
              mt={{ base: "2", md: "4" }}
            >
              <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                <li style={{ marginBottom: "20px" }}>
                  <NavLink to="/">Home</NavLink>
                </li>
                <li style={{ marginBottom: "20px" }}>
                  <NavLink to="/about-us">About Us</NavLink>
                </li>
                {/* <li style={{ marginBottom: "42px" }}>
                <NavLink to="/contact">Contact Us</NavLink>
              </li> */}
                <li style={{ marginBottom: "20px" }}>
                  <ChakraLink onClick={onOpen} cursor="pointer">
                    Terms of Use
                  </ChakraLink>
                </li>
                <li>
                  <ChakraLink onClick={onOpen} cursor="pointer">
                    Privacy Policy
                  </ChakraLink>
                </li>
              </ul>
            </Box>
          </Box>
          <Box mb={{ base: "6", md: "0" }} mr={{ base: "0", md: "25px" }}>
            <Text
              fontSize={{ base: "2xl", md: "3xl" }}
              fontWeight="bold"
              mb={{ base: "30px", md: "40px" }}
              textAlign={{ base: "left", md: "" }}
            >
              Get In Touch With Us
            </Text>
            <Box
              fontSize={{ base: "lg", md: "xl" }}
              textAlign="left"
              mt={{ base: "2", md: "4" }}
            >
              <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                <li style={{ marginBottom: "20px" }}>
                  Email:{" "}
                  <a
                    href="mailto:support@mikulhealth.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    support@mikulhealth.com
                  </a>{" "}
                </li>
                <li style={{ marginBottom: "20px" }}>
                  Phone number: <a href="tel:+2349160596636">+2349160596636</a>
                </li>
              </ul>
            </Box>
          </Box>
        </Flex>
        <Box textAlign="center" mt={{ base: "6", md: "8" }}>
          <Divider my={6} borderColor="white" />
          {/* <NavLink onClick={handleClick}>
          <ArrowUpIcon
            boxShadow="0 8px 12px rgba(0,0,0,0.2)"
            borderRadius="50%"
            p="2"
            color="white"
            boxSize={{ base: "10", md: "12" }}
          />
        </NavLink> */}
          <Text fontSize={{ base: "sm", md: "lg" }}>
            © 2024 Mikul Healthcare Technology. All Rights Reserved.
          </Text>
        </Box>
      </Box>
      <TermsModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default Footer;
