import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPage from "./components/pages/unAuthPages/LandingPage";
import AboutPage from "./components/pages/unAuthPages/AboutPage";
import ConatactPage from "./components/pages/unAuthPages/Contact";
import MedicRegPage from "./components/pages/MedicRegPage";
import MedicRegPage2 from "./components/pages/MedicRegPage2";
import VerifyNumber from "./components/pages/VerifyNumber";
import VerifyMedicNumber from "./components/pages/VerifyMedicNumber";
import MedicRegConfirmation from "./components/pages/MedicConfirmationPage";
import CustomerReg from "./components/pages/CustomerReg";
import Login from "./components/pages/LoginPage";
import ForgotPassword from "./components/pages/ForgotPassowrd";
import ResetPassword from "./components/pages/ResetPassword";
import ClientDash from "./components/pages/ClientDashboad";
import AppointmentPage from "./components/pages/AppointmentPage";
import UserDetailsModal from "./components/sections/UserDetails";
import VerifyOtpDash from "./components/sections/VerifyOTP";
import PaymentConfirmationPage from "./components/sections/PaymentConfirmationModal";
import WalletPage from "./components/pages/WalletPage";
import SettingsPage from "./components/pages/SettingsPage";
import EdithProfilePage from "./components/pages/EdithProfilePage";
import ChangePasswordPage from "./components/pages/ChangePasswordPage";
import NotificationSettingsPage from "./components/pages/NotificationSettingsPage";
import HelpPage from "./components/pages/HelpPage";
import ServicePage from "./components/pages/ServicePage";
import CustomizeServicePage from "./components/pages/CustomizeServicePage";
import BookButton from "./components/authLayouts/BookButton";
import ServicesSection from "./components/unAuthLayouts/ServicesSection";
import Faqs from "./components/unAuthLayouts/Faqs";
import WalletPaymentPage from "./components/sections/WalletPayment";
import VerifyMedicForm from "./components/pages/VerifyMedicForm";
import CompletionPage from "./components/pages/ComplitionPage";
import MedicDashboard from "./components/pages/MedicDashboard";
import PatientsPage from "./components/pages/PatientsPage";
import InactivityLogout from "./InactivityLogout";
import MedicAppPage from "./components/pages/MedicAppPage";
import MedicWalletPage from "./components/pages/MedicWalletPage";
import MedicHelpPage from "./components/pages/MedicHelpPage";
import MedicChangePasswordPage from "./components/pages/MedicChangePassword";
import MedicEdithProfilePage from "./components/pages/MedicEdithProfile";
import MedicNotificationSettingsPage from "./components/pages/MedicNotiSettings";
import MedicSettingsPage from "./components/pages/MedicSettingsPage";
import WalletSubscriptionPage from "./components/sections/WalletSubscription";
import AdminDashboard from "./components/pages/admin/AdminDash";
// import GoogleMapsConfig from './GoogleMapsConfig';
import AppSubscriptionPage from "./components/pages/AppointmentSub";
import Customers from "./components/pages/admin/Customers";
import Medics from "./components/pages/admin/Medics";
import Admin from "./components/pages/admin/Admin";
import Appointments from "./components/pages/admin/Appointments";
import MedicalReports from "./components/pages/admin/MedicalReports";
import Finance from "./components/pages/admin/Finance";
import WhatsAppButton from "./components/sections/WhatsAppButton";
import ChangeTransactionPinPage from "./components/pages/ChangeTransactionPinPage ";
import MedicChangeTransactionPinPage from "./components/pages/MedicChangeTransactionPin"
import VerifyEmailOtp from "./components/pages/VerifyEmailOtp";
import GoogleAnalytics from "./components/sections/GoogleAnalytics";

function App() {
  const trackingId = "G-RVEF6317ZR"; 
  return (
    <div className="App">
      <BrowserRouter>
        {/* <GoogleMapsConfig/> */}
        <GoogleAnalytics trackingId={trackingId} />
        <InactivityLogout/>
        <WhatsAppButton/>
        <Routes>
          <Route path="/" element={<LandingPage />}></Route>
          <Route path="/about-us" element={<AboutPage />}></Route>
          <Route path="/contact" element={<ConatactPage />}></Route>
          <Route path="/join" element={<MedicRegPage />}></Route>
          <Route path="/join-complete" element={<MedicRegPage2 />}></Route>
          <Route path="/verify-medic" element={<VerifyMedicForm />}></Route>
          <Route path="/verify-number" element={<VerifyNumber />}></Route>
          <Route path="/verify-email" element={<VerifyEmailOtp />}></Route>
          <Route path="/complete" element={<CompletionPage />}></Route>
          <Route path="/patients" element={<PatientsPage />}></Route>
          <Route path="/medic-appointment" element={<MedicAppPage />}></Route>
          <Route path="/medic-wallet" element={<MedicWalletPage />}></Route>
          <Route
            path="/verify-medic-number"
            element={<VerifyMedicNumber />}
          ></Route>
          <Route path="/medic-reg" element={<MedicRegPage2 />}></Route>
          <Route
            path="/confirm-medic-reg"
            element={<MedicRegConfirmation />}
          ></Route>
          <Route path="/signup" element={<CustomerReg />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/forgot-password" element={<ForgotPassword />}></Route>
          <Route path="/resetpassword" element={<ResetPassword />}></Route>
          <Route path="/client-dashboard" element={<ClientDash />}></Route>
          <Route path="/medic-dashboard" element={<MedicDashboard />}></Route>
          <Route path="/details" element={<UserDetailsModal />}></Route>
          <Route path="/verifyPhone" element={<VerifyOtpDash />}></Route>
          <Route path="/make-payment" element={<PaymentConfirmationPage />} />
          <Route path="/wallet-confirmation" element={<WalletPaymentPage />} />
          <Route
            path="/wallet-sub-confirmation"
            element={<WalletSubscriptionPage />}
          />
          <Route
            path="/app-sub-confirmation"
            element={<AppSubscriptionPage />}
          />
          <Route path="/wallet" element={<WalletPage />} />
          <Route path="/admin" element={<AdminDashboard />} />
          <Route path="/appointment" element={<AppointmentPage />} />
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/medic-settings" element={<MedicSettingsPage />} />
          <Route path="/edit-profile" element={<EdithProfilePage />} />
          <Route
            path="/medic-edit-profile"
            element={<MedicEdithProfilePage />}
          />
          <Route path="/change-password" element={<ChangePasswordPage />} />
          <Route path="/change-transaction-pin" element={<ChangeTransactionPinPage />} />
          <Route path="/medic-change-transPin" element={< MedicChangeTransactionPinPage/>} />
          <Route
            path="/medic-change-password"
            element={<MedicChangePasswordPage />}
          />
          <Route
            path="/notification-settings"
            element={<NotificationSettingsPage />}
          />
          <Route
            path="/medic-notification-settings"
            element={<MedicNotificationSettingsPage />}
          />
          <Route path="/help" element={<HelpPage />} />
          <Route path="/medic-help" element={<MedicHelpPage />} />
          <Route path="/services" element={<ServicePage />} />
          <Route path="/customize-service" element={<CustomizeServicePage />} />
          <Route path="/book" element={<BookButton />} />
          <Route path="/servicesSection" element={<ServicesSection />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/users/customers" element={<Customers />} />
          <Route path="/users/medics" element={<Medics />} />
          <Route path="/users/admins" element={<Admin />} />
          <Route path="/appointments" element={<Appointments />} />
          <Route path="/medical-reports" element={<MedicalReports />} />
          <Route path="/finance" element={<Finance />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
