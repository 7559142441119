import React, { useState } from "react";
import axios from "axios";
import {
  Box,
  Flex,
  Text,
  VStack,
  Button,
  Icon,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  useBreakpointValue,
  DrawerBody,
  DrawerCloseButton,
  Spinner,
  useDisclosure,
  DrawerFooter,
} from "@chakra-ui/react";
import { CopyIcon, CheckIcon } from "@chakra-ui/icons";
import { FaEye, FaEyeSlash, FaPlus, FaPiggyBank } from "react-icons/fa";
import { baseUrl } from "../../apiCalls/config";

const WalletBox = ({
  user,
  accountNumber,
  accountName,
  hasCopied,
  onCopy,
  handleOpenFundWalletModal,
  handleOpenWalletModal,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { walletCreated, walletBalance, pvbWalletId } = user;
  const [interests, setInterests] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const drawerSize = useBreakpointValue({ base: "full", md: "md" });
  const [showBalance, setShowBalance] = useState(false);

  const formatAmount = (amount) => {
    const num = Number(amount);
    return num.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const toggleBalanceVisibility = () => setShowBalance(!showBalance);

  const fetchAccruedInterests = async () => {
    setIsFetching(true); 
    setLoading(true);
    try {
      const response = await axios.get(
        `${baseUrl}/api/wallets/${pvbWalletId}/accrued-interests`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.data.success) {
        setInterests(response.data.data);
      } else {
        console.error("Failed to fetch interests:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching accrued interests:", error);
    } finally {
      setLoading(false);
      setIsFetching(false); 
      onOpen();
    }
  };

  return (
    <Box
      position="relative"
      textAlign="center"
      w={{ base: "375px", md: "910px" }}
      h={{ base: "151px", md: "250px" }}
      mt={{ base: "10px", md: "0" }}
      mb={{ base: "10px", md: "30" }}
      paddingBottom={{ base: "20px", md: "" }}
      bg="#A210C6"
      borderRadius="20px"
      overflow="hidden"
    >
      {/* Left Gradient Circles */}
     
      <Box
        position="absolute"
        bottom={{ base: "-50px", md: "20px" }}
        left={{ base: "-50px", md: "20px" }}
        w={{ base: "100px", md: "144px" }}
        h={{ base: "100px", md: "144px" }}
        borderRadius="50%"
        bg="#D087E2"
        opacity="0.3"
        zIndex={1}
      />
      <Box
        position="absolute"
        bottom={{ base: "-40px", md: "38px" }}
        left={{ base: "-40px", md: "38px" }}
        w={{ base: "80px", md: "113px" }}
        h={{ base: "80px", md: "113px" }}
        borderRadius="50%"
        bg="#ECCFF4"
        opacity="0.3"
        zIndex={1}
      />
      <Box
        position="absolute"
        bottom={{ base: "-30px", md: "55px" }}
        left={{ base: "-30px", md: "55px" }}
        w={{ base: "60px", md: "82px" }}
        h={{ base: "60px", md: "82px" }}
        borderRadius="50%"
        bg="#D087E2"
        opacity="1"
        zIndex={1}
      />

      {/* Right Gradient Circles */}
      <Box
        position="absolute"
        bottom={{ base: "-50px", md: "18px" }}
        right={{ base: "-50px", md: "75px" }}
        w={{ base: "100px", md: "144px" }}
        h={{ base: "100px", md: "144px" }}
        borderRadius="50%"
        bg="#D087E2"
        opacity="0.3"
        zIndex={1}
      />
      <Box
        position="absolute"
        bottom={{ base: "-40px", md: "28px" }}
        right={{ base: "-40px", md: "88px" }}
        w={{ base: "80px", md: "113px" }}
        h={{ base: "80px", md: "113px" }}
        borderRadius="50%"
        bg="#ECCFF4"
        opacity="0.3"
        zIndex={1}
      />
      <Box
        position="absolute"
        bottom={{ base: "-30px", md: "35px" }}
        right={{ base: "-30px", md: "100px" }}
        w={{ base: "60px", md: "82px" }}
        h={{ base: "60px", md: "82px" }}
        borderRadius="50%"
        bg="#D087E2"
        opacity="1"
        zIndex={1}
      />
      <Flex w={{ base: "90vw", md: "80vw" }} position="relative" zIndex={2}>
        <Box ml={{ base: "20px", md: "40px" }} pt={{ base: "px", md: "8px" }}>
          <Text
            fontSize="16px"
            fontFamily="body"
            color="white"
            marginTop="20px"
          >
            Mikul Health Wallet
          </Text>
          <Flex alignItems="center">
            <Text
              marginTop="2px"
              color="white"
              fontSize={{ base: "18px", md: "22px" }}
              textAlign="left"
            >
              ₦
              {walletCreated
                ? showBalance
                  ? formatAmount(walletBalance)
                  : "******"
                : "__.__"}
            </Text>
            {walletCreated && (
              <IconButton
                aria-label="Toggle Balance Visibility"
                icon={showBalance ? <FaEyeSlash /> : <FaEye />}
                onClick={toggleBalanceVisibility}
                ml={2}
                size="sm"
                bg="transparent"
                color="white"
              />
            )}
          </Flex>
        </Box>
        <VStack pt={{ base: "5px", md: "15px" }}>
          <Button
            leftIcon={<Icon as={FaPlus} />}
            padding={{ base: "5px", md: "5px" }}
            ml={{ base: "75px", md: "500px" }}
            w={{ base: "100px", md: "40%" }}
            h={{ base: "30px", md: "50%" }}
            fontSize={{ base: "12px", md: "16px" }}
            borderRadius="15px"
            color="#A210C6"
            marginTop="20px"
            onClick={
              walletCreated ? handleOpenFundWalletModal : handleOpenWalletModal
            }
            bg="white"
          >
            {walletCreated ? "Fund Wallet" : "Create Wallet"}
          </Button>
        </VStack>
      </Flex>
      <Flex
        ml={{ base: "20px", md: "40px" }}
        mt={{ base: "30px", md: "100px" }}
        position="relative"
        zIndex={2}
      >
        <Box
          fontWeight="bold"
          marginBottom={{ base: "50px", md: "50px" }}
          color="white"
        >
          <Text textAlign="left" fontSize={{ base: "10px", md: "16px" }}>
            Wallet ID:
          </Text>
          <Flex>
            <Text
              textAlign="left"
              fontSize={{ base: "10px", md: "16px" }}
              fontStyle={
                !walletCreated || !accountName || !accountNumber
                  ? "italic"
                  : "normal"
              }
            >
              {walletCreated && accountName && accountNumber
                ? accountName
                : "Processing, please wait..."}
            </Text>
            <Text
              ml="10px"
              textAlign="left"
              fontSize={{ base: "10px", md: "16px" }}
              fontStyle={
                !walletCreated || !accountName || !accountNumber
                  ? "italic"
                  : "normal"
              }
            >
              {walletCreated && accountName && accountNumber
                ? accountNumber
                : ""}
            </Text>
            {walletCreated && accountName && accountNumber && (
              <IconButton
                icon={hasCopied ? <CheckIcon /> : <CopyIcon />}
                onClick={onCopy}
                mt="-5px"
                size="sm"
                aria-label="Copy account number"
                color="white"
                bg={hasCopied ? "#A210C6" : "transparent"}
                _hover={{ bg: "transparent" }}
              />
            )}
          </Flex>
        </Box>
        {walletCreated && (
          <Flex fontWeight="bold" marginLeft={{ base: "50px", md: "400px" }}>
            <Button
              leftIcon={<Icon as={FaPiggyBank} />}
              bg="green.200"
              fontStyle="italic"
              w={{ base: "auto", md: "auto" }}
              h={{ base: "30px", md: "6vh" }}
              color="#4B4B4B"
              fontSize={{ base: "12px", md: "16px" }}
              display="flex"
              alignItems="center"
              borderRadius="100px"
              justifyContent="space-between"
              px="20px"
              onClick={fetchAccruedInterests}
            >
              {isFetching ? (
                <>
                  <Spinner size="sm" mr="8px" />
                  Fetching...
                </>
              ) : (
                "Interest"
              )}
            </Button>
          </Flex>
        )}
      </Flex>
      <Drawer
        size={drawerSize}
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent overflow="auto">
          <DrawerCloseButton />
          <DrawerHeader color="#A210C6">Accrued Interests</DrawerHeader>
          <DrawerBody>
            {loading ? (
              <Flex justifyContent="center" alignItems="center" height="100%">
                <Spinner />
              </Flex>
            ) : interests && interests.length > 0 ? (
              <Box w="100%" overflowX="auto">
                <Text fontWeight="bold" fontSize="lg" mb="4">
                  Interest Breakdown
                </Text>
                <Box
                  as="table"
                  width="100%"
                  border="1px solid #E2E8F0"
                  borderRadius="10px"
                  boxShadow="0 2px 4px rgba(0, 0, 0, 0.1)"
                  bg="white"
                >
                  <Box as="thead" bg="#F7FAFC" borderBottom="1px solid #E2E8F0">
                    <Box as="tr">
                      <Box
                        as="th"
                        textAlign="left"
                        fontWeight="bold"
                        py="2"
                        px="4"
                      >
                        Date
                      </Box>
                      <Box
                        as="th"
                        textAlign="left"
                        fontWeight="bold"
                        py="2"
                        px="4"
                      >
                        Interest
                      </Box>
                      <Box
                        as="th"
                        textAlign="left"
                        fontWeight="bold"
                        py="2"
                        px="4"
                      >
                        Balance
                      </Box>
                    </Box>
                  </Box>
                  <Box as="tbody">
                    {interests.map((interest, index) => (
                      <Box
                        as="tr"
                        key={index}
                        borderBottom="1px solid #E2E8F0"
                        _last={{ borderBottom: "none" }}
                      >
                        <Box as="td" py="2" px="4">
                          {interest.interestDate}
                        </Box>
                        <Box as="td" py="2" px="4">
                          {interest.interestValue}
                        </Box>
                        <Box as="td" py="2" px="4">
                          {interest.balance}
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            ) : (
              <Text>No interest data available.</Text>
            )}
          </DrawerBody>
          <DrawerFooter>
            <Button
              fontSize="19px"
              marginRight="20px"
              bg="gray"
              onClick={onClose}
              fontFamily="heading"
              style={{
                color: "white",
                fontStyle: "italic",
                cursor: "pointer",
              }}
              _hover={{ color: "#A210C6" }}
            >
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default WalletBox;
