import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {
  Box,
  Flex,
  Text,
  Input,
  Divider,
  InputLeftElement,
  InputGroup,
  Table,
  Spinner,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { format, formatDistanceToNow } from "date-fns";
import { baseUrl } from "../../../apiCalls/config";

const UserActivities = () => {
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [nextPageLoading, setNextPageLoading] = useState(false); // Loading state for next page
  const [prevPageLoading, setPrevPageLoading] = useState(false); // Loading state for previous page
  const [currentTime, setCurrentTime] = useState(new Date());
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  const fetchActivities = useCallback(async (page) => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, redirecting to login.");
      window.location.href = "/login";
      return;
    }

    try {
      // Determine if we should show the loading spinner for next or previous page
      if (page > currentPage) {
        setNextPageLoading(true);
      } else {
        setPrevPageLoading(true);
      }

      const response = await axios.get(
        `${baseUrl}/api/log/activities/past-three-days?page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const sortedActivities = response.data.content.sort(
        (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
      );
      setActivities(sortedActivities);
      setTotalPages(response.data.totalPages);
      setCurrentPage(page);
    } catch (error) {
      console.error("Error fetching activities:", error);
    } finally {
      // Ensure loading states are reset
      setLoading(false);
      setNextPageLoading(false);
      setPrevPageLoading(false);
    }
  }, [currentPage]);

  useEffect(() => {
    fetchActivities(currentPage);
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, [fetchActivities, currentPage]);

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      fetchActivities(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      fetchActivities(currentPage - 1);
    }
  };

  return (
    <Box
      bg="#4B4B4B"
      borderRadius="10px"
      p={4}
      color="white"
      w="350px"
      maxH="700px"
    >
      <Flex justifyContent="space-between">
        <Text textAlign="left" fontSize="lg" fontWeight="bold">
          Recent Activity Log
        </Text>
      </Flex>

      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <InputGroup>
          <Input
            placeholder="Search by date"
            backgroundColor="#4B4B4B"
            color="white"
            borderRadius="10px"
            width="200px"
          />
          <InputLeftElement
            children={<SearchIcon color="white" />}
            pointerEvents="none"
          />
        </InputGroup>
        <Text fontSize="14px" fontWeight="bold">
          {format(currentTime, "dd MMMM yyyy, hh:mm:ss a")}
        </Text>
      </Flex>

      <Box
        className="activities"
        maxH="300px"
        w="90%"
        overflowY="auto"
        css={{
          "&::-webkit-scrollbar": { display: "none" },
          "-ms-overflow-style": "none" /* IE and Edge */,
          "scrollbar-width": "none" /* Firefox */,
        }}
      >
        {loading ? (
          <Flex justifyContent="center" alignItems="center" height="100%">
            <Spinner size="xl" color="white" />
          </Flex>
        ) : (
          <Table variant="simple" colorScheme="whiteAlpha">
            <Thead
              css={{
                position: "sticky",
                top: 0,
                background: "#4B4B4B",
                zIndex: 1,
              }}
            >
              <Tr>
                <Th color="purple.200">Name</Th>
                <Th color="purple.200">Action</Th>
                <Th color="purple.200">Timestamp</Th>
                <Th color="purple.200">User ID</Th>
              </Tr>
            </Thead>
            <Tbody fontSize="11px">
              {activities.map((activity) => (
                <Tr key={activity.id} _hover={{ bg: "#3B3B3B" }}>
                  <Td>{activity.name}</Td>
                  <Td>{activity.action}</Td>
                  <Td>
                    {formatDistanceToNow(new Date(activity.timestamp), {
                      addSuffix: true,
                    })}
                  </Td>
                  <Td>{activity.userId}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </Box>
      <Divider />
      <Flex justifyContent="space-between" mt={2} alignItems="center">
        <Text
          fontSize="12px"
          onClick={handlePrevPage}
          isDisabled={currentPage === 0 || prevPageLoading}
          color={prevPageLoading ? "gray.500" : "yellow.200"}
          cursor={prevPageLoading || currentPage === 0 ? "not-allowed" : "pointer"}
        >
          {prevPageLoading ? (
            <Spinner size="sm" color="yellow.200" />
          ) : (
            "Previous"
          )}
        </Text>
        <Text
          fontSize="12px"
          onClick={handleNextPage}
          isDisabled={currentPage >= totalPages - 1 || nextPageLoading}
          color={nextPageLoading ? "gray.500" : "green.200"}
          cursor={nextPageLoading || currentPage >= totalPages - 1 ? "not-allowed" : "pointer"}
        >
          {nextPageLoading ? (
            <Spinner size="sm" color="green.200" />
          ) : (
            "Next"
          )}
        </Text>
      </Flex>
    </Box>
  );
};

export default UserActivities;
