import React, { useState } from "react";
import LeftSideBar from "../authLayouts/LeftSideBar";
import { useSelector } from "react-redux";
import NavBar from "../authLayouts/NavBar";
import { useNavigate } from "react-router-dom";
import {
  ChakraProvider,
  VStack,
  Input,
  Button,
  InputGroup,
  InputRightElement,
  Box,
  Text,
  IconButton,
  Flex,
  FormControl,
  FormLabel,
  Spinner,
  extendTheme,
} from "@chakra-ui/react";
import { HiEye, HiEyeOff } from "react-icons/hi";
import SettingsSideBar from "../authLayouts/SettingsSideBar";
import MobileFooter from "../authLayouts/MobileFooter";
import { baseUrl } from "../../apiCalls/config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const customTheme = extendTheme({
  components: {
    Link: {
      baseStyle: {
        _focus: {
          boxShadow: "none",
        },
      },
    },
  },
  fonts: {
    body: "Montserrat, sans-serif",
    heading: "Gill Sans MT, sans-serif",
  },
});

const ChangeTransactionPinPage = () => {
  const navigate = useNavigate();
  const [newPin, setNewPin] = useState("");
  const { user } = useSelector((state) => state.userReducer);
  const [confirmPin, setConfirmPin] = useState("");
  const [showNewPin, setShowNewPin] = useState(false);
  const [showConfirmPin, setShowConfirmPin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleToggleNewPin = () => {
    setShowNewPin(!showNewPin);
  };

  const handleToggleConfirmPin = () => {
    setShowConfirmPin(!showConfirmPin);
  };

  const handleNewPinChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    if (value.length <= 4) {
      setNewPin(value);
    }
  };

  const handleConfirmPinChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    if (value.length <= 4) {
      setConfirmPin(value);
    }
  };

  const validate = (values) => {
    let errors = {};

    if (!values.newPin) errors.newPin = "*Required";

    if (!values.confirmPin) errors.confirmPin = "*Required";

    if (values.newPin !== values.confirmPin)
      errors.confirmPin = "Pins do not match";

    return errors;
  };

  const handleSaveChanges = async () => {
    const validationErrors = validate({ newPin, confirmPin });

    if (Object.keys(validationErrors).length > 0) {
      Object.values(validationErrors).forEach((error) => {
        toast.error(error);
      });
      return;
    }

    setIsLoading(true);
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(
        `${baseUrl}/api/wallets/transaction-pin/reset`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            userId: user.userId,
            newPin,
          }),
        }
      );

      const responseData = await response.json();

      if (response.ok && responseData.success) {
        setIsLoading(false);
        toast.success("Transaction PIN changed successfully");
        setTimeout(() => {
          navigate("/client-dashboard");
        }, 3000);
      } else {
        console.error("API error:", responseData.message);
        toast.error("Failed to change transaction PIN");
      }
    } catch (error) {
      console.error("Network error:", error.message);
      toast.error("Network error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleback = () => {
    navigate("/settings");
  };

  return (
    <ChakraProvider theme={customTheme}>
      <ToastContainer />
      <LeftSideBar />
      <VStack
        position="fixed"
        ml={{ base: "40px", md: "280px" }}
        w="70%"
        h="100vh"
      >
        <Box w="90%" position="sticky" top="0" zIndex="1000">
          <NavBar />
        </Box>
        <Flex
          display={{ base: "none", md: "flex" }}
          mt={{ md: "30px" }}
          ml={{ base: "50px", md: "-150px" }}
        >
          <SettingsSideBar />
          <Box p={3}>
            <VStack w="30vw" marginLeft="10px">
              <Text
                textAlign="left"
                fontSize={{ base: "12px", md: "20px" }}
                marginTop="3px"
                fontWeight="bold"
                marginBottom="20px"
                fontFamily="heading"
              >
                Change Transaction PIN
              </Text>
              <Text
                textAlign="left"
                fontSize={{ base: "12px", md: "16px" }}
                marginTop="3px"
                marginBottom="20px"
                fontFamily="body"
              >
                To change your transaction pin, please ensure you have created
                one before during a transaction.
              </Text>

              <FormControl>
                <FormLabel fontFamily="body">New Transaction PIN</FormLabel>
                <InputGroup>
                  <Input
                    type={showNewPin ? "text" : "password"}
                    value={newPin}
                    onChange={handleNewPinChange}
                    borderColor="black"
                    placeholder="Enter 4-digit PIN"
                  />
                  <InputRightElement width="4.5rem">
                    <IconButton
                      h="1.75rem"
                      size="sm"
                      onClick={handleToggleNewPin}
                      icon={showNewPin ? <HiEyeOff /> : <HiEye />}
                    />
                  </InputRightElement>
                </InputGroup>
              </FormControl>

              <FormControl>
                <FormLabel fontFamily="body">
                  Confirm New Transaction PIN
                </FormLabel>
                <InputGroup>
                  <Input
                    type={showConfirmPin ? "text" : "password"}
                    value={confirmPin}
                    onChange={handleConfirmPinChange}
                    borderColor="black"
                    placeholder="Re-enter 4-digit PIN"
                  />
                  <InputRightElement width="4.5rem">
                    <IconButton
                      h="1.75rem"
                      size="sm"
                      onClick={handleToggleConfirmPin}
                      icon={showConfirmPin ? <HiEyeOff /> : <HiEye />}
                    />
                  </InputRightElement>
                </InputGroup>
              </FormControl>

              <Button
                fontFamily="body"
                color="white"
                bg="#A210C6"
                onClick={handleSaveChanges}
                isLoading={isLoading}
                spinner={<Spinner size="sm" />}
              >
                Save Changes
              </Button>
            </VStack>
          </Box>
        </Flex>

        <Flex
          display={{ base: "block", md: "none" }}
          w="100%"
          mt={{ base: "20px" }}
        >
          <Flex justifyContent="space-between">
            <Box>
              <Text
                textAlign="left"
                fontWeight="bold"
                fontSize={{ base: "18px" }}
                marginTop="3px"
                fontFamily="heading"
              >
                Change Transaction Pin
              </Text>
              <Text
                textAlign="left"
                fontSize={{ base: "12px", md: "16px" }}
                marginTop="3px"
                marginBottom="20px"
                fontFamily="body"
              >
                To change your transaction pin, please ensure you have created
                one before during a transaction.
              </Text>
            </Box>
            <Button
              onClick={handleback}
              borderColor="#A210C6"
              borderWidth="1px"
              color="#A210C6"
              fontFamily="body"
              _hover={{ color: "" }}
              fontSize={{ base: "12px" }}
              h="3vh"
              borderRadius="100px"
            >
              Back
            </Button>
          </Flex>

          <Box className="change-password" p={3}>
            <VStack ml="15px" w="full">
              <FormControl>
                <FormLabel fontFamily="body">New Transaction PIN</FormLabel>
                <InputGroup>
                  <Input
                    type={showNewPin ? "text" : "password"}
                    value={newPin}
                    onChange={handleNewPinChange}
                    borderColor="black"
                    placeholder="Enter 4-digit PIN"
                  />
                  <InputRightElement width="4.5rem">
                    <IconButton
                      h="1.75rem"
                      size="sm"
                      onClick={handleToggleNewPin}
                      icon={showNewPin ? <HiEyeOff /> : <HiEye />}
                    />
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel fontFamily="body">
                  Confirm New Transaction PIN
                </FormLabel>
                <InputGroup>
                  <Input
                    type={showConfirmPin ? "text" : "password"}
                    value={confirmPin}
                    onChange={handleConfirmPinChange}
                    borderColor="black"
                    placeholder="Re-enter 4-digit PIN"
                  />
                  <InputRightElement width="4.5rem">
                    <IconButton
                      h="1.75rem"
                      size="sm"
                      onClick={handleToggleConfirmPin}
                      icon={showConfirmPin ? <HiEyeOff /> : <HiEye />}
                    />
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Button
                fontFamily="body"
                color="white"
                bg="#A210C6"
                onClick={handleSaveChanges}
                isLoading={isLoading}
                spinner={<Spinner size="sm" />}
              >
                Save Changes
              </Button>
            </VStack>
          </Box>
        </Flex>
        <MobileFooter />
      </VStack>
    </ChakraProvider>
  );
};

export default ChangeTransactionPinPage;
