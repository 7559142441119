import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import axios from "axios";
import AppointmentDetailsDrawer from "./AppointmentDetailsDrawer";
import { baseUrl } from "../../../apiCalls/config";

const formatDate = (dateString) => {
  if (!dateString) return "N/A";
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const formatDateTime = (dateString) => {
  if (!dateString) return "N/A";
  const options = {
    year: 'numeric', month: 'long', day: 'numeric',
    hour: '2-digit', minute: '2-digit', second: '2-digit',
    hour12: true, // You can set hour12 to false if you prefer 24-hour time format
  };
  return new Date(dateString).toLocaleString(undefined, options);
};

const CustomerAppointments = () => {
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleClose = () => {
    setSelectedAppointment(null); 
    setDrawerOpen(false)  
  };


  useEffect(() => {
    const fetchAppointments = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found, redirecting to login.");
        window.location.href = "/login";
        return;
      }

      try {
        const response = await axios.get(
          `${baseUrl}/api/admin/all-appointments`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setAppointments(response.data.data);
      } catch (error) {
        console.error("Error fetching appointments:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAppointments();
  }, []);

  const filterAppointments = (type) => {
    if (!Array.isArray(appointments)) {
      console.error("Appointments is not an array", appointments);
      return [];
    }

    switch (type) {
      case "active":
        return appointments.filter(
          (appointment) => appointment.appointmentActive
        );
      case "completed":
        return appointments.filter(
          (appointment) => appointment.appointmentCompleted
        );
      default:
        return appointments;
    }
  };

  const handleRowClick = (appointment) => {
    setSelectedAppointment(appointment);
    setDrawerOpen(true);
  };

  return (
    <Box
      maxH="600px"
      w="100%"
      overflowY="auto"
      css={{
        "&::-webkit-scrollbar": { display: "none" },
        "-ms-overflow-style": "none" /* IE and Edge */,
        "scrollbar-width": "none" /* Firefox */,
      }}
    >
      <Box bg="#4B4B4B" borderRadius="10px" p={4} color="white" w="100%">
        <Tabs variant="soft-rounded">
          <TabList>
            <Tab color="blue.200">Active</Tab>
            <Tab color="green.200">Completed</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              {loading ? (
                <Flex justifyContent="center" alignItems="center" h="200px">
                  <Spinner size="xl" />
                </Flex>
              ) : (
                <AppointmentTable
                  appointments={filterAppointments("active")}
                  onRowClick={handleRowClick}
                />
              )}
            </TabPanel>
            <TabPanel>
              {loading ? (
                <Flex justifyContent="center" alignItems="center" h="200px">
                  <Spinner size="xl" />
                </Flex>
              ) : (
                <AppointmentTable
                  appointments={filterAppointments("completed")}
                  onRowClick={handleRowClick}
                />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>

      {selectedAppointment && (
        <AppointmentDetailsDrawer
          isOpen={drawerOpen}
          onClose={handleClose}
          appointment={selectedAppointment}
        />
      )}
    </Box>
  );
};

const AppointmentTable = ({ appointments, onRowClick }) => (
  <>
    {appointments.length > 0 ? (
      <Box overflow="auto" maxH="600px" w="100%">
        <Table variant="simple" colorScheme="whiteAlpha">
          <Thead
            css={{
              position: "sticky",
              top: 0,
              background: "#4B4B4B",
              zIndex: 1,
            }}
          >
            <Tr>
              <Th color="purple.200">Recipient Name</Th>
              <Th color="purple.200">Medic Name</Th>
              <Th color="purple.200">Start Date</Th>
              <Th color="purple.200">End Date</Th>
              <Th color="purple.200">Sub</Th>
              <Th color="purple.200">Last Report Sent</Th>
            </Tr>
          </Thead>
          <Tbody>
            {appointments.map((appointment) => (
              <Tr
                key={appointment.id}
                onClick={() => onRowClick(appointment)}
                cursor="pointer"
                fontSize="13px"
                _hover={{ bg: "#3B3B3B" }}
              >
                <Td>
                  {appointment.recipientFirstname}{" "}
                  {appointment.recipientLastname}
                </Td>
                <Td>
                  {" "}
                  {appointment.matchedMedic?.bioData.firstName}{" "}
                  {appointment.matchedMedic?.bioData.lastName}{" "}
                </Td>
                <Td>{formatDate(appointment.actualStartDate || "N/A")}</Td>
                <Td>{formatDate(appointment.actualEndDate || "N/A")}</Td>
                <Td>{appointment.subscription ? "Yes" : "No"}</Td>
                <Td>{formatDateTime(appointment.lastReportSent || "N/A")}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    ) : (
      <Text>No appointments in this category at the moment</Text>
    )}
  </>
);

export default CustomerAppointments;
