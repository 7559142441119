import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  VStack,
  HStack,
  Box,
  Icon,
} from "@chakra-ui/react";
import {
  FaPiggyBank,
  FaCreditCard,
  FaHandsHelping,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const MedicLearnMoreModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/medic-wallet");
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={{ base: "full", sm: "lg", md: "xl" }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color="#A210C6">Your MH Wallet</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack align="stretch" spacing={4}>
            <Box>
              <HStack spacing={2}>
                <Icon as={FaCreditCard} w={5} h={5} color="teal.500" />
                <Text fontWeight="bold">Seamless Payments, Seamless Care</Text>
              </HStack>
              <Text pl={7}>
                Facilitate your professional services with our MH Wallet,
                ensuring prompt and effortless transactions directly from your
                practice.
              </Text>
            </Box>
            <Box>
              <HStack spacing={2}>
                <Icon as={FaPiggyBank} w={5} h={5} color="green.500" />
                <Text fontWeight="bold">
                  Invest in Your Practice, Watch It Grow
                </Text>
              </HStack>
              <Text pl={7}>
                Secure your financial future by investing directly through your
                MH Wallet. Watch your practice thrive as your investments yield
                substantial returns, 5% interest annually.
              </Text>
            </Box>
            <Box>
              <HStack spacing={2}>
                <Icon as={FaHandsHelping} w={5} h={5} color="purple.500" />
                <Text fontWeight="bold">
                  Earn More, Worry Less with MH Wallet
                </Text>
              </HStack>
              <Text pl={7}>
                Leverage the MH Wallet to not only manage but also magnify your
                earnings, reducing financial worries with competitive interest
                rates.
              </Text>
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            bgGradient="linear(to-r, #A210C6, #E552FF)"
            borderRadius="100px"
            color="white"
            onClick={handleButtonClick}
          >
            Explore MH Wallet
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MedicLearnMoreModal;
