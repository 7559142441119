import React, { useState } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Box,
  Text,
  VStack,
  Divider,
  Flex,
  Avatar,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import axios from "axios";
import MedicDetailsModal from "./MedicDetailsDrawer ";
import EditAppointmentModal from "./EditAppointmentModal";
import CancelAppointmentModal from "./CancelAppointmentModal";
import RematchMedicModal from "./RematchMedicModal";
import RebookCustomerAppointmentModal from "./RebookCustomerApp";
import { baseUrl } from "../../../apiCalls/config";

const AppointmentDetailsDrawer = ({ isOpen, onClose, appointment }) => {
  const [isMedicModalOpen, setMedicModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isCancelModalOpen, setCancelModalOpen] = useState(false);
  const [isRematchModalOpen, setRematchModalOpen] = useState(false);
  const [isRebookModalOpen, setRebookModalOpen] = useState(false);
  const [isCompleteModalOpen, setCompleteModalOpen] = useState(false);
  const [isCompleting, setIsCompleting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTriggeringMatch, setIsTriggeringMatch] = useState(false);

  const toast = useToast();

  const handleMedicModalOpen = () => {
    setMedicModalOpen(true);
  };

  const handleMedicModalClose = () => {
    setMedicModalOpen(false);
  };

  const handleEditModalOpen = () => {
    setEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setEditModalOpen(false);
  };

  const handleCancelModalOpen = () => setCancelModalOpen(true);
  const handleCancelModalClose = () => setCancelModalOpen(false);

  const handleRematchModalOpen = () => setRematchModalOpen(true);
  const handleRematchModalClose = () => setRematchModalOpen(false);

  const handleRebookModalOpen = () => setRebookModalOpen(true);
  const handleRebookModalClose = () => setRebookModalOpen(false);

  const handleCompleteModalOpen = () => setCompleteModalOpen(true);
  const handleCompleteModalClose = () => setCompleteModalOpen(false);

  const handleCompleteAppointment = async () => {
    setIsCompleting(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${baseUrl}/api/admin/complete-appointment/${appointment.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.success) {
        toast({
          title: "Appointment Completed",
          description: "The appointment has been marked as completed.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        onClose();
      } else {
        toast({
          title: "Error",
          description: "Failed to complete the appointment.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred while completing the appointment.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsCompleting(false);
      setCompleteModalOpen(false);
    }
  };

  const formatAmount = (amount) => {
    const num = Number(amount);
    return num.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const handleResolveRematchRequest = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("No token found, redirecting to login.");
      window.location.href = "/login";
      return;
    }

    setIsLoading(true);

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };

      const response = await axios.post(
        `${baseUrl}/api/admin/rematch/resolve/${appointment.id}`,
        null,
        config
      );

      if (response.data.success) {
        toast({
          title: "Rematch request resolved successfully.",
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("Error resolving rematch request:", error);
      toast({
        title: "Failed to resolve rematch request.",
        description: error.response?.data?.message || error.message,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-left",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const formatDateTime = (dateString) => {
    if (!dateString) return "N/A";
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true, // You can set hour12 to false if you prefer 24-hour time format
    };
    return new Date(dateString).toLocaleString(undefined, options);
  };

  const handleTriggerMatch = async () => {
    const token = localStorage.getItem("token");

    setIsTriggeringMatch(true);

    try {
      const response = await axios.get(
        `${baseUrl}/appointment/trigger-match/${appointment.customerPhoneNumber}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast({
        title: "Success",
        description: response.data.message,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.response?.data?.message || "An error occurred",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setIsTriggeringMatch(false);
    }
  };

  return (
    <>
      <Drawer
        blockScrollOnMount={false}
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        size="md"
        style={{ zIndex: 1400 }}
      >
        <DrawerOverlay />
        <DrawerContent bg="#4B4B4B" color="white">
          <DrawerCloseButton />
          <DrawerHeader>Appointment Details</DrawerHeader>

          <DrawerBody>
            <VStack align="left" spacing={4}>
              {appointment.matchedMedic && (
                <Flex justifyContent="center">
                  <Avatar
                    size="xl"
                    alt={`${appointment.matchedMedic.bioData.firstName} ${appointment.matchedMedic.bioData.lastName}`}
                    cursor="pointer"
                  />
                </Flex>
              )}
              <Text fontSize="2xl" fontWeight="bold">
                {appointment.recipientFirstname} {appointment.recipientLastname}
              </Text>
              {appointment.paid &&
                !appointment.appointmentActive &&
                !appointment.appointmentActive &&
                !appointment.appointmentCompleted && (
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text>
                      Customer Name: {appointment.customerName || "N/A"}
                    </Text>
                    <Button
                      colorScheme="blue"
                      onClick={handleTriggerMatch}
                      size="sm"
                      isDisabled={isTriggeringMatch}
                      leftIcon={isTriggeringMatch && <Spinner size="sm" />}
                    >
                      {isTriggeringMatch ? "Triggering..." : "Trigger Matching"}
                    </Button>
                  </Flex>
                )}
              <Text>
                Recipient Phone Number: {appointment.recipientPhoneNumber}
              </Text>
              <Text>Gender: {appointment.recipientGender}</Text>
              <Text>
                Date of Birth:{" "}
                {new Date(appointment.recipientDOB).toLocaleDateString()}
              </Text>
              <Text>
                Relationship with Customer: {appointment.relationship || "N/A"}
              </Text>
              <Text>Customer: {appointment.customerName || "N/A"}</Text>
              <Text>Recipient State: {appointment.state || "N/A"}</Text>
              <Text>Recipient Town: {appointment.recipientTown || "N/A"}</Text>
              <Text>Current Location: {appointment.currentLocation}</Text>
              <Text>Health History: {appointment.recipientHealthHistory}</Text>
              <Text>
                Special Needs: {appointment.specialNeeds?.join(", ") || "N/A"}
              </Text>
              <Divider />
              <Text>Shift: {appointment.shift}</Text>
              <Text>Service Plan: {appointment.servicePlan}</Text>
              <Text>Caregiver Type: {appointment.medicSpecialization}</Text>

              <Text>
                Preferred Religion: {appointment.preferredReligion || "N/A"}
              </Text>
              <Text>
                Preferred Medic Gender:{" "}
                {appointment.preferredMedicGender || "N/A"}
              </Text>
              <Text>
                Preferred Tribe/Language:{" "}
                {appointment.preferredLanguage || "N/A"}
              </Text>
              <Divider />
              <Text>
                Cost of Service: ₦{formatAmount(appointment.costOfService)}
              </Text>
              <Text>
                Amount payable: ₦
                {formatAmount(appointment.amountPayable) || "N/A"}
              </Text>
              <Text>
                Start Date:{" "}
                {new Date(appointment.startDate).toLocaleDateString()}
              </Text>
              <Text>
                End Date: {new Date(appointment.endDate).toLocaleDateString()}
              </Text>
              <Text>
                Actual Start Date:{" "}
                {new Date(appointment.actualStartDate).toLocaleDateString()}
              </Text>
              <Text>
                Actual End Date:{" "}
                {new Date(appointment.actualEndDate).toLocaleDateString()}
              </Text>
              <Text>Duration: {appointment.duration}</Text>
              <Text>Created At: {formatDateTime(appointment.createdAt)}</Text>
              <Text>Updated At: {formatDateTime(appointment.updatedAt)}</Text>
              <Text>Rebooked On: {formatDateTime(appointment.reBookedAt)}</Text>
              <Divider />
              <Text>Customer ID: {appointment.customerId}</Text>
              <Text>Payment ID: {appointment.paymentId || "N/A"}</Text>
              <Text>Appointment ID: {appointment.id || "N/A"}</Text>
              <Text>Policy Number: {appointment.policyNumber || "N/A"}</Text>
              <Text>Priority: {appointment.priority || "N/A"}</Text>
              <Text>
                Status:{" "}
                {appointment.appointmentActive
                  ? "Active"
                  : appointment.appointmentCompleted
                  ? "Completed"
                  : "Pending"}
              </Text>
              <Text>
                Need Medical Report:{" "}
                {appointment.needMedicalReport ? "Yes" : "No"}
              </Text>
              <Text>
                Subscription: {appointment.subscription ? "Yes" : "No"}
              </Text>
              <Text>Paid: {appointment.paid ? "Yes" : "No"}</Text>
              <Text>Refunded: {appointment.refunded ? "Yes" : "No"}</Text>
              <Text>Medic Paid: {appointment.medicPaid ? "Yes" : "No"}</Text>
              <Text>
                Last Report Sent: {formatDateTime(appointment.lastReportSent)}
              </Text>
              <Text>
                Last Report Reminder: {formatDateTime(appointment.lastReminder)}
              </Text>
              <Text>
                Last Subscription Reminder:{" "}
                {formatDateTime(appointment.lastSubscriptionReminder)}
              </Text>

              <Divider />
              {appointment.matchedMedic && (
                <Box>
                  <Text fontSize="lg" fontWeight="bold">
                    Medic Details
                  </Text>
                  <Text>
                    Medic Name: {appointment.matchedMedic.bioData.firstName}{" "}
                    {appointment.matchedMedic.bioData.lastName}{" "}
                  </Text>
                  <Text>Medic Type: {appointment.matchedMedic.medicType}</Text>
                  <Text>
                    Start date:{" "}
                    {appointment.matchedMedic?.appointmentStartDate || "N/A"}
                  </Text>
                  <Text>
                    End date:{" "}
                    {appointment.matchedMedic?.appointmentEndDate || "N/A"}
                  </Text>

                  <Text>
                    Years of Experience: {appointment.matchedMedic.yearsOfExp}
                  </Text>
                </Box>
              )}
              <Divider />
              {appointment.matchedMedic && (
                <Button colorScheme="teal" onClick={handleMedicModalOpen}>
                  View Medic Details
                </Button>
              )}

              {/* Rematch Medic Button */}
              {(appointment.appointmentActive ||
                appointment.appointmentPending) && (
                <>
                  <Button
                    colorScheme="yellow"
                    variant="outline"
                    onClick={handleRematchModalOpen}
                  >
                    Rematch Medic
                  </Button>
                  <Button
                    variant="outline"
                    colorScheme="green"
                    onClick={handleCompleteModalOpen}
                  >
                    Complete Appointment
                  </Button>
                </>
              )}
              {appointment.appointmentActive &&
                appointment.rematchRequested && (
                  <>
                    <Button
                      bg="blue.300"
                      variant="outline"
                      color="white"
                      onClick={handleResolveRematchRequest}
                      isDisabled={isLoading}
                    >
                      {isLoading ? (
                        <Flex alignItems="center">
                          <Spinner size="sm" mr={2} />
                          Processing...
                        </Flex>
                      ) : (
                        "Resolve Rematch Request"
                      )}
                    </Button>
                  </>
                )}

              {appointment.appointmentCompleted && (
                <Button
                  variant="outline"
                  colorScheme="white"
                  onClick={handleRebookModalOpen}
                >
                  Rebook Appointment
                </Button>
              )}
            </VStack>
          </DrawerBody>

          <DrawerFooter justifyContent="space-between">
            {/* <Button
              variant="outline"
              colorScheme="red"
              onClick={handleCancelModalOpen}
            >
              Cancel Appointment
            </Button> */}

            {(appointment.appointmentPending ||
              appointment.appointmentMatched) && (
              <Button
                variant="outline"
                colorScheme="red"
                onClick={handleCancelModalOpen}
              >
                Cancel Appointment
              </Button>
            )}
            <Button
              variant="outline"
              colorScheme="blue"
              onClick={handleEditModalOpen}
            >
              Edit Appointment
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      {appointment.matchedMedic && (
        <MedicDetailsModal
          medic={appointment.matchedMedic}
          isOpen={isMedicModalOpen}
          onClose={handleMedicModalClose}
        />
      )}

      {appointment && (
        <EditAppointmentModal
          isOpen={isEditModalOpen}
          onClose={handleEditModalClose}
          appointment={appointment}
        />
      )}

      <CancelAppointmentModal
        isOpen={isCancelModalOpen}
        onClose={handleCancelModalClose}
        appointmentId={appointment.id}
      />

      {appointment && (
        <RematchMedicModal
          isOpen={isRematchModalOpen}
          onClose={handleRematchModalClose}
          appointmentId={appointment.id}
          appointmentActualEndDate={appointment.actualEndDate}
          appointmentActualStartDate={appointment.actualStartDate}
        />
      )}

      {appointment && (
        <RebookCustomerAppointmentModal
          isOpen={isRebookModalOpen}
          onClose={handleRebookModalClose}
          appointment={appointment}
        />
      )}

      {/* Complete Appointment Modal */}
      <Modal
        isOpen={isCompleteModalOpen}
        onClose={handleCompleteModalClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent bg="#4B4B4B" color="white">
          <ModalHeader>Complete Appointment</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Are you sure you want to mark this appointment as complete?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              bg="gray.500"
              color="white"
              onClick={handleCompleteModalClose}
              isDisabled={isCompleting}
            >
              Cancel
            </Button>
            <Button
              colorScheme="green"
              onClick={handleCompleteAppointment}
              isLoading={isCompleting}
              ml={3}
              isDisabled={isCompleting}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AppointmentDetailsDrawer;
