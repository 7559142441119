import React, { useState } from "react";
import {
  Flex,
  Text,
  Box,
  Image,
  VStack,
  Button,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  extendTheme,
  Icon,
} from "@chakra-ui/react";
import {
  DownloadIcon,
  TimeIcon,
} from "@chakra-ui/icons";
import {
  FaHeartbeat,
  FaThermometerHalf,
  FaSmile,
  FaProcedures,
  FaBed,
  FaUserMd,
  FaFileMedical,
  FaRegCommentDots,
  FaMapMarkerAlt,
  FaBandAid,
  FaUtensils,
  FaToilet,
  FaToiletPaper,
  FaRunning,
  FaGlassWhiskey,
  FaPills,
  FaPen,
} from "react-icons/fa";

const customTheme = extendTheme({
  components: {
    Link: {
      baseStyle: {
        _focus: {
          boxShadow: "none",
        },
      },
    },
  },
  fonts: {
    body: "Montserrat, sans-serif",
    heading: "Gill Sans MT, sans-serif",
  },
});

const ReportDetails = ({
  selectedReport,
  setSelectedReport,
  openPasswordModal,
  handleViewRecommendations,
  isDownloading,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    return `${date.toLocaleDateString()}, ${date.toLocaleTimeString()}`;
  };

  const formatMedicationTime = (medication) => {
    const parts = medication.split(",");
    const timePart = parts.find((part) => part.startsWith("Time:"));
    const time = timePart
      ? new Date(timePart.replace("Time:", ""))
      : new Date(NaN);
    return `${parts[0]}, ${parts[1]}, ${parts[2]}, ${
      !isNaN(time.getTime())
        ? `Time:${time.toLocaleString()}`
        : "Time:Invalid Date"
    }`;
  };

  const handleImageClick = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <VStack theme={customTheme} mb="50px" spacing={4} align="center" w="100%">
        <Flex w="100%">
          <Button
            mb="10px"
            bg="green.500"
            color="white"
            leftIcon={<DownloadIcon />}
            onClick={openPasswordModal}
            isLoading={isDownloading}
            loadingText="Downloading..."
          >
            PDF
          </Button>
        </Flex>

        <Box>
          <Box>
            <Flex
              justifyContent="space-between"
              flexDirection={{ base: "column", md: "row" }}
              w="100%"
              fontFamily="body"
            >
              <Flex mb={{ base: 1, md: 0 }}>
                <Text fontSize={{ base: "xl", md: "2xl" }} fontWeight="bold">
                  {selectedReport.recipientFullName}'s Care Report
                </Text>
              </Flex>
            </Flex>
            <Flex fontFamily="body">
              <Icon color="purple" as={TimeIcon} mr="8px" />
              <Text fontWeight="bold">Report date and time:</Text>
              <Text ml="10px">{formatDateTime(selectedReport.createdAt)}</Text>
            </Flex>
            <Flex>
              <FaUserMd color="purple" style={{ marginRight: "8px" }} />
              <Text fontWeight="bold">Submitted by:</Text>
              <Text ml="10px">{selectedReport.medicFullName}</Text>
            </Flex>
          </Box>

          <Box
            p={4}
            bg="#FADCFF"
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
            fontFamily="body"
            borderTopRadius="md"
          >
            <Flex p={4} bg="white" mb={{ base: 2, md: 2 }}>
              <FaFileMedical color="blue" style={{ marginRight: "8px" }} />
              <Text fontWeight="bold">Service Plan:</Text>
              <Text ml="10px">{selectedReport.servicePlan}</Text>
            </Flex>
            <Box p={4} bg="white" mb={{ base: 2, md: 2 }}>
              <Flex>
                <FaHeartbeat color="red" style={{ marginRight: "8px" }} />
                <Text fontWeight="bold">Vital Signs:</Text>
              </Flex>
              <Text>Temperature: {selectedReport.temperature}°C</Text>
              <Text>Blood Pressure: {selectedReport.bloodPressure}</Text>
              <Text>Pulse: {selectedReport.pulse} bpm</Text>
              <Text>Blood Sugar: {selectedReport.bloodSugar}</Text>
              <Text>SpO2: {selectedReport.sp02}%</Text>
              <Text>Respiration: {selectedReport.respiration} c/m</Text>
            </Box>
            <Flex p={4} bg="white" mb={{ base: 2, md: 2 }}>
              <FaSmile color="orange" style={{ marginRight: "8px" }} />
              <Text fontWeight="bold">Emotional State: </Text>
              <Text ml="5px">{selectedReport.emotionalState}</Text>
            </Flex>
            <Flex p={4} bg="white" mb={{ base: 2, md: 2 }}>
              <FaRunning color="green" style={{ marginRight: "8px" }} />
              <Text fontWeight="bold">Physical State: </Text>
              <Text ml="5px">{selectedReport.physicalState}</Text>
            </Flex>
            <Flex p={4} bg="white" mb={{ base: 2, md: 2 }}>
              <FaThermometerHalf color="gray" style={{ marginRight: "8px" }} />
              <Text fontWeight="bold">Pain Level: </Text>
              <Text ml="5px">{selectedReport.painLevel}</Text>
            </Flex>
            <Flex p={4} bg="white" mb={{ base: 2, md: 2 }}>
              <FaMapMarkerAlt color="gray" style={{ marginRight: "8px" }} />
              <Text fontWeight="bold">Pain Location: </Text>
              <Text ml="5px">{selectedReport.painLocation}</Text>
            </Flex>
            <Flex p={4} bg="white" mb={{ base: 2, md: 2 }}>
              <FaBandAid color="green" style={{ marginRight: "8px" }} />
              <Text fontWeight="bold">Skin Integrity: </Text>
              <Text ml="5px">{selectedReport.skinIntegrity}</Text>
            </Flex>
            <Flex p={4} bg="white" mb={{ base: 2, md: 2 }}>
              <FaUtensils color="orange" style={{ marginRight: "8px" }} />
              <Text fontWeight="bold">Appetite: </Text>
              <Text ml="5px">{selectedReport.appetite}</Text>
            </Flex>
            <Flex p={4} bg="white" mb={{ base: 2, md: 2 }}>
              <FaGlassWhiskey color="gray" style={{ marginRight: "8px" }} />
              <Text fontWeight="bold">Fluid Intake: </Text>
              <Text ml="5px">{selectedReport.fluidIntake}</Text>
            </Flex>
            <Flex p={4} bg="white" mb={{ base: 2, md: 2 }}>
              <FaToilet color="gray" style={{ marginRight: "8px" }} />
              <Text fontWeight="bold">Urinary Elimination: </Text>
              <Text ml="5px">{selectedReport.urinaryElimination}</Text>
            </Flex>
            <Flex p={4} bg="white" mb={{ base: 2, md: 2 }}>
              <FaToiletPaper color="green" style={{ marginRight: "8px" }} />
              <Text fontWeight="bold">Bowel Elimination: </Text>
              <Text ml="5px">{selectedReport.bowelElimination}</Text>
            </Flex>
            <Flex p={4} bg="white" mb={{ base: 2, md: 2 }}>
              <FaBed color="purple" style={{ marginRight: "8px" }} />
              <Text fontWeight="bold">Sleep Quality: </Text>
              <Text ml="5px">{selectedReport.sleepQuality}</Text>
            </Flex>
            <Box p={4} bg="white" mb={{ base: 2, md: 2 }}>
              <Flex>
                <FaPills color="blue" style={{ marginRight: "8px" }} />
                <Text fontWeight="bold">Medications:</Text>
              </Flex>

              <VStack align="start" spacing={1}>
                {selectedReport.medications.map((medication, index) => (
                  <Text key={index}>{formatMedicationTime(medication)}</Text>
                ))}
              </VStack>
            </Box>
            <Box p={4} bg="white" mb={{ base: 2, md: 2 }}>
              <Flex>
                <FaProcedures color="purple" style={{ marginRight: "8px" }} />
                <Text fontWeight="bold" maxWidth="100%">
                  Care Provided:
                </Text>
              </Flex>
              <VStack align="start" spacing={1}>
                {selectedReport.activities.map((activity, index) => (
                  <Text key={index}>{activity}</Text>
                ))}
              </VStack>
            </Box>

            <Box mb={{ base: 2, md: 2 }} p={4} bg="white">
              <Flex>
                <FaRegCommentDots
                  color="purple"
                  style={{ marginRight: "8px" }}
                />
                <Text fontWeight="bold" maxWidth="100%">
                  Comments/Observation:
                </Text>
              </Flex>
              <Text maxWidth="100%">{selectedReport.comments}</Text>
            </Box>
            <Box mb={{ base: 2, md: 2 }} p={4} bg="white">
              <Flex>
                <FaPen color="purple" style={{ marginRight: "8px" }} />
                <Text fontWeight="bold" maxWidth="100%">
                  Recommendations/Requests:
                </Text>
              </Flex>
              <Text maxWidth="100%">{selectedReport.recommendations}</Text>
            </Box>
            {selectedReport.image && (
              <Tooltip label="Enlarge" aria-label="A tooltip">
                <Image
                  src={selectedReport.image}
                  alt="Image"
                  boxSize="150px"
                  objectFit="cover"
                  borderRadius="md"
                  cursor="pointer"
                  onClick={handleImageClick}
                />
              </Tooltip>
            )}
            <Button
              mt="10px"
              color="white"
              bg="linear-gradient(80deg, #A210C6, #E552FF)"
              onClick={handleViewRecommendations}
              w="100%"
            >
              View Recommended Interventions
            </Button>
          </Box>
        </Box>
      </VStack>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Image
              src={selectedReport.image}
              alt="Enlarged Image"
              objectFit="contain"
              maxH="80vh"
              maxW="100%"
            />
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  );
};

export default ReportDetails;
