import React from "react";
import {
  Box,
  Flex,
  FormLabel,
  Input,
  Select,
  Textarea,
  Text,
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const stateTownsMap = {
  Lagos: [
    "Ikeja",
    "Ogudu",
    "Berger",
    "Surulere",
    "Ikorodu",
    "Epe",
    "Badagry",
    "Yaba",
    "Victoria Island",
    "Lekki",
    "Lagos Island",
    "Ajah",
    "Sangotedo",
    "Agege",
    "Ikoyi",
    "Okota",
    "Mushin",
    "Iyana Ipaja",
    "Oshodi",
    "Isolo",
    "Ikotun",
    "Festac",
    "Ijesha",
    "Maryland",
    "Ojota",
  ],
  Abuja: [
    "Asokoro",
    "Maitama",
    "Gwarinpa",
    "Wuse",
    "Garki",
    "Jabi",
    "Central Business District (CBD)",
    "Lugbe",
    "Karu",
    "Kubwa",
    "Nyanya",
    "Lokogoma",
    "Apo",
    "Utako",
    "Gudu",
    "Kuje",
    "Dutse",
    "Bwari",
    "Mpape",
    "Galadimawa",
    "Life Camp",
    "Kaura",
    "Karmo",
    "Gwagwalada",
  ],
  Nasarawa: ["Lafia", "Keffi", "Akwanga", "Doma", "Nasarawa", "Karu"],
  Ibadan: [
    "Bodija",
    "Dugbe",
    "Iwo Road",
    "Challenge",
    "Ring Road",
    "Mokola",
    "Apata",
  ],
  Porthacourt: ["Diobu", "Trans Amadi", "Eleme", "GRA", "Rumuokoro", "Rivers"],
  Enugu: ["Enugu", "Nsukka", "Udi", "Oji River", "Ezeagu"],
  Ogun: [
    "Abeokuta ",
    "Ijebu Ode",
    "Ota (Otta)",
    "Sagamu ",
    "Ilaro ",
    "Ijebu Igbo",
    "Ayetoro ",
    "Sango ",
    "Ifo ",
    "Owode ",
    "Agbara ",
    "Ikenne ",
    "Ijoko ",
    "Odogbolu ",
    "Remo ",
    "Ado-Odo",
    "Ewekoro ",
    "Igbesa ",
    "Isara-Remo",
    "Imeko ",
    "Ijebu Remo",
  ],
  Calabar: ["Big Qua", "Marian", "Akpabuyo", "Odukpani", "Ikom"],
  Delta: ["Warri", "Asaba", "Ughelli", "Sapele", "Kwale"],
};

const formatAmount = (amount) => {
  const num = Number(amount);
  return num.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

const majorReligions = ["Christianity", "Islam", "Traditional", "Anyone"];

export const FormFields = ({
  formFields,
  majorLanguages,
  handleInputChange,
  handleStartDateChange,
  handleStateChange,
  selectedState,
  availableTowns = [],
  selectedStartDate,
  customizedPlans,
  isShiftDisabled,
}) => (
  <>
    <Box alignItems="center" p="15px" boxShadow="0 0 10px rgba(0, 0, 0, 0.1)">
      <Flex flexWrap="wrap" marginTop="20px">
        <Box borderRadius="5px" w={{ base: "full", md: "270px" }}>
          <FormLabel fontFamily="body" fontWeight="bold" color="#00000080">
            Start Date
          </FormLabel>
          <Flex
            color="#00000080"
            h="6.5vh"
            paddingTop="10px"
            paddingLeft="15px"
            bg="#f9f9f9"
            borderRadius="5px"
          >
            <DatePicker
              isRequired
              selected={selectedStartDate}
              onChange={handleStartDateChange}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              dateFormat="dd-MM-yyyy"
              placeholderText="preferred date to start"
              className="form-control"
              minDate={new Date()}
              style={{ backgroundColor: "#f7fafc !important" }}
            />
          </Flex>
        </Box>

        <Box
          ml={{ md: "5px" }}
          color="#00000080"
          w={{ base: "full", md: "270px" }}
        >
          <FormLabel color="#00000080" fontFamily="body" fontWeight="bold">
            Service Plan
          </FormLabel>
          <Select
            isRequired
            h="6.5vh"
            name="servicePlan"
            placeholder="preferred service plan"
            w={{ base: "full", md: "270px" }}
            fontSize={{ base: "14px", md: "16px" }}
            value={formFields.servicePlan}
            onChange={handleInputChange}
            bg="#f9f9f9"
            borderRadius="5px"
          >
            <optgroup label="Standard Plans">
              <option value="Elderly care by a Licensed Nurse">
                Elderly care by a Licensed Nurse
              </option>
              <option value="Elderly care by a Nurse Assistant">
                Elderly care by a Nurse Assistant
              </option>
              <option value="Postpartum care">
                Postpartum care by a Licensed Nurse/Midwife
              </option>
              <option value="Nanny care">
                Nanny service by a Professional Nanny
              </option>
              <option value="Recovery care">
                Recovery care by a Licensed Nurse
              </option>
              <option value="Short home visit">
                Short home visit by a Licensed Nurse
              </option>
            </optgroup>
            <optgroup label="Custom Plans">
              {customizedPlans.map((plan) => (
                <option key={plan.id} value={plan.name}>
                  {plan.name}
                </option>
              ))}
            </optgroup>
          </Select>
        </Box>
      </Flex>

      <Flex marginTop="20px" color="#00000080" flexWrap="wrap">
        <Box
          w={{ base: "full", md: "270px" }}
          color="#00000080"
          fontFamily="body"
          ml={{ md: "5px" }}
        >
          <FormLabel fontWeight="bold">Shift</FormLabel>
          <Select
            isRequired
            h="6.5vh"
            name="shift"
            placeholder="select preferred shift"
            value={formFields.shift}
            onChange={handleInputChange}
            disabled={isShiftDisabled}
            bg="#f9f9f9"
            borderRadius="5px"
          >
            <option value="Day Shift (8hrs)">Day Shift (8hrs)</option>
            <option value="Live-in (24hrs)">Live-in (24hrs)</option>
          </Select>

          {formFields.costOfService && (
            <Text fontWeight="bold" color="green.500" mt={2}>
              Total Cost of Service: ₦{formatAmount(formFields.costOfService)}
            </Text>
          )}
          {formFields.duration && (
            <Text fontWeight="bold" color="green.500" mt={2}>
              Duration of Service: {formFields.duration} day(s)
            </Text>
          )}
        </Box>

        <Box ml={{ md: "5px" }} w={{ base: "full", md: "270px" }}>
          <FormLabel fontFamily="body" fontWeight="bold" color="#00000080">
            End Date
          </FormLabel>
          <Flex
            color="#00000080"
            h="6.5vh"
            paddingTop="5px"
            paddingLeft="15px"
            bg="#f9f9f9"
            borderRadius="5px"
          >
            <DatePicker
              selected={formFields.endDate}
              placeholderText="estimated end date"
              dateFormat="dd-MM-yyyy"
              className="form-control"
              disabled
              style={{ color: "#00000080" }}
            />
          </Flex>
        </Box>
      </Flex>

      <Flex marginTop="20px" color="#00000080" flexWrap="wrap">
      <Box w={{ base: "full", md: "270px" }} marginTop="20px">
        <FormLabel fontFamily="body" fontWeight="bold" color="#00000080">
          Select State
        </FormLabel>
        <Select
          name="state"
          placeholder="Select State"
          value={selectedState}
          onChange={handleStateChange}
          bg="#f9f9f9"
          borderRadius="5px"
        >
          {Object.keys(stateTownsMap).map((state) => (
            <option key={state} value={state}>
              {state}
            </option>
          ))}
        </Select>
      </Box>

      <Box ml={{ md: "5px" }} w={{ base: "full", md: "270px" }} marginTop="20px">
        <FormLabel color="#00000080" fontFamily="body" fontWeight="bold">
          City/Town
        </FormLabel>
        <Select
          bg="#f9f9f9"
          borderRadius="5px"
          isRequired
          name="recipientTown"
          placeholder="select town"
          fontSize={{ base: "14px", md: "16px" }}
          value={formFields.recipientTown}
          onChange={handleInputChange}
          isDisabled={availableTowns.length === 0}
        >
          {availableTowns.map((town) => (
            <option key={town} value={town}>
              {town}
            </option>
          ))}
        </Select>
      </Box>
      </Flex>
      <Box w={{ base: "full", md: "550px" }} marginTop="20px">
        <FormLabel color="#00000080" fontWeight="bold" fontFamily="body">
          Location
        </FormLabel>
        <Input
          bg="#f9f9f9"
          borderRadius="5px"
          name="currentLocation"
          type="text"
          placeholder="enter home address"
          value={formFields.currentLocation}
          onChange={handleInputChange}
          w={{ base: "full", md: "550px" }}
        />
      </Box>

      <Flex color="#00000080" flexWrap="wrap">
        <Box w={{ base: "full", md: "270px" }} marginTop="20px">
          <FormLabel color="#00000080" fontFamily="body" fontWeight="bold">
            Preferred Tribe/language
          </FormLabel>
          <Select
            isRequired
            bg="#f9f9f9"
            borderRadius="5px"
            name="preferredLanguage"
            placeholder="select language"
            w={{ base: "full", md: "270px" }}
            fontSize={{ base: "14px", md: "16px" }}
            value={formFields.preferredLanguage}
            onChange={handleInputChange}
          >
            {majorLanguages.map((language) => (
              <option key={language} value={language}>
                {language}
              </option>
            ))}
          </Select>
        </Box>
        <Box
          w={{ base: "full", md: "270px" }}
          ml={{ md: "5px" }}
          marginTop="20px"
        >
          <FormLabel color="#00000080" fontWeight="bold" fontFamily="body">
            Preferred Medic Gender
          </FormLabel>
          <Select
            bg="#f9f9f9"
            borderRadius="5px"
            isRequired
            name="preferredMedicGender"
            placeholder="select gender"
            w={{ base: "full", md: "270px" }}
            fontSize={{ base: "14px", md: "16px" }}
            value={formFields.preferredMedicGender}
            onChange={handleInputChange}
          >
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Anyone">Any Gender</option>
          </Select>
        </Box>
      </Flex>

      <Box spacing={4} marginTop="20px" flex="1">
        <FormLabel fontFamily="body" fontWeight="bold" color="#00000080">
          Preferred Religion
        </FormLabel>
        <Select
          isRequired
          bg="#f9f9f9"
          borderRadius="5px"
          w="full"
          fontSize={{ base: "14px", md: "16px" }}
          name="religion"
          placeholder="Select Religion"
          value={formFields.religion}
          onChange={handleInputChange}
        >
          {majorReligions.map((religion) => (
            <option key={religion} value={religion}>
              {religion}
            </option>
          ))}
        </Select>
      </Box>

      <Box color="#00000080" marginTop="20px">
        <FormLabel color="#00000080" fontWeight="bold" fontFamily="body">
          Health History
        </FormLabel>
        <FormLabel color="#00000080" fontSize="14px" fontFamily="body">
          (Is there anything you'd like us to know?)
        </FormLabel>
        <Textarea
          bg="#f9f9f9"
          borderRadius="5px"
          name="recipientHealthHistory"
          type="text"
          placeholder="share health history"
          value={formFields.recipientHealthHistory}
          onChange={handleInputChange}
          w={{ base: "full", md: "550px" }}
        />
      </Box>
    </Box>
  </>
);
