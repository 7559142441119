import React, { useState, useEffect } from "react";
import { Box, Flex, Text, HStack } from "@chakra-ui/react";
import {
  FaUsers,
  FaUserTie,
  FaHeartbeat,
  FaBuilding,
} from "react-icons/fa"; // Importing from 'react-icons/fa'
import axios from "axios";
import { useSpring, animated } from "@react-spring/web";
import { baseUrl } from "../../../apiCalls/config";

// Function to format numbers with commas
const formatNumber = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const CustomerStatisticsBox = () => {
  const [statistics] = useState([
    {
      id: 1,
      title: "Users",
      count: 0,
      percentage: "0%",
      bgColor: "#00C6F7",
      lastMonth: "Last Month",
      icon: FaUsers, // Icon for Users
    },
    {
      id: 2,
      title: "Customers",
      count: 0,
      percentage: "0%",
      bgColor: "yellow.400",
      lastMonth: "Last Month",
      icon: FaUserTie, // Icon for Customers
    },
    {
      id: 3,
      title: "Patients",
      count: 0,
      percentage: "0%",
      bgColor: "green.400",
      lastMonth: "Last Month",
      icon: FaHeartbeat, // Icon for Patients
    },
    {
      id: 4,
      title: "Corporates",
      count: 0,
      percentage: "0%",
      bgColor: "linear-gradient(80deg, #A210C6, #E552FF)",
      lastMonth: "Last Month",
      icon: FaBuilding, // Icon for Corporates
    },
  ]);

  const [fetchedData, setFetchedData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found, redirecting to login.");
        window.location.href = "/login";
        return;
      }

      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(`${baseUrl}/api/statistics`, config);
        const data = response.data[0];

        setFetchedData({
          totalUsers: data.totalUsers,
          totalCustomers: data.totalCustomers,
          totalCareBeneficiaries: data.totalCareBeneficiaries,
          totalCorporates: data?.totalCorporates,
        });
      } catch (error) {
        console.error("Error fetching statistics:", error);
      }
    };

    fetchData();
  }, []);

  const totalUsersSpring = useSpring({
    from: { value: 0 },
    value: fetchedData ? fetchedData.totalUsers : 0,
  });
  const totalCustomersSpring = useSpring({
    from: { value: 0 },
    value: fetchedData ? fetchedData.totalCustomers : 0,
  });
  const totalPatientsSpring = useSpring({
    from: { value: 0 },
    value: fetchedData ? fetchedData.totalCareBeneficiaries : 0,
  });
  const totalCorporatesSpring = useSpring({
    from: { value: 0 },
    value: fetchedData ? fetchedData?.totalCorporates : 0,
  });

  return (
    <Flex justifyContent="space-between" w="100%" p={4} gap={4}>
      {statistics.map((stat, index) => {
        const springValue =
          index === 0
            ? totalUsersSpring.value
            : index === 1
            ? totalCustomersSpring.value
            : index === 2
            ? totalPatientsSpring.value
            : totalCorporatesSpring.value;

        return (
          <Box
            key={stat.id}
            bg={stat.bgColor}
            borderRadius="20px"
            p={4}
            flex="1"
            color="white"
          >
            <Flex justifyContent="space-between" alignItems="center">
              <Text fontSize="lg" fontWeight="bold">
                {stat.title}
              </Text>
              <stat.icon size={24} />
            </Flex>
            <Text fontSize="2xl" fontWeight="bold" mt={2}>
              <animated.span>
                {springValue.to((val) => formatNumber(Math.floor(val)))}
              </animated.span>
            </Text>
            <HStack justifyContent="space-between" mt={2}>
              <Text bg="rgba(255, 255, 255, 0.3)" borderRadius="10px" p={1}>
                {stat.percentage}
              </Text>
              <Text>{stat.lastMonth}</Text>
            </HStack>
          </Box>
        );
      })}
    </Flex>
  );
};

export default CustomerStatisticsBox;
