import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

// Function to generate a random signature
const generateSignature = () => {
  const signatures = [
    "Nurse Anita Anieze", 
    "Michael Joshua", 
  ];
  const randomIndex = Math.floor(Math.random() * signatures.length);
  return signatures[randomIndex];
};

export const generatePDF = async (report, setIsDownloading, toast) => {
  setIsDownloading(true);
  try {
    const doc = new jsPDF();
    
    // Add the logo to the PDF
    const imgData = 'https://res.cloudinary.com/dmfewrwla/image/upload/v1720388853/MH_logo.png';
    doc.addImage(imgData, 'PNG', 10, 10, 30, 10);

    // Set the title with color
    doc.setTextColor("#A210C6");
    doc.setFontSize(20);
    doc.setFont("helvetica", "bold");
    doc.text(`${report.recipientFullName} MH Report`, 70, 30);

    // Prepare the report details in a table format
    const reportData = [
      ["Beneficiary Name", report.recipientFullName],
      ["Service Plan", report.servicePlan],
      ["Report Date and Time", formatDateTime(report.createdAt)],
      ["Temperature", `${report.temperature}°C`],
      ["Blood Pressure", report.bloodPressure],
      ["Pulse", `${report.pulse} bpm`],
      ["Blood Sugar", report.bloodSugar],
      ["SpO2", `${report.sp02}%`],
      ["Respiration", `${report.respiration} c/m`],
      ["Mood", report.mood],
      ["Emotional State", report.emotionalState],
      ["Physical State", report.physicalState],
      ["Pain Level", report.painLevel],
      ["Medications", report.medications.map(formatMedicationTime).join("\n")],
      ["Care Provided", report.activities.join(", ")],
      ["Comments", report.comments],
      ["Recommendations", report.recommendations],
      ["Reported by", report.medicFullName],
    ];

    // Add table with the report details
    doc.setTextColor("#000000");
    autoTable(doc, {
      startY: 40,
      head: [["Label", "Value"]],
      body: reportData.map(([label, value]) => [label, value || "N/A"]),
      theme: 'grid',
      styles: { fontSize: 12, cellPadding: 3 },
    });

    // Add image if it exists
    if (report.picturePath) {
      doc.addPage();
      doc.addImage(report.picturePath, "JPEG", 10, 40, 180, 160);
    }

    // Add the signature at the bottom left
    const signature = generateSignature();
    doc.setFontSize(14);
    doc.setFont("helvetica", "italic");
    doc.text(`Signature: ${signature}`, 10, doc.internal.pageSize.height - 20);
    doc.text("Mikul Health Admin", 10, doc.internal.pageSize.height - 10);

    // Save the PDF
    const fileName = `${report.recipientFullName}_${formatDateTime(report.createdAt)}_MH_Medical_Report.pdf`;
    doc.save(fileName);
    
    // Show success toast
    toast({
      title: "Download Complete",
      description: "Report downloaded successfully.",
      status: "success",
      duration: 5000,
      isClosable: true,
      position: "top-left",
    });
  } catch (error) {
    // Show error toast
    toast({
      title: "Error",
      description: "An error occurred while generating the PDF.",
      status: "error",
      duration: 9000,
      isClosable: true,
      position: "top-right",
    });
  } finally {
    setIsDownloading(false);
  }
};

const formatMedicationTime = (medication) => {
  const parts = medication.split(",");
  const timePart = parts.find((part) => part.startsWith("Time:"));
  const time = timePart
    ? new Date(timePart.replace("Time:", ""))
    : new Date(NaN);
  return `${parts[0]}, ${parts[1]}, ${parts[2]}, ${
    !isNaN(time.getTime())
      ? `Time:${time.toLocaleString()}`
      : "Time:Invalid Date"
  }`;
};

const formatDateTime = (dateString) => {
  const date = new Date(dateString);
  return `${date.toLocaleDateString()}, ${date.toLocaleTimeString()}`;
};
