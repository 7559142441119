import React, { useState } from "react";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Avatar,
  Text,
  VStack,
  Divider,
  Flex,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import axios from "axios";
import SuspendCustomerModal from "./SuspendCustomerModal";
import { baseUrl } from "../../../apiCalls/config";
import EditMedicModal from "./EditMedicDetails";

const MedicDetailsDrawer = ({ isOpen, onClose, medic, onUpdateMedic }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageTitle, setSelectedImageTitle] = useState("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isSuspendModalOpen, setIsSuspendModalOpen] = useState(false);
  const [isSuspended, setIsSuspended] = useState(medic.bioData.suspended);
  const [isLoading, setIsLoading] = useState(false);
  const [deletingUserId, setDeletingUserId] = useState(null);
  const toast = useToast();

  const {
    isOpen: isDocumentDrawerOpen,
    onOpen: openDocumentDrawer,
    onClose: closeDocumentDrawer,
  } = useDisclosure();

  const {
    isOpen: isModalOpen,
    onOpen: openModal,
    onClose: closeModal,
  } = useDisclosure();

  const {
    isOpen: isVerifyModalOpen,
    onOpen: openVerifyModal,
    onClose: closeVerifyModal,
  } = useDisclosure();

  const {
    isOpen: isDeleteModalOpen,
    onOpen: openDeleteModal,
    onClose: closeDeleteModal,
  } = useDisclosure();

  const handleImageClick = (image, title) => {
    setSelectedImage(image);
    setSelectedImageTitle(title);
    openModal();
  };

  const handleAvatarClick = () => {
    handleImageClick(medic.bioData.image, "Medic Avatar");
  };

  const handleOpenEditModal = () => {
    setIsEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
  };

  const handleOpenSuspendModal = () => {
    setIsSuspendModalOpen(true);
  };

  const handleCloseSuspendModal = () => {
    setIsSuspendModalOpen(false);
  };

  const handleDeleteMedic = () => {
    openDeleteModal();
  };

  const handleSuspensionChange = (newSuspendedState) => {
    setIsSuspended(newSuspendedState);
    onUpdateMedic({
      ...medic,
      bioData: {
        ...medic.bioData,
        suspended: newSuspendedState,
      },
    });
    toast({
      title: `Medic ${newSuspendedState ? "Suspended" : "Unsuspended"}`,
      description: `The medic has been ${
        newSuspendedState ? "suspended" : "unsuspended"
      } successfully.`,
      status: "success",
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  const handleVerifyMedic = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, redirecting to login.");
      window.location.href = "/login";
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.post(
        `${baseUrl}/api/admin/verify-medic/${medic.id}`,
        {},
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        toast({
          title: "Medic Verified",
          description: "The medic has been verified successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        closeVerifyModal();
      } else {
        toast({
          title: "Verification Failed",
          description: "The medic could not be verified. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (error) {
      toast({
        title: "Verification Failed",
        description: "There was an error verifying the medic.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const confirmDeleteMedic = async () => {
    setDeletingUserId(medic.id);
    const token = localStorage.getItem("token");

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await axios.post(
        `${baseUrl}/api/admin/delete-medic/${medic.id}`,
        {},
        config
      );

      toast({
        title: "Medic deleted",
        description: "The medic was successfully deleted.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right"
      });

      onClose();
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to delete the medic.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.error("Error deleting medic:", error);
    } finally {
      setDeletingUserId(null);
      closeDeleteModal();
    }
  };

  return (
    <>
      <Drawer
        style={{ zIndex: 1400 }}
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        size="md"
        blockScrollOnMount={false}
      >
        <DrawerOverlay />
        <DrawerContent bg="#4B4B4B" color="white">
          <DrawerCloseButton />
          <DrawerHeader>Medic Details</DrawerHeader>
          <DrawerBody>
            <VStack align="left" spacing={4}>
              <Box>
                <Flex justifyContent="center">
                  <Avatar
                    size="xl"
                    src={medic.bioData.image}
                    alt={`${medic.bioData.firstName} ${medic.bioData.lastName}`}
                    cursor="pointer"
                    onClick={handleAvatarClick}
                  />
                </Flex>
                <Flex alignItems="center" justifyContent="space-between" mt={2}>
                  <VStack>
                    <Button colorScheme="blue" onClick={openDocumentDrawer}>
                      View Credentials
                    </Button>
                  </VStack>
                  {!medic.medicVerified && (
                    <Button colorScheme="green" onClick={openVerifyModal}>
                      Verify Medic
                    </Button>
                  )}
                </Flex>
                {!medic.medicVerified && (
                  <Button
                    mt="10px"
                    w="full"
                    colorScheme="red"
                    onClick={handleDeleteMedic}
                  >Delete Medic</Button>
                )}
              </Box>
              <Divider />
              <Text fontSize="2xl" fontWeight="bold">
                {medic.bioData.firstName} {medic.bioData.lastName}
              </Text>
              <Text>Phone Number: {medic.phoneNumber}</Text>
              <Text>ID: {medic.id}</Text>
              <Text>Email: {medic.bioData.email}</Text>
              <Text>Gender: {medic.bioData.gender}</Text>
              <Text>
                Date of Birth:{" "}
                {new Date(medic.bioData.dob).toLocaleDateString()}
              </Text>
              <Text>Native Language: {medic.preferredLanguage}</Text>
              <Text>Religion: {medic.religion}</Text>
              <Text>Home Address: {medic.medicHomeAddress}</Text>
              <Divider />
              <Text>Marital Status: {medic.maritalStatus || "N/A"}</Text>
              <Text>Spouse FullName: {medic.spouseFullName || "N/A"}</Text>
              <Text>
                Spouse PhoneNumber: {medic.spousePhoneNumber || "N/A"}
              </Text>
              <Text>Spouse Email: {medic.spouseEmail || "N/A"}</Text>
              <Text>Spouse Address: {medic.spouseAddress || "N/A"}</Text>
              <Text>
                Spouse HomeAddress: {medic.spouseHomeAddress || "N/A"}
              </Text>
              <Divider />
              <Text>Medic Type: {medic.medicType}</Text>
              <Text>Years of Experience: {medic.yearsOfExp}</Text>
              <Text>Has Wallet: {medic.walletCreated ? "Yes" : "No"}</Text>
              <Text>Number of Patients: {medic.noOfPatients}</Text>
              <Text>
                Matched to Appointment:{" "}
                {medic.matchedToAppointment ? "Yes" : "No"}
              </Text>
              <Text>Total Made: ₦{medic.totalMade.toLocaleString()}</Text>
              <Text>
                Total Withdrawn: ₦{medic.totalWithdraw.toLocaleString()}
              </Text>
              <Text>
                Last Payment Date:{" "}
                {medic.lastPayDateTime
                  ? new Date(medic.lastPayDateTime).toLocaleDateString()
                  : "N/A"}
              </Text>
              <Text>Medic Verified: {medic.medicVerified ? "Yes" : "No"}</Text>
              <Text>Need Payment: {medic.needPayment ? "Yes" : "No"}</Text>
              <Text>Start date: {medic?.appointmentStartDate}</Text>
              <Text>End date: {medic?.appointmentEndDate}</Text>
              <Text>
                Verified At:{" "}
                {medic.verifiedAt
                  ? new Date(medic.verifiedAt).toLocaleDateString()
                  : "N/A"}
              </Text>

              <Divider />
              <Text fontSize="lg" fontWeight="bold">
                Guarantor Details
              </Text>
              <Text>
                Guarantor Name: {medic.guarantorFirstName}{" "}
                {medic.guarantorLastName}
              </Text>
              <Text>Guarantor Phone: {medic.guarantorPhone}</Text>
              <Text>Guarantor Email: {medic.guarantorEmail}</Text>
              <Text>Guarantor Home Address: {medic.guarantorHomeAddress}</Text>
              <Text>
                Relationship with Guarantor:{" "}
                {medic.relationshipWithGuarantor || "N/A"}
              </Text>
              <Divider />
              <Text fontSize="lg" fontWeight="bold">
                Last Employer Details
              </Text>
              <Text>Name: {medic.lastEmployerFullName || "N/A"}</Text>
              <Text>Phone: {medic.lastEmployerPhoneNumber || "N/A"}</Text>
              <Text>Email: {medic.lastEmployerEmail || "N/A"}</Text>
              <Text>Home Address: {medic.lastEmployerAddress || "N/A"}</Text>
              <Divider />
              <Text fontSize="lg" fontWeight="bold">
                Bank Details
              </Text>
              {medic.bankDetails?.map((bank, index) => (
                <Box key={index}>
                  <Text>Bank Name: {bank.bankName}</Text>
                  <Text>Account Number: {bank.accountNumber}</Text>
                  <Text>Account Name: {bank.accountName}</Text>
                </Box>
              ))}
            </VStack>
          </DrawerBody>
          <DrawerFooter justifyContent="space-between">
            <Button
              variant="outline"
              colorScheme="blue"
              onClick={handleOpenEditModal}
            >
              Edit Medic Details
            </Button>
            <Button
              variant="outline"
              colorScheme={isSuspended ? "green" : "red"}
              onClick={handleOpenSuspendModal}
            >
              {isSuspended ? "Activate Medic" : "Suspend Medic"}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <EditMedicModal
        isOpen={isEditModalOpen}
        onClose={handleCloseEditModal}
        customer={medic}
      />
      <SuspendCustomerModal
        isOpen={isSuspendModalOpen}
        onClose={handleCloseSuspendModal}
        customerId={medic.id}
        isSuspended={isSuspended}
        onSuspensionChange={handleSuspensionChange}
      />

      {/* Delete Confirmation Modal */}
      <Modal isOpen={isDeleteModalOpen} onClose={closeDeleteModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Deletion</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to delete this medic?</Text>
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={deletingUserId === medic.id}
              loadingText="Deleting..."
              spinnerPlacement="start"
              bg="red.400"
              color="white"
              onClick={confirmDeleteMedic}
            >
              Confirm Delete
            </Button>
            <Button variant="ghost" onClick={closeDeleteModal} ml={3}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Drawer
        isOpen={isDocumentDrawerOpen}
        placement="right"
        onClose={closeDocumentDrawer}
        size="md"
        style={{ zIndex: 1400 }}
      >
        <DrawerOverlay />
        <DrawerContent bg="#4B4B4B" color="white">
          <DrawerCloseButton />
          <DrawerHeader>Medic's Credentials</DrawerHeader>
          <DrawerBody>
            <VStack spacing={4} align="center" justify="center" height="100%">
              {/* License Section */}
              <Box>
                <Text fontSize="lg" mb={2}>
                  License
                </Text>
                {medic.license && medic.license.endsWith(".pdf") ? (
                  <iframe
                    src={medic.license}
                    style={{ width: "100%", height: "400px" }}
                    title="License PDF"
                  />
                ) : (
                  medic.license && (
                    <Image
                      src={medic.license}
                      alt="License"
                      height="200px"
                      width="auto"
                      objectFit="cover"
                      onClick={() => handleImageClick(medic.license, "License")}
                      cursor="pointer"
                    />
                  )
                )}
              </Box>

              {/* CV Section */}
              <Box mt={6}>
                <Text fontSize="lg" mb={2}>
                  CV
                </Text>
                {medic.cvCopy && medic.cvCopy.endsWith(".pdf") ? (
                  <iframe
                    src={medic.cvCopy}
                    style={{ width: "100%", height: "400px" }}
                    title="CV PDF"
                  />
                ) : (
                  medic.cvCopy && (
                    <Image
                      src={medic.cvCopy}
                      alt="CV"
                      height="200px"
                      width="auto"
                      objectFit="cover"
                      onClick={() => handleImageClick(medic.cvCopy, "CV")}
                      cursor="pointer"
                    />
                  )
                )}
              </Box>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {/* Confirmation Modal for Verification */}
      <Modal
        style={{ zIndex: 1400 }}
        isOpen={isVerifyModalOpen}
        onClose={closeVerifyModal}
      >
        <ModalOverlay />
        <ModalContent bg="#4B4B4B" color="white">
          <ModalHeader>Confirm Verification</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Ensure you have reviewed all the medic's documents and
              credentials? If yes, please confirm the verification of the medic.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              variant="outline"
              mr={3}
              onClick={closeVerifyModal}
            >
              Cancel
            </Button>

            <Button
              onClick={handleVerifyMedic}
              isLoading={isLoading}
              loadingText="Verifying..."
              colorScheme="green"
            >
              {isLoading ? <Spinner size="sm" /> : "Confirm Verification"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        style={{ zIndex: 1400 }}
        isOpen={isModalOpen}
        onClose={closeModal}
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent
          overflow="scroll"
          mt="-2px"
          mb="20px"
          bg="white"
          color="black.500"
        >
          <ModalCloseButton />
          <ModalHeader>{selectedImageTitle}</ModalHeader>
          <ModalBody>
            {selectedImage && selectedImage.endsWith(".pdf") ? (
              <iframe
                src={selectedImage}
                style={{ width: "100%", height: "600px" }}
                title="Selected Document PDF"
              />
            ) : (
              selectedImage && (
                <Image
                  src={selectedImage}
                  alt="Selected Document"
                  width="90%"
                  objectFit="cover"
                />
              )
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default MedicDetailsDrawer;
