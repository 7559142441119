import React, { useState, useEffect } from "react";
import { Box, Flex, Text, HStack } from "@chakra-ui/react";
import {
  AiOutlineUser,
} from "react-icons/ai";
import {
  FaUserShield,
  FaUserTie,
  FaUserCog,
} from "react-icons/fa"; // Importing from 'react-icons/fa'
import axios from "axios";
import { useSpring, animated } from "@react-spring/web";
import { baseUrl } from "../../../apiCalls/config";

// Function to format numbers with commas
const formatNumber = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const AdminStatBox = () => {
  const [statistics] = useState([
    {
      id: 1,
      title: "All",
      count: 0,
      percentage: "0%",
      bgColor: "gray.400",
      lastMonth: "Last Month",
      icon: AiOutlineUser,
    },
    {
      id: 2,
      title: "Super-admin",
      count: 0,
      percentage: "0%",
      bgColor: "linear-gradient(80deg, #E552FF, #A210C6)",
      lastMonth: "Last Month",
      icon: FaUserShield, // Icon for Super-admin
    },
    {
      id: 3,
      title: "Admin",
      count: 0,
      percentage: "0%",
      bgColor: "#A210C6",
      lastMonth: "Last Month",
      icon: FaUserTie, // Icon for Admin
    },
    {
      id: 4,
      title: "Admin-support",
      count: 0,
      percentage: "0%",
      bgColor: "#E552FF",
      lastMonth: "Last Month",
      icon: FaUserCog, // Icon for Admin-support
    },
  ]);

  const [fetchedData, setFetchedData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found, redirecting to login.");
        window.location.href = "/login";
        return;
      }
  
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
  
        const response = await axios.get(`${baseUrl}/api/statistics`, config);
        const data = response.data[0];
  
        setFetchedData({
          totalAdministrator: data.totalAdministrator,
          totalSuperAdmins: data.totalSuperAdmins,
          totalAdmins: data.totalAdmins,
          totalAdminSupports: data.totalAdminSupports,
        });
      } catch (error) {
        console.error("Error fetching statistics:", error);
      }
    };
  
    fetchData();
  }, []);
  

  const adminSpring = useSpring({
    from: { value: 0 },
    value: fetchedData ? fetchedData.totalAdministrator : 0,
  });
  const superAdminSpring = useSpring({
    from: { value: 0 },
    value: fetchedData ? fetchedData.totalSuperAdmins : 0,
  });
  const totalAdminSpring = useSpring({
    from: { value: 0 },
    value: fetchedData ? fetchedData.totalAdmins : 0,
  });
  const adminSupportSpring = useSpring({
    from: { value: 0 },
    value: fetchedData ? fetchedData.totalAdminSupports : 0,
  });

  return (
    <Flex justifyContent="space-between" w="100%" p={4}>
      {statistics.map((stat, index) => {
        const springValue =
          index === 0
            ? adminSpring.value
            : index === 1
            ? superAdminSpring.value
            : index === 2
            ? totalAdminSpring.value
            : adminSupportSpring.value;

        return (
          <Box
            key={stat.id}
            bg={stat.bgColor}
            borderRadius="20px"
            p={4}
            w="23%"
            color="white"
          >
            <Flex justifyContent="space-between" alignItems="center">
              <Text fontSize="lg" fontWeight="bold">
                {stat.title}
              </Text>
              <stat.icon size={24} />
            </Flex>
            <Text fontSize="2xl" fontWeight="bold" mt={2}>
              <animated.span>
                {springValue.to((val) => formatNumber(Math.floor(val)))}
              </animated.span>
            </Text>
            <HStack justifyContent="space-between" mt={2}>
              <Text bg="rgba(255, 255, 255, 0.3)" borderRadius="10px" p={1}>
                {stat.percentage}
              </Text>
              <Text>{stat.lastMonth}</Text>
            </HStack>
          </Box>
        );
      })}
    </Flex>
  );
};

export default AdminStatBox;
