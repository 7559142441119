import React, { useState } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Box,
  Text,
  Button,
  VStack,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import {
  exportTransactionAsPDF,
  exportTransactionAsExcel,
} from "./exportTransactionFunctions";

const TransactionDetailsDrawer = ({
  isOpen,
  onClose,
  transaction,
  selectedTransactions = [],
  handleSendReceipts,
  handleExportReceipts,
}) => {
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  if (!transaction) return null;

  const openExportModal = () => setIsExportModalOpen(true);
  const closeExportModal = () => setIsExportModalOpen(false);

  const handleExportClick = (format) => {
    const transactionsToExport =
      selectedTransactions.length > 0 ? selectedTransactions : [transaction];

    if (format === "pdf") {
      exportTransactionAsPDF(transactionsToExport);
    } else if (format === "excel") {
      exportTransactionAsExcel(transactionsToExport);
    }
    closeExportModal();
  };

  return (
    <>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent bg="#4B4B4B" color="white">
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            Transaction Details
          </DrawerHeader>

          <DrawerBody>
            <VStack align="left" spacing={4}>
              <Box>
                <Text fontWeight="bold">Transaction ID:</Text>
                <Text>{transaction.id || "N/A"}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Customer ID:</Text>
                <Text>{transaction.customerId || "N/A"}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Appointment ID:</Text>
                <Text>{transaction.appointmentId || "N/A"}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Amount:</Text>
                <Text>₦{transaction.amount?.toLocaleString() || "N/A"}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Status:</Text>
                <Text>{transaction.status || "N/A"}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Method:</Text>
                <Text>{transaction.method || "N/A"}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Type:</Text>
                <Text>{transaction.type || "N/A"}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Beneficiary Name:</Text>
                <Text>{transaction.beneficiaryName || "N/A"}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Narration:</Text>
                <Text>{transaction.narration || "N/A"}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Transaction Date:</Text>
                <Text>
                  {transaction.transactionDate
                    ? new Date(transaction.transactionDate).toLocaleString()
                    : "N/A"}
                </Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Reference:</Text>
                <Text>{transaction.reference || "N/A"}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Update Date:</Text>
                <Text>
                  {transaction.updateDate
                    ? new Date(transaction.updateDate).toLocaleString()
                    : "N/A"}
                </Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Subscription:</Text>
                <Text color={transaction.subscription ? "green.200" : "yellow.200"}>
                  {transaction.subscription ? "Yes" : "No"}
                </Text>
              </Box>
            </VStack>
          </DrawerBody>

          <DrawerFooter justifyContent="space-between">
            <Button colorScheme="blue" mr={2} onClick={handleSendReceipts}>
              Send Receipt
            </Button>
            <Button colorScheme="green" onClick={openExportModal}>
              Export Receipt
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      {/* Export Modal */}
      <Modal isOpen={isExportModalOpen} onClose={closeExportModal}>
        <ModalOverlay />
        <ModalContent bg="#4B4B4B" color="white">
          <ModalHeader>Export Receipt</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Select the format to export the receipt:</Text>
          </ModalBody>
          <ModalFooter justifyContent="space-between">
            <Button
              colorScheme="green"
              onClick={() => handleExportClick("pdf")}
            >
              Export as PDF
            </Button>
            <Button
              colorScheme="blue"
              onClick={() => handleExportClick("excel")}
            >
              Export as Excel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TransactionDetailsDrawer;
