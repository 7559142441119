import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/Logo.svg";
import GetStartedModal from "../unAuthLayouts/GetStarted";
import {
  Box,
  Button,
  Link as ChakraLink,
  Text,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Image,
  ChakraProvider,
  extendTheme,
} from "@chakra-ui/react";
import AOS from "aos";
import "aos/dist/aos.css";
import "../../styles/pages/LandingPage.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loginBaseUrl, baseUrl } from "../../apiCalls/config";

const customTheme = extendTheme({
  components: {
    Link: {
      baseStyle: {
        _focus: { boxShadow: "none" },
      },
    },
  },
  fonts: {
    body: "Montserrat, sans-serif",
    heading: "Gill Sans MT, sans-serif",
  },
});

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [emailInput, setEmailInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleEmailInputChange = (e) => setEmailInput(e.target.value);
  const handlePasswordInputChange = (e) => setPasswordInput(e.target.value);
  const handleClick = () => setShow(!show);
  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleLogin = async () => {
    setLoading(true);

    if (!validateEmail(emailInput)) {
      toast.warning("Please enter a valid email address");
      setLoading(false);
      return;
    }
    const apiUrl = `${loginBaseUrl}/login`;
    const formattedEmail = emailInput.toLowerCase();
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: formattedEmail,
          password: passwordInput,
        }),
      });

      if (response.ok) {
        const responseData = await response.json();
        const userRoles = responseData.roles || [];
        console.log("user roles", userRoles);

        if (userRoles.includes("SUSPENDED")) {
          toast.warning(
            "Account Suspended: Your account is on suspension. Kindly contact support."
          );
          setLoading(false);
          return;
        }

        localStorage.setItem("token", responseData.access_token);

        if (
          userRoles.includes("NEW_USER") &&
          userRoles.includes("VERIFIED_CUSTOMER")
        ) {
          toast.success("Login successful");
          await signIn(formattedEmail);
          navigate("/client-dashboard");
        } else if (
          userRoles.includes("VERIFIED_CUSTOMER") ||
          userRoles.includes("VERIFIED_MEDIC") 
        ) {
          toast.success("Login successful");
          await signIn(formattedEmail);
          navigate("/medic-dashboard");
        } else if (
          userRoles.includes("SUPER_ADMIN") ||
          userRoles.includes("ADMIN") ||
          userRoles.includes("ADMIN_SUPPORT")
        ) {
          toast.success("Login successful");
          await signIn(formattedEmail);
          navigate("/admin");
        } else {
          toast.error("Unauthorized access");
        }
      } else {
        const errorData = await response.json();
        console.error("Login failed:", errorData.message);
        toast.error("Wrong password or email address");
      }
    } catch (error) {
      console.error("Login failed:", error.message);
      toast.error("Login failed");
    } finally {
      setLoading(false);
    }
  };

  const signIn = async (email) => {
    try {
      const apiUrl =`${baseUrl}/angel/sign-in`;
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams({ email: email }),
      });
  
      if (response.ok) {
        console.log("Sign-in successful");
      } else {
        console.error("Sign-in failed");
      }
    } catch (error) {
      console.error("Error during sign-in:", error);
    }
  };

  useEffect(() => {
    AOS.init();
  }, []);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <ChakraProvider theme={customTheme}>
      <Box
        overflowX={{ base: "auto", md: "hidden" }}
        overflowY={{ base: "hidden", md: "auto" }}
        minHeight="100vh"
      >
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <Box
          fontFamily="body"
          color="#00000080"
          display={{ base: "block", md: "flex" }}
          paddingX={{ base: "1rem", md: "2rem" }}
          justifyContent="center"
        >
          <Box
            maxWidth={{ base: "90%", sm: "600px", md: "650px", lg: "700px" }}
            mx="auto"
            textAlign="center"
            mt={{ base: "30px", md: "60px" }}
            mb={{ base: "30px", md: "60px" }}
            boxShadow="lg"
            bg="white"
            p="6"
          >
            <a href="/">
              <Image
                src={logo}
                alt="Logo"
                mx="auto"
                h={{ base: "60px", md: "100px" }}
                w={{ base: "200px", md: "350px" }}
              />
            </a>
            <Text mt="5px" fontSize="20px" fontFamily="header" color="#A210C6">
              Login to your account
            </Text>
            <FormControl fontFamily="body" isRequired mt="20px">
              <FormLabel>Email address</FormLabel>
              <InputGroup>
                <Input
                  placeholder="Email address"
                  value={emailInput}
                  onChange={handleEmailInputChange}
                />
              </InputGroup>
              <FormLabel mt="30px">Password</FormLabel>
              <InputGroup size="md">
                <Input
                  pr="4.5rem"
                  type={show ? "text" : "password"}
                  placeholder="Enter password"
                  onChange={handlePasswordInputChange}
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" onClick={handleClick}>
                    {show ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>

              <Button
                isLoading={loading}
                loadingText="Loading..."
                onClick={handleLogin}
                width="full"
                bg="linear-gradient(80deg, #A210C6, #E552FF)"
                mt="20px"
                color="white"
              >
                {loading ? "Loading..." : "Login"}
              </Button>
              <ChakraLink
                fontStyle="italic"
                fontFamily="body"
                href="/forgot-password"
                color="#A210C6"
                display="block"
                mt="10px"
              >
                Forgot password?
              </ChakraLink>
              <Text fontSize="16px" fontFamily="body" mt="15px">
                Don't have an account?{" "}
                <ChakraLink
                  onClick={handleOpenModal}
                  fontStyle="italic"
                  color="#A210C6"
                >
                  Sign Up
                </ChakraLink>
              </Text>
            </FormControl>
          </Box>
        </Box>
      </Box>
      <GetStartedModal isOpen={showModal} onClose={handleCloseModal} />
    </ChakraProvider>
  );
};

export default Login;
