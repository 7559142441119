import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useMediaQuery,
  Button,
  Text,
} from "@chakra-ui/react";

const VerificationMethodModal = ({
  isOpen,
  onClose,
  handlePhoneVerification,
  handleEmailVerification,
}) => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const modalWidth = isLargerThan768 ? "400px" : "90vw";
  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent width={modalWidth} borderRadius="25px 25px 25px 0px">
        <ModalHeader>Choose Verification Method</ModalHeader>
        <ModalBody>
          <Text mb={4}>
            Please choose how you'd like to verify your account.
          </Text>
          <Button
            width="100%"
            bg="linear-gradient(80deg, #A210C6, #E552FF)"
            color="white"
            onClick={handleEmailVerification}
          >
            Verify via Email
          </Button>
          <Button
            width="100%"
            mt="10px"
            bg="linear-gradient(80deg, #A210C6, #E552FF)"
            color="white"
            onClick={handlePhoneVerification}
          >
            Verify via Phone Number
          </Button>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default VerificationMethodModal;
