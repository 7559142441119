import React, { useState } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Box,
  Text,
  Avatar,
  Divider,
  Button,
  VStack,
  useToast,
} from "@chakra-ui/react";
import EditCustomerModal from "./EditCustomerModal";
import SuspendCustomerModal from "./SuspendCustomerModal";

const CustomerDetailsDrawer = ({
  isOpen,
  onClose,
  customer,
  onUpdateCustomer,
}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isSuspendModalOpen, setIsSuspendModalOpen] = useState(false);
  const [isSuspended, setIsSuspended] = useState(customer.bioData.suspended);
  const toast = useToast();

  const handleOpenEditModal = () => {
    setIsEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
  };

  const handleOpenSuspendModal = () => {
    setIsSuspendModalOpen(true);
  };

  const handleCloseSuspendModal = () => {
    setIsSuspendModalOpen(false);
  };

  const handleSuspensionChange = (newSuspendedState) => {
    setIsSuspended(newSuspendedState);
    onUpdateCustomer({
      ...customer,
      bioData: {
        ...customer.bioData,
        suspended: newSuspendedState,
      },
    });
    toast({
      title: `Customer ${newSuspendedState ? "Suspended" : "Unsuspended"}`,
      description: `The customer has been ${
        newSuspendedState ? "suspended" : "unsuspended"
      } successfully.`,
      status: "success",
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  if (!customer) return null;

  return (
    <>
      <Drawer
        blockScrollOnMount={false}
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent bg="#4B4B4B" color="white">
          <DrawerCloseButton />
          <DrawerHeader>
            <Avatar
              borderRadius="full"
              boxSize="80px"
              src={customer.bioData.image}
              alt={customer.bioData.firstName}
            />
            <Text fontSize="2xl" fontWeight="bold" mt={2}>
              {`${customer.bioData.firstName} ${customer.bioData.lastName}`}
            </Text>
          </DrawerHeader>

          <DrawerBody>
            <VStack align="left" spacing={4}>
              <Box>
                <Text fontWeight="bold">Phone Number:</Text>
                <Text>{customer.phoneNumber}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Email:</Text>
                <Text>{customer.bioData.email}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Gender:</Text>
                <Text>{customer.bioData.gender}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Date of Birth:</Text>
                <Text>
                  {new Date(customer.bioData.dob).toLocaleDateString()}
                </Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Address:</Text>
                <Text>{customer.bioData.address || "N/A"}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Created At:</Text>
                <Text>
                  {new Date(customer.bioData.createdAt).toLocaleDateString()}
                </Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Has Wallet:</Text>
                <Text>{customer.walletCreated ? "Yes" : "No"}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Wallet Balance:</Text>
                <Text>{customer.walletBalance || "N/A"}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Pending Appointments:</Text>
                <Text>{customer.numberOfPendingAppointments}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Active Appointments:</Text>
                <Text>{customer.numberOfActiveAppointments}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Completed Appointments:</Text>
                <Text>{customer.numberOfCompletedAppointments}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Beneficiaries:</Text>
                <Text>{customer.numberOfBeneficiaries}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Subscriptions:</Text>
                <Text>{customer.numberOfSubscriptions}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Amount Spent:</Text>
                <Text>₦{customer.amountSpent.toLocaleString()}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Number of Plans:</Text>
                <Text>{customer.numberOfPlan}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Bank Details:</Text>
                <Text>
                  {customer.bankDetails
                    ? `${customer.bankDetails.bankName} - ${customer.bankDetails.accountNumber}`
                    : "N/A"}
                </Text>
              </Box>
            </VStack>
          </DrawerBody>

          <DrawerFooter justifyContent="space-between">
            <Button
              variant="outline"
              colorScheme="blue"
              mr={3}
              onClick={handleOpenEditModal}
            >
              Edit Customer Details
            </Button>
            <Button
              variant="outline"
              colorScheme={isSuspended ? "green" : "red"}
              onClick={handleOpenSuspendModal}
            >
              {isSuspended ? "Activate Customer" : "Suspend Customer"}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <EditCustomerModal
        isOpen={isEditModalOpen}
        onClose={handleCloseEditModal}
        customer={customer}
      />
      <SuspendCustomerModal
        isOpen={isSuspendModalOpen}
        onClose={handleCloseSuspendModal}
        customerId={customer.id}
        isSuspended={isSuspended}
        onSuspensionChange={handleSuspensionChange}
      />
    </>
  );
};

export default CustomerDetailsDrawer;
