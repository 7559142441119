import React, { useState } from "react";
import axios from "axios";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { baseUrl } from "../../../apiCalls/config";
import LoadingSpinner from "../../../utils/Spiner";

const UpdateMedicCredentialsModal = ({ isOpen, onClose, medic, onUpdate }) => {
  const [cvCopy, setCvCopy] = useState();
  const [license, setLicense] = useState();
  const [cvLoading, setCvLoading] = useState(false);
  const [licenseLoading, setLicenseLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const [formData, setFormData] = useState({
    license: "",
    cvCopy: "",
    id: medic.bioData.id,
  });

  const handleUpdateCredentials = async () => {
    if (!cvCopy && !license) {
      toast({
        title: "No file selected",
        description: "Please select a file to upload.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);
    await postCv(cvCopy, formData, setFormData);
    await postLicense(license, formData, setFormData);

    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${baseUrl}/api/admin/update-medic-credentials`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setIsLoading(false);
      if (response.data.success) {
        toast({
          title: "Saved Successfully",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        onClose();
      } else {
        setIsLoading(false);
        console.error("Error registering");
        toast.error(response.data.message);
      }
    } catch (error) {
      toast({
        title: "Failed",
        description: error.response.data,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const postLicense = async (license, formData, setFormData) => {
    setLicenseLoading(true);
    if (!license) {
      setLicenseLoading(false);
      return;
    }

    // Create new FormData object
    const data = new FormData();
    data.append("file", license);
    data.append("upload_preset", "license");

    // Determine the appropriate upload URL and resource type
    let uploadUrl = "https://api.cloudinary.com/v1_1/dmfewrwla/image/upload"; // Default to image upload
    if (license.type === "application/pdf") {
      uploadUrl = "https://api.cloudinary.com/v1_1/dmfewrwla/raw/upload"; // Use raw upload for PDFs
    }

    try {
      const response = await fetch(uploadUrl, {
        method: "post",
        body: data,
      });

      const imageData = await response.json();

      if (imageData.url) {
        setFormData({
          ...formData,
          license: imageData.url.toString(),
        });
      } else {
        console.error(
          "Failed to upload file:",
          imageData.error || "No error info"
        );
      }
      setLicenseLoading(false);
    } catch (err) {
      console.error("Error uploading file:", err);
      setLicenseLoading(false);
    }
  };

  const postCv = async (cvCopy, formData, setFormData) => {
    setCvLoading(true);
    if (!cvCopy) {
      setCvLoading(false);
      return;
    }

    const data = new FormData();
    data.append("file", cvCopy);
    data.append("upload_preset", "medicCv");

    // Determine the appropriate Cloudinary URL based on file type
    let uploadUrl = "https://api.cloudinary.com/v1_1/dmfewrwla/image/upload"; // Default for images
    if (cvCopy.type === "application/pdf") {
      uploadUrl = "https://api.cloudinary.com/v1_1/dmfewrwla/raw/upload"; // Use raw upload for PDFs
    }

    try {
      const response = await fetch(uploadUrl, {
        method: "post",
        body: data,
      });

      const imageData = await response.json();

      if (imageData.url) {
        setFormData({
          ...formData,
          cvCopy: imageData.url.toString(),
        });
      } else {
        console.error(
          "Failed to upload CV:",
          imageData.error || "No error info"
        );
      }
      setCvLoading(false);
    } catch (err) {
      console.error("Error uploading CV:", err);
      setCvLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update Medic Credentials</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel htmlFor="cv">CV File</FormLabel>
            <Input
              id="cv"
              type="file"
              onChange={(e) => {
                setCvCopy(e.target.files[0]);
                postCv(e.target.files[0], formData, setFormData);
              }}
              accept=".pdf,.doc,.docx"
            />

            {cvLoading && <LoadingSpinner size={20} />}
          </FormControl>
          <FormControl mt={4}>
            <FormLabel htmlFor="license">License File</FormLabel>
            <Input
              id="license"
              type="file"
              onChange={(e) => {
                setLicense(e.target.files[0]);
                postLicense(e.target.files[0], formData, setFormData);
              }}
              accept=".pdf,.doc,.docx"
            />

            {licenseLoading && <LoadingSpinner size={20} />}
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={handleUpdateCredentials}
            isLoading={isLoading}
            isDisabled={isLoading}
            spinner={<Spinner size="sm" />}
          >
            Update Credentials
          </Button>
          <Button variant="ghost" onClick={onClose} isDisabled={isLoading}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UpdateMedicCredentialsModal;
