import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";

export const exportAsPDF = (paymentData) => {
  const doc = new jsPDF();

  // Load the logo image
  const logo = "https://res.cloudinary.com/dmfewrwla/image/upload/v1720388853/MH_logo.png";

  // Add the logo to the PDF
  doc.addImage(logo, "PNG", 10, 10, 50, 20); // X, Y, Width, Height

  // Set the theme color for the title
  doc.setTextColor("#A210C6");

  // Add the title with the theme color
  doc.setFontSize(20);
  doc.text("Payment Receipt", 70, 40);

  // Reset the text color to default (black)
  doc.setTextColor("#000000");

  // Add the payment details as a table
  const paymentDetails = Object.entries(paymentData).map(([key, value]) => [
    { content: key, styles: { textColor: "#000000" } },
    { content: value || "N/A" },
  ]);

  doc.autoTable({
    startY: 50, // Start after the logo and title
    body: paymentDetails,
    theme: "grid", // Style the table
    styles: {
      fillColor: "#FFFFFF", // White background for the table cells
    },
    headStyles: {
      fillColor: "#A210C6", // Theme color for the header
      textColor: "#FFFFFF", // White text for the header
    },
    bodyStyles: {
      textColor: "#000000", // Default text color for the body
    },
  });

  // Save the PDF
  doc.save("Payment_Receipt.pdf");
};


export const exportAsExcel = (paymentData) => {
  const worksheetData = [];

  // Add each key-value pair as a row in the worksheet
  for (const key in paymentData) {
    if (paymentData.hasOwnProperty(key)) {
      worksheetData.push([key, paymentData[key]]);
    }
  }

  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Payment Receipt");

  // Generate and download the Excel file
  XLSX.writeFile(workbook, "Payment_Receipt.xlsx");
};
