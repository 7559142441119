import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Checkbox,
  Button,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { format, formatDistanceToNow } from "date-fns"; 
import ReportDetailsDrawer from "../../sections/admin/ReportDetailsDrawer";
import { baseUrl } from "../../../apiCalls/config";

const RecentMedicalReports = () => {
  const [medicalReportsData, setMedicalReportsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedReports, setSelectedReports] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [filterType, setFilterType] = useState(null); 
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetchAllReports();
  }, []);

  const fetchAllReports = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, redirecting to login.");
      window.location.href = "/login";
      return;
    }
  
    setLoading(true);
    axios
      .get(`${baseUrl}/api/admin/medical-reports`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.success) {
          const sortedData = response.data.data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setMedicalReportsData(sortedData);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };
  
  const searchReports = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, redirecting to login.");
      window.location.href = "/login";
      return;
    }
  
    if (!searchQuery) {
      fetchAllReports();
      return;
    }
  
    setLoading(true);
  
    let query = "";
  
    if (filterType === "date") {
      const formattedDate = format(new Date(searchQuery), "yyyy-MM-dd");
      query = `date=${formattedDate}`;
    } else {
      query = `policyNumber=${searchQuery}`;
    }
  
    axios
      .get(`${baseUrl}/api/admin/reports/search?${query}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.success) {
          const sortedData = response.data.data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setMedicalReportsData(sortedData);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };
  
  const handleSelectReport = (reportId) => {
    if (selectedReports.includes(reportId)) {
      setSelectedReports(selectedReports.filter((id) => id !== reportId));
    } else {
      setSelectedReports([...selectedReports, reportId]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedReports([]);
    } else {
      setSelectedReports(medicalReportsData.map((report) => report.id));
    }
    setSelectAll(!selectAll);
  };

  const handleSendReports = () => {
    alert(`Sending reports: ${selectedReports.join(", ")}`);
  };

  const handleExportReports = () => {
    alert(`Exporting reports: ${selectedReports.join(", ")}`);
  };

  const openDrawer = (report) => {
    setSelectedReport(report);
    setIsDrawerOpen(true);
  };

  const clearSearch = () => {
    setSearchQuery("");
    setFilterType(null);
    fetchAllReports();
  };

  return (
    <Box bg="#4B4B4B" borderRadius="10px" p={4} color="white" w="100%">
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Text fontSize="md" fontWeight="bold">
          Recent Medical Reports
        </Text>
        <Menu>
          <MenuButton  fontSize="14px" as={Button} rightIcon={<ChevronDownIcon />}>
            Advanced Filter
          </MenuButton>
          <MenuList  fontSize="12px" color="black" fontWeight="bold">
            {/* <MenuItem onClick={() => setFilterType("date")}>
              Search by Date
            </MenuItem> */}
            <MenuItem onClick={() => setFilterType("policyNumber")}>
              Search by Policy Number
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>

      {filterType === "date" && (
        <Flex justifyContent="flex-start" mb={4}>
          <Box
            style={{
              padding: "0 8px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              height: "40px", 
              width: "220px", 
              display: "flex",
              alignItems: "center", 
            }}
          >
            <DatePicker
              selected={searchQuery ? new Date(searchQuery) : null}
              onChange={(date) => setSearchQuery(date.toISOString().split("T")[0])}
              maxDate={new Date()}
              dateFormat="yyyy-MM-dd"
              placeholderText="Select Date"
              className="custom-datepicker"
              style={{ width: "100%", border: "none", outline: "none" }}
            />
          </Box>

          <Button  fontSize="12px" ml={2} onClick={searchReports} colorScheme="blue">
            Search
          </Button>
          <Button  fontSize="12px" ml={2} onClick={clearSearch} colorScheme="gray">
            Clear
          </Button>
        </Flex>
      )}

      {filterType === "policyNumber" && (
        <Flex justifyContent="flex-start" mb={4}>
          <Input
            placeholder="Enter Policy Number"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            maxW="200px"
            bg="white"
            color="black"
            borderRadius="md"
          />
          <Button  fontSize="12px" ml={2} onClick={searchReports} colorScheme="blue">
            Search
          </Button>
          <Button  fontSize="12px" ml={2} onClick={clearSearch} colorScheme="gray">
            Clear
          </Button>
        </Flex>
      )}

      {selectedReports.length > 0 && (
        <Flex justifyContent="flex-start" mb={4}>
          <Button colorScheme="blue" mr={2} onClick={handleSendReports}>
            Send Selected Report(s)
          </Button>
          <Button colorScheme="green" onClick={handleExportReports}>
            Export Selected Report(s)
          </Button>
        </Flex>
      )}

      {loading ? (
        <Flex justifyContent="center" alignItems="center" h="200px">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <Box maxH="400px" overflowY="auto">
          <Table variant="simple" colorScheme="whiteAlpha">
            <Thead
              css={{
                // position: "sticky",
                top: 0,
                background: "#4B4B4B",
                zIndex: 1,
              }}
            >
              <Tr>
                <Th color="#A210C6">
                  <Checkbox
                    colorScheme="blue"
                    isChecked={selectAll}
                    onChange={handleSelectAll}
                  />
                </Th>
                <Th color="purple.200">Policy Number</Th>
                <Th color="purple.200">Medic Full Name</Th>
                <Th color="purple.200">Recipient Full Name</Th>
                <Th color="purple.200">Service Plan</Th>
                <Th color="purple.200">Timestamp</Th>
                <Th color="purple.200">Details</Th>
              </Tr>
            </Thead>
            <Tbody>
              {medicalReportsData.map((report) => (
                <Tr fontSize="13px"  key={report.id} _hover={{ bg: "#3B3B3B" }}>
                  <Td>
                    <Checkbox
                      colorScheme="blue"
                      isChecked={selectedReports.includes(report.id)}
                      onChange={(e) => {
                        e.stopPropagation();
                        handleSelectReport(report.id);
                      }}
                    />
                  </Td>
                  <Td>{report.policyNumber || "N/A"}</Td>
                  <Td>{report.medicFullName}</Td>
                  <Td>{report.recipientFullName}</Td>
                  <Td>{report.servicePlan}</Td>
                  <Td>
                    {formatDistanceToNow(new Date(report.createdAt), {
                      addSuffix: true,
                    })}
                  </Td>
                  <Td>
                    <Button
                      size="sm"
                      colorScheme="blue"
                      onClick={() => openDrawer(report)}
                    >
                      View
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}
      <ReportDetailsDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        report={selectedReport}
      />
    </Box>
  );
};

export default RecentMedicalReports;
