import React, { useState, useEffect } from "react";
import { Box, Flex, Text, HStack } from "@chakra-ui/react";
import {
  FaUserTimes,
  FaUserNurse,
  FaUserMd,
  FaStethoscope,
  FaHeartbeat,
} from "react-icons/fa"; // Importing from 'react-icons/fa'
import axios from "axios";
import { useSpring, animated } from "@react-spring/web";
import { baseUrl } from "../../../apiCalls/config";

// Function to format numbers with commas
const formatNumber = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const MedicStatBox = () => {
  const [fetchedData, setFetchedData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found, redirecting to login.");
        window.location.href = "/login";
        return;
      }

      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(`${baseUrl}/api/statistics`, config);
        const data = response.data[0];

        setFetchedData({
          totalUnVerifiedMedics: data.totalUnVerifiedMedics,
          totalRNs: data.totalRNs,
          totalCNAs: data.totalCNAs,
          totalDoctors: data.totalDoctors,
          totalPhysiotherapists: data.totalPhysiotherapists,
        });
      } catch (error) {
        console.error("Error fetching statistics:", error);
      }
    };

    fetchData();
  }, []);

  const unverifiedSpring = useSpring({
    from: { value: 0 },
    value: fetchedData ? fetchedData.totalUnVerifiedMedics : 0,
  });
  const rnSpring = useSpring({
    from: { value: 0 },
    value: fetchedData ? fetchedData.totalRNs : 0,
  });
  const cnaSpring = useSpring({
    from: { value: 0 },
    value: fetchedData ? fetchedData.totalCNAs : 0,
  });
  const doctorSpring = useSpring({
    from: { value: 0 },
    value: fetchedData ? fetchedData.totalDoctors : 0,
  });
  const ptSpring = useSpring({
    from: { value: 0 },
    value: fetchedData ? fetchedData.totalPhysiotherapists : 0,
  });

  const statistics = [
    {
      id: 1,
      title: "Unverified",
      bgColor: "yellow.400",
      lastMonth: "Last Month",
      icon: FaUserTimes, // Icon for Unverified Medics
    },
    {
      id: 2,
      title: "RNs",
      bgColor: "linear-gradient(80deg, #A210C6, #E552FF)",
      lastMonth: "Last Month",
      icon: FaUserNurse, // Icon for Registered Nurses
    },
    {
      id: 3,
      title: "CNAs",
      bgColor: "gray.400",
      lastMonth: "Last Month",
      icon: FaHeartbeat, // Icon for Certified Nursing Assistants
    },
    {
      id: 4,
      title: "DRs",
      bgColor: "blue.400",
      lastMonth: "Last Month",
      icon: FaUserMd, // Icon for Doctors
    },
    {
      id: 5,
      title: "PTs",
      bgColor: "gray.400",
      lastMonth: "Last Month",
      icon: FaStethoscope, // Icon for Physiotherapists
    },
  ];

  return (
    <Flex justifyContent="space-between" w="100%" p={4}>
      {statistics.map((stat, index) => {
        const springValue =
          index === 0
            ? unverifiedSpring.value
            : index === 1
            ? rnSpring.value
            : index === 2
            ? cnaSpring.value
            : index === 3
            ? doctorSpring.value
            : ptSpring.value;

        return (
          <Box
            key={stat.id}
            bg={stat.bgColor}
            borderRadius="20px"
            p={4}
            w="19%"
            color="white"
          >
            <Flex justifyContent="space-between" alignItems="center">
              <Text fontSize="lg" fontWeight="bold">
                {stat.title}
              </Text>
              <stat.icon size={24} />
            </Flex>
            <Text fontSize="2xl" fontWeight="bold" mt={2}>
              <animated.span>
                {springValue.to((val) => formatNumber(Math.floor(val)))}
              </animated.span>
            </Text>
            <HStack justifyContent="space-between" mt={2}>
              <Text bg="rgba(255, 255, 255, 0.3)" borderRadius="10px" p={1}>
                {stat.percentage}
              </Text>
              <Text>{stat.lastMonth}</Text>
            </HStack>
          </Box>
        );
      })}
    </Flex>
  );
};

export default MedicStatBox;
