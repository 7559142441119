import axios from "axios";
import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BookingInstructions from "./BookingInstructions";
import { baseUrl } from "../../apiCalls/config";
import { FormFields } from "./formFields";
// import { FaPhoneAlt } from "react-icons/fa";
import PaymentModal from "./PaymentMethod";
import SpecialNeedsForm from "./SpecialNeedsForm";
import {
  formatDateToUTC,
  calculateEndDate,
  calculateUrgency,
  calculateServiceCost,
  calculateAmountPayable,
} from "./helpers";

import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightElement,
  InputLeftAddon,
  Input,
  Button,
  Flex,
  Box,
  Select,
  Switch,
  Text,
  extendTheme,
} from "@chakra-ui/react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const customTheme = extendTheme({
  components: {
    Link: {
      baseStyle: {
        _focus: {
          boxShadow: "none",
        },
      },
    },
  },
  fonts: {
    body: "Montserrat, sans-serif",
    heading: "Gill Sans MT, sans-serif",
  },
});

const stateTownsMap = {
  Lagos: [
    "Ikeja",
    "Ogudu",
    "Berger",
    "Surulere",
    "Ikorodu",
    "Epe",
    "Badagry",
    "Yaba",
    "Victoria Island",
    "Lekki",
    "Lagos Island",
    "Ajah",
    "Sangotedo",
    "Agege",
    "Ikoyi",
    "Okota",
    "Mushin",
    "Iyana Ipaja",
    "Oshodi",
    "Isolo",
    "Ikotun",
    "Festac",
    "Ijesha",
    "Maryland",
    "Ojota",
  ],
  Abuja: [
    "Asokoro",
    "Maitama",
    "Gwarinpa",
    "Wuse",
    "Garki",
    "Jabi",
    "Central Business District (CBD)",
    "Lugbe",
    "Karu",
    "Kubwa",
    "Nyanya",
    "Lokogoma",
    "Apo",
    "Utako",
    "Gudu",
    "Kuje",
    "Dutse",
    "Bwari",
    "Mpape",
    "Galadimawa",
    "Life Camp",
    "Kaura",
    "Karmo",
    "Gwagwalada",
  ],
  Nasarawa: ["Lafia", "Keffi", "Akwanga", "Doma", "Nasarawa", "Karu"],
  Ibadan: [
    "Bodija",
    "Dugbe",
    "Iwo Road",
    "Challenge",
    "Ring Road",
    "Mokola",
    "Apata",
  ],
  Porthacourt: ["Diobu", "Trans Amadi", "Eleme", "GRA", "Rumuokoro", "Rivers"],
  Enugu: ["Enugu", "Nsukka", "Udi", "Oji River", "Ezeagu"],
  Ogun: [
    "Abeokuta ",
    "Ijebu Ode",
    "Ota (Otta)",
    "Sagamu ",
    "Ilaro ",
    "Ijebu Igbo",
    "Ayetoro ",
    "Sango ",
    "Ifo ",
    "Owode ",
    "Agbara ",
    "Ikenne ",
    "Ijoko ",
    "Odogbolu ",
    "Remo ",
    "Ado-Odo",
    "Ewekoro ",
    "Igbesa ",
    "Isara-Remo",
    "Imeko ",
    "Ijebu Remo",
  ],
  Calabar: ["Big Qua", "Marian", "Akpabuyo", "Odukpani", "Ikom"],
  Delta: ["Warri", "Asaba", "Ughelli", "Sapele", "Kwale"],
};

const majorLanguages = [
  "English",
  "Yoruba",
  "Igbo",
  "Hausa",
  "Pidgin",
  "Other",
  "Anyone",
];

const OthersAppointmentModal = ({ isOpen, onClose }) => {
  const { user } = useSelector((state) => state.userReducer);
  const [loading, setLoading] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedDob, setSelectedDob] = useState(null);
  const [customizedPlans, setCustomizedPlans] = useState([]);
  const [addToBeneficiaryList, setAddToBeneficiaryList] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [paymentData, setPaymentData] = useState({});
  const [shiftDisabled, setIsShiftDisabled] = useState(false);
  const [priority, setPriority] = useState("");
  const [specialNeeds, setSpecialNeeds] = useState([]);
  const [showSpecialNeedsForm, setShowSpecialNeedsForm] = useState(false);

  const [isBookingInstructionsOpen, setIsBookingInstructionsOpen] =
    useState(false);

  const [formFields, setFormFields] = useState({
    recipientFirstname: "",
    recipientLastname: "",
    recipientPhoneNumber: "",
    recipientGender: "",
    recipientDOB: "",
    currentLocation: "",
    shift: "",
    servicePlan: "",
    costOfService: "",
    startDate: "",
    relationship: "",
    medicalReport: "",
    recipientHealthHistory: "",
    recipientTown: "",
    preferredMedicGender: "",
    preferredLanguage: "",
    duration: "",
    amountPayable: "",
    religion: "",
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsBookingInstructionsOpen(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "servicePlan") {
      const selectedPlan = customizedPlans.find((plan) => plan.name === value);

      if (selectedPlan) {
        if (selectedPlan.costOfService) {
          const cleanedCost = selectedPlan.costOfService;

          setFormFields((prevFields) => ({
            ...prevFields,
            [name]: value,
            shift: selectedPlan.shift,
            costOfService: cleanedCost,
            medicSpecialization: selectedPlan.preferredCaregiver,
            amountPayable: selectedPlan.amountPayable || "",
          }));
        } else {
          console.log("Cost of Service is undefined or null");
        }
        setIsShiftDisabled(true);
      } else {
        setFormFields((prevFields) => ({
          ...prevFields,
          [name]: value,
          shift: "",
          costOfService: "",
          amountPayable: "",
        }));
        setIsShiftDisabled(false);
      }

      if (selectedPlan) {
        calculateEndDate(
          value,
          selectedStartDate,
          selectedPlan.duration,
          customizedPlans,
          setFormFields
        );
      } else {
        calculateEndDate(
          value,
          selectedStartDate,
          null,
          customizedPlans,
          setFormFields
        );
      }

      if (value === "Short home visit") {
        setFormFields((prevFields) => ({
          ...prevFields,
          shift: "Day Shift (8hrs)",
        }));
        setIsShiftDisabled(true);
      }
    } else {
      setFormFields((prevFields) => ({ ...prevFields, [name]: value }));
    }
  };

  const [availableTowns, setAvailableTowns] = useState([]);

  const handleStateChange = (e) => {
    const selectedState = e.target.value;
    const towns = stateTownsMap[selectedState] || []; // Update available towns based on the selected state
    setFormFields((prevFields) => ({
      ...prevFields,
      state: selectedState,
      recipientTown: "", // Reset town when state changes
    }));
    setAvailableTowns(towns);
  };

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    setFormFields((prevFields) => ({ ...prevFields, startDate: date }));
    calculateUrgency(date, setPriority);

    if (formFields.servicePlan) {
      const selectedPlan = customizedPlans.find(
        (plan) => plan.name === formFields.servicePlan
      );
      if (selectedPlan) {
        calculateEndDate(
          formFields.servicePlan,
          date,
          selectedPlan.duration,
          customizedPlans,
          setFormFields
        );
      } else {
        calculateEndDate(
          formFields.servicePlan,
          date,
          null,
          customizedPlans,
          setFormFields
        );
      }
    }
  };

  const handleDOBChange = (date) => {
    setSelectedDob(date);
    setFormFields({ ...formFields, recipientDOB: date });
  };

  const getValidNigerianPhoneNumber = (phoneNumber) => {
    const pattern = /^(\d{10})$/;
    if (pattern.test(phoneNumber)) {
      return "0" + phoneNumber;
    }
    return null;
  };

  const handleFormSubmit = async () => {
    setLoading(true);

    const validPhoneNumber = getValidNigerianPhoneNumber(
      formFields.recipientPhoneNumber
    );
    console.log("number " + validPhoneNumber);

    if (!validPhoneNumber) {
      toast.warning("Please enter a valid Nigerian phone number");
      setLoading(false);
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const apiUrl = `${baseUrl}/appointment/save`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const formatDateWithDayAdjustment = (selectedDate) =>
        formatDateToUTC(new Date(selectedDate));

      const formDataWithDates = {
        ...formFields,
        startDate: formatDateWithDayAdjustment(formFields.startDate),
        recipientDOB: formatDateWithDayAdjustment(formFields.recipientDOB),
        recipientPhoneNumber: validPhoneNumber,
        customerPhoneNumber: user?.phoneNumber,
        customerId: user?.id,
        priority,
        specialNeeds,
      };
      const requestBody = JSON.stringify(formDataWithDates);
      const response = await axios.post(apiUrl, requestBody, { headers });

      if (response.data.success) {
        setLoading(false);
        setFormFields({
          recipientFirstname: "",
          recipientLastname: "",
          recipientPhoneNumber: "",
          recipientGender: "",
          recipientDOB: "",
          currentLocation: "",
          shift: "",
          servicePlan: "",
          costOfService: "",
          startDate: "",
          relationship: "",
          medicalReport: "",
          recipientHealthHistory: "",
          recipientTown: "",
          preferredMedicGender: "",
          preferredLanguage: "",
          amountPayable: "",
        });
        toast.success("Appointment saved");
        setPaymentData({
          costOfService: response.data.data.costOfService,
          appointmentId: response.data.data.id,
          endDate: response.data.data.endDate,
          startDate: response.data.data.startDate,
          beneficiary: `${response.data.data.recipientFirstname} ${response.data.data.recipientLastname}`,
        });
        setTimeout(() => {
          setIsPaymentModalOpen(true);
        }, 1000);
      } else {
        setLoading(false);
        console.error("Error booking appointment");
        const errorMessage = response.data
          ? response.data.message
          : "Unknown error";
        toast.error(errorMessage);
      }
    } catch (error) {
      setLoading(false);
      console.error("An error occurred:", error);
      toast.error("Error booking appointment");
    }
  };

  useEffect(() => {
    calculateServiceCost(
      formFields.servicePlan,
      formFields.shift,
      customizedPlans,
      setFormFields
    );
  }, [formFields.servicePlan, formFields.shift, customizedPlans]);

  useEffect(() => {
    calculateAmountPayable(
      formFields.servicePlan,
      formFields.shift,
      customizedPlans,
      setFormFields
    );
  }, [formFields.servicePlan, formFields.shift, customizedPlans]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await axios.get(
          `${baseUrl}/appointment/all-customized-services`,
          config
        );

        if (response.data.success) {
          setCustomizedPlans(response.data.data);
        } else {
          console.error("Failed to fetch custom services");
        }
      } catch (error) {
        console.error("Error fetching custom services:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSwitchChange = async () => {
    setLoading(true);

    const validPhoneNumber = getValidNigerianPhoneNumber(
      formFields.recipientPhoneNumber
    );
    console.log("number " + validPhoneNumber);

    if (!validPhoneNumber) {
      toast.warning("Please enter a valid Nigerian phone number");
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const apiUrl = `${baseUrl}/appointment/addNewBeneficiary`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const formatDateWithDayAdjustment = (selectedDate) =>
        formatDateToUTC(new Date(selectedDate));

      const formDataWithDates = {
        ...formFields,
        recipientDOB: formatDateWithDayAdjustment(formFields.recipientDOB),
        recipientPhoneNumber: validPhoneNumber,
        customerPhoneNumber: user?.phoneNumber,
        customerId: user?.id,
      };

      const requestBody = JSON.stringify(formDataWithDates);

      const response = await axios.post(apiUrl, requestBody, { headers });

      if (response.data.success) {
        setLoading(false);
        toast.success("Beneficiary added");
      } else {
        setLoading(false);
        console.error("Error adding beneficiary");
        const errorMessage = response.data
          ? response.data.message
          : "Unknown error";
        toast.error(errorMessage);
      }
    } catch (error) {
      setLoading(false);
      console.error("An error occurred:", error);
      toast.error("Error adding beneficiary");
    }
  };

  const handleLocationChange = (location) => {
    setFormFields((prevFields) => ({
      ...prevFields,
      currentLocation: location,
    }));
  };

  return (
    <>
      <Drawer
        theme={customTheme}
        isOpen={isOpen}
        onClose={onClose}
        size={{ base: "md", md: "lg" }}
      >
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <DrawerOverlay />
        <DrawerContent alignItems="center">
          <DrawerCloseButton />
          <DrawerHeader color="#A210C6" fontFamily="heading">
            Book Appointment
          </DrawerHeader>

          <DrawerBody>
            {showSpecialNeedsForm ? (
              <SpecialNeedsForm
                specialNeeds={specialNeeds}
                loading={loading}
                setSpecialNeeds={setSpecialNeeds}
                handleSubmit={handleFormSubmit}
                handleBack={() => setShowSpecialNeedsForm(false)}
              />
            ) : (
              <Box
                alignItems="center"
                p="15px"
                boxShadow="0 0 10px rgba(0, 0, 0, 0.1)"
              >
                <FormControl w={{ base: "100%", md: "100%" }}>
                  <FormLabel
                    ml={{ base: "0", md: "30px" }}
                    fontWeight="bold"
                    fontFamily="heading"
                    color="#00000080"
                  >
                    Enter Beneficiary details
                  </FormLabel>
                  <Flex
                    display={{ base: "block", md: "flex" }}
                    ml={{ base: "0", md: "30px" }}
                  >
                    <InputGroup>
                      <Input
                        color="#00000080"
                        bg="#f9f9f9"
                        name="recipientFirstname"
                        placeholder="first name"
                        value={formFields.recipientFirstname}
                        onChange={handleInputChange}
                        w={{ base: "full", md: "270px" }}
                        h="6vh"
                      />
                    </InputGroup>
                    <InputGroup
                      mt={{ base: "20px", md: "0" }}
                      ml={{ md: "40px" }}
                    >
                      <Input
                        color="#00000080"
                        name="recipientLastname"
                        ml={{ md: "-35px" }}
                        placeholder="last name"
                        value={formFields.recipientLastname}
                        onChange={handleInputChange}
                        w={{ base: "full", md: "270px" }}
                        bg="#f9f9f9"
                        h="6vh"
                      />
                    </InputGroup>
                  </Flex>
                  <Flex flexWrap="wrap" ml={{ base: "full", md: "30px" }}>
                    <Box w={{ base: "full", md: "270px" }}>
                      <FormLabel
                        fontFamily="body"
                        fontWeight="bold"
                        marginTop="20px"
                        color="#00000080"
                      >
                        Gender{" "}
                      </FormLabel>
                      <Select
                        color="#00000080"
                        bg="#f9f9f9"
                        name="recipientGender"
                        placeholder="select gender"
                        w={{ base: "full", md: "270px" }}
                        h="6vh"
                        value={formFields.recipientGender}
                        onChange={handleInputChange}
                      >
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </Select>
                    </Box>
                    <Box
                      w={{ base: "full", md: "270px" }}
                      fontFamily="body"
                      ml={{ md: "5px" }}
                      borderRadius="5px"
                    >
                      <FormLabel
                        color="#00000080"
                        fontWeight="bold"
                        marginTop="20px"
                      >
                        Date of Birth
                      </FormLabel>
                      <Flex
                        h="6vh"
                        padding="5px"
                        paddingLeft="15px"
                        color="#00000080"
                        bg="#f9f9f9"
                        paddingTop="10px"
                        w={{ base: "full", md: "270px" }}
                      >
                        <DatePicker
                          name="recipientDOB"
                          selected={selectedDob}
                          onChange={handleDOBChange}
                          maxDate={new Date()}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          dateFormat="dd-MM-yyyy"
                          placeholderText="select date of birth"
                          className="form-control"
                          color="#00000080"
                          bg="#f9f9f9"
                        />
                      </Flex>
                    </Box>
                  </Flex>
                  <Flex
                    flexWrap="wrap"
                    marginTop="1px"
                    ml={{ base: "0", md: "30px" }}
                    w={{ base: "full" }}
                  >
                    <Box w={{ base: "full" }}>
                      <FormLabel
                        fontFamily="body"
                        fontWeight="bold"
                        marginTop="20px"
                        color="#00000080"
                      >
                        Contact Number{" "}
                      </FormLabel>
                      <InputGroup>
                        <InputLeftAddon children="+234" />
                        <Input
                          color="#00000080"
                          bg="#f9f9f9"
                          name="recipientPhoneNumber"
                          type="tel"
                          placeholder="70xxxxxxxx"
                          value={formFields.recipientPhoneNumber}
                          onChange={handleInputChange}
                          w={{ base: "full", md: "475px" }}
                        />
                        <InputRightElement pointerEvents="none"></InputRightElement>
                      </InputGroup>
                    </Box>
                    <Box w={{ base: "full" }}>
                      <FormLabel
                        fontFamily="body"
                        fontWeight="bold"
                        marginTop="20px"
                        color="#00000080"
                      >
                        Relationship with beneficiary{" "}
                      </FormLabel>
                      <Select
                        name="relationship"
                        placeholder="Select the appropriate relationship type"
                        w={{ base: "full", md: "540px" }}
                        onChange={handleInputChange}
                        color="#00000080"
                        bg="#f9f9f9"
                      >
                        <option value="Mum">Mum</option>
                        <option value="Dad">Dad</option>
                        <option value="Wife">Wife</option>
                        <option value="Husband">Husband</option>
                        <option value="Sister">Sister</option>
                        <option value="Brother">Brother</option>
                        <option value="Uncle">Uncle</option>
                        <option value="Aunt">Aunt</option>
                        <option value="Son">Son</option>
                        <option value="Daughter">Daughter</option>
                        <option value="Niece">Niece</option>
                        <option value="Nephew">Nephew</option>
                        <option value="Cousin">Cousin</option>
                        <option value="Friend">Friend</option>
                        <option value="Colleague">Colleague</option>
                        <option value="Neighbour">Neighbour</option>
                        <option value="MotherInLaw">Mother in-law</option>
                        <option value="FatherInLaw">Father in-law</option>
                        <option value="Grandmother">Grand mother</option>
                        <option value="Grandfather">Grand father</option>
                      </Select>
                    </Box>
                  </Flex>
                </FormControl>

                <Box ml={{ md: "8px" }}>
                  <FormFields
                    formFields={formFields}
                    availableTowns={availableTowns} // Pass available towns
                    handleStateChange={handleStateChange} // Pass state change handler
                    majorLanguages={majorLanguages}
                    handleInputChange={handleInputChange}
                    handleStartDateChange={handleStartDateChange}
                    selectedStartDate={selectedStartDate}
                    customizedPlans={customizedPlans}
                    isShiftDisabled={shiftDisabled}
                    handleLocationChange={handleLocationChange}
                  />
                </Box>

                <Box mb="20px" ml={{ base: "0", md: "40px" }}>
                  <Button
                    isLoading={loading}
                    loadingText="Loading..."
                    w="150px"
                    bg="linear-gradient(80deg, #A210C6, #E552FF)"
                    color="white"
                    mt="20px"
                    onClick={() => setShowSpecialNeedsForm(true)}
                  >
                    {loading ? "Loading..." : "Next"}
                  </Button>
                </Box>
              </Box>
            )}
            <Flex justify="right" marginTop="10px">
              <Text fontFamily="body" color="#A210C6" fontStyle="italic">
                Add to beneficiary list?
              </Text>
              <Switch
                marginLeft="10px"
                colorScheme="green"
                isChecked={addToBeneficiaryList}
                onChange={() => {
                  setAddToBeneficiaryList(!addToBeneficiaryList);
                  if (!addToBeneficiaryList) {
                    handleSwitchChange();
                  }
                }}
              />
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <PaymentModal
        isOpen={isPaymentModalOpen}
        onClose={() => setIsPaymentModalOpen(false)}
        paymentData={paymentData}
      />
      <BookingInstructions
        isOpen={isBookingInstructionsOpen}
        onClose={() => setIsBookingInstructionsOpen(false)}
      />
    </>
  );
};

export default OthersAppointmentModal;
