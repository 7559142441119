import React from "react";
import { FormControl, FormLabel, VStack, Checkbox } from "@chakra-ui/react";

const ActivitiesForm = ({ activities, handleCheckboxChange }) => {
  const activitiesList = [
    "Tub bath/shower assistance",
    "Bed bath/sink bath",
    "Shampoo hair",
    "Shave hair",
    "Mouth care",
    "Dress assistance",
    "Body massage",
    "Wound care/dressing",
    "Catheter care",
    "Ostomy care",
    "Feed client",
    "Served urinal or bed pan",
    "Assisted to toilet or commode",
    "Diaper change",
    "Assistance with transfer to bed, chair or wheelchair"
  ];

  return (
    <FormControl isRequired>
      <FormLabel fontSize={{ base: "18px", md: "20px" }} fontWeight="bold">
        Activities of Daily Living
      </FormLabel>
      <VStack align="start">
        {activitiesList.map((activity) => (
          <Checkbox
            key={activity}
            name={activity}
            isChecked={activities.includes(activity)} 
            onChange={handleCheckboxChange}
          >
            {activity}
          </Checkbox>
        ))}
      </VStack>
    </FormControl>
  );
};

export default ActivitiesForm;
