import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useDisclosure,
} from "@chakra-ui/react";
import axios from "axios";
import AppointmentDetailsDrawer from "./AppointmentDetailsDrawer";
import { baseUrl } from "../../../apiCalls/config";

const formatDate = (dateString) => {
  if (!dateString) return "N/A";
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const CanceledCustomerAppointments = () => {
  const [canceledAppointments, setCanceledAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const fetchCanceledAppointments = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found, redirecting to login.");
        window.location.href = "/login";
        return;
      }

      try {
        const response = await axios.get(
          `${baseUrl}/api/admin/canceled-appointments`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const sortedAppointments = response.data.data.sort(
          (a, b) => new Date(b.canceledAt) - new Date(a.canceledAt)
        );
        setCanceledAppointments(sortedAppointments);
      } catch (error) {
        console.error("Error fetching canceled appointments:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCanceledAppointments();
  }, []);

  const handleRowClick = (appointment) => {
    setSelectedAppointment(appointment.customerAppointment);
    onOpen();
  };

  return (
    <Box
      bg="#4B4B4B"
      borderRadius="10px"
      w="100%"
      css={{
        "&::-webkit-scrollbar": { display: "none" },
        "-ms-overflow-style": "none" /* IE and Edge */,
        "scrollbar-width": "none" /* Firefox */,
      }}
      p={4}
    >
      <Text
        css={{
          position: "sticky",
          top: 0,
          background: "#4B4B4B",
          zIndex: 1,
        }}
        fontSize="md"
        fontWeight="bold"
        textAlign="left"
        color="white"
      >
        Canceled Customer Appointments
      </Text>
      <Box p={4} color="white" w="100%">
        {loading ? (
          <Flex justifyContent="center" alignItems="center" h="200px">
            <Spinner size="xl" />
          </Flex>
        ) : canceledAppointments.length > 0 ? (
          <Box overflow="auto" maxH="600px" w="100%">
            <Table variant="simple" colorScheme="whiteAlpha">
              <Thead
                css={{
                  position: "sticky",
                  top: 0,
                  background: "#4B4B4B",
                  zIndex: 1,
                }}
              >
                <Tr>
                  <Th color="purple.200">Recipient Name</Th>
                  <Th color="purple.200">Customer ID</Th>
                  <Th color="purple.200">Created Date</Th>
                  <Th color="purple.200">Reason for Cancellation</Th>
                </Tr>
              </Thead>
              <Tbody>
                {canceledAppointments.map((appointment) => (
                  <Tr
                    key={appointment.id}
                    onClick={() => handleRowClick(appointment)}
                    cursor="pointer"
                    fontSize="13px"
                    _hover={{ bg: "#3B3B3B" }}
                  >
                    <Td>
                      {appointment.customerAppointment.recipientFirstname}{" "}
                      {appointment.customerAppointment.recipientLastname}
                    </Td>
                    <Td>
                      {appointment.customerAppointment.customerId || "N/A"}
                    </Td>
                    <Td>
                      {formatDate(appointment.customerAppointment.createdAt)}
                    </Td>
                    <Td>{appointment.reasonForCancellation || "N/A"}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        ) : (
          <Text>No canceled appointments at the moment</Text>
        )}
      </Box>

      {/* Drawer for displaying appointment details */}
      {selectedAppointment && (
        <AppointmentDetailsDrawer
          isOpen={isOpen}
          onClose={onClose}
          appointment={selectedAppointment}
        />
      )}
    </Box>
  );
};

export default CanceledCustomerAppointments;
