import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  InputGroup,
  InputLeftElement,
  Th,
  Td,
  VStack,
  Center,
  Avatar,
  Link,
  Button,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  FormControl,
  FormLabel,
  Select,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { AddIcon, SearchIcon } from "@chakra-ui/icons";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import AdminDetailsDrawer from "./AdminDetailsDrawer";
import { baseUrl } from "../../../apiCalls/config";

const getInitials = (name) => {
  const names = name.split(" ");
  const initials = names.map((n) => n[0]).join("");
  return initials.toUpperCase();
};

const AdminManagement = () => {
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchEmail, setSearchEmail] = useState("");
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    gender: "",
    role: "",
  });
  const [isRegistering, setIsRegistering] = useState(false);
  const toast = useToast();

  const fetchAdmins = async () => {
    setLoading(true);

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, redirecting to login.");
      window.location.href = "/login";
      return;
    }

    try {
      const response = await axios.get(`${baseUrl}/api/admin/admin-user/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = response.data.data.map((item) => ({
        id: item.id,
        name: `${item.bioData.firstName} ${item.bioData.lastName}`,
        phoneNumber: item.bioData.phoneNumber,
        email: item.bioData.email,
        dob: item.bioData.dob
          ? new Date(item.bioData.dob).toLocaleDateString()
          : "N/A",
        createdDate: new Date(item.bioData.createdAt).toLocaleDateString(),
        updatedDate: item.bioData.updatedAt
          ? new Date(item.bioData.updatedAt).toLocaleDateString()
          : "N/A",
        role: item.bioData.roles.join(", "),
        image: item.bioData.image,
        fullDetails: item,
      }));
      setAdmins(data);
    } catch (error) {
      console.error("Error fetching admins:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAdmins();
  }, []);

  useEffect(() => {
    if (searchEmail.trim() === "") {
      fetchAdmins();
    }
  }, [searchEmail]);

  const handleSearch = async () => {
    if (searchEmail.trim() === "") return;

    setLoading(true);

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, redirecting to login.");
      window.location.href = "/login";
      return;
    }

    try {
      const response = await axios.get(
        `${baseUrl}/api/admin/admin-user/search?email=${searchEmail}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data;
      const admin = {
        id: data.id,
        name: `${data.bioData.firstName} ${data.bioData.lastName}`,
        phoneNumber: data.bioData.phoneNumber,
        email: data.bioData.email,
        dob: data.bioData.dob
          ? new Date(data.bioData.dob).toLocaleDateString()
          : "N/A",
        createdDate: new Date(data.bioData.createdAt).toLocaleDateString(),
        updatedDate: data.bioData.updatedAt
          ? new Date(data.bioData.updatedAt).toLocaleDateString()
          : "N/A",
        role: data.bioData.roles.join(", "),
        image: data.bioData.image,
        fullDetails: data,
      };
      setAdmins([admin]);
    } catch (error) {
      console.error("Error searching admin:", error);
    } finally {
      setLoading(false);
    }
  };

  const openDrawer = (admin) => {
    setSelectedAdmin(admin);
    setIsDrawerOpen(true);
  };

  const handleRegisterAdmin = async () => {
    setIsRegistering(true); 
  
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, redirecting to login.");
      window.location.href = "/login";
      return;
    }
  
    try {
      const response = await axios.post(
        `${baseUrl}/api/admin/register`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.data.success) {
        fetchAdmins();
        onClose();
        setFormData({
          email: "",
          firstName: "",
          lastName: "",
          phoneNumber: "",
          gender: "",
          role: "",
        });
        toast({
          title: "Admin Registered",
          description: "The new admin has been successfully registered.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      } else {
        console.error("Error registering admin:", response.data.message);
        toast({
          title: "Registration Failed",
          description: response.data.message || "There was an error registering the admin.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-left",
        });
      }
    } catch (error) {
      console.error("Error registering admin:", error);
      toast({
        title: "Registration Failed",
        description: "An error occurred while trying to register the admin.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-left",
      });
    } finally {
      setIsRegistering(false);
    }
  };
  

  return (
    <Box bg="#4B4B4B" borderRadius="10px" p={4} color="white" w="100%">
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Text fontSize="md" fontWeight="bold">
          Admin Management
        </Text>
        <Tooltip label="Register New Admin" fontSize="12">
          <Button onClick={onOpen} colorScheme="white" variant="ghost" ml={4}>
            <AddIcon />
          </Button>
        </Tooltip>
      </Flex>
      <Flex mb={4}>
        <InputGroup>
          <Input
            placeholder="Search admin by email"
            backgroundColor="#4B4B4B"
            color="white"
            borderRadius="10px"
            width="500px"
            value={searchEmail}
            onChange={(e) => setSearchEmail(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleSearch();
            }}
          />
          <InputLeftElement
            children={<SearchIcon color="white" />}
            pointerEvents="none"
          />
        </InputGroup>
        <Box ml={4}>
          <Text
            as="button"
            bg="#00C6F7"
            color="white"
            borderRadius="10px"
            fontSize="12px"
            p={2}
            onClick={handleSearch}
          >
            {searchEmail.trim() === "" ? "Search" : "Clear"}
          </Text>
        </Box>
      </Flex>
      {loading ? (
        <Flex justifyContent="center" alignItems="center" h="200px">
          <ClipLoader color="#00C6F7" />
        </Flex>
      ) : (
        <Box maxH="400px" overflowY="auto">
          <Table variant="simple" colorScheme="whiteAlpha">
            <Thead
              css={{
                position: "sticky",
                top: 0,
                background: "#4B4B4B",
                zIndex: 1,
              }}
            >
              <Tr>
                <Th color="purple.200">Avatar</Th>
                <Th color="purple.200">Name</Th>
                <Th color="purple.200">Phone Number</Th>
                <Th color="purple.200">Email</Th>
                <Th color="purple.200">Date of Birth</Th>
                <Th color="purple.200">Role</Th>
                <Th color="purple.200">Details</Th>
              </Tr>
            </Thead>
            <Tbody>
              {admins.map((admin, index) => (
                <Tr fontSize="14px" _hover={{ bg: "#3B3B3B" }} key={index}>
                  <Td>
                    {admin.image ? (
                      <Avatar
                        borderRadius="full"
                        boxSize="40px"
                        src={admin.image}
                        alt={admin.name}
                      />
                    ) : (
                      <Center
                        borderRadius="full"
                        boxSize="40px"
                        bg="white"
                        color="black"
                        fontWeight="bold"
                      >
                        {getInitials(admin.name)}
                      </Center>
                    )}
                  </Td>
                  <Td>{admin.name}</Td>
                  <Td>{admin.phoneNumber}</Td>
                  <Td>{admin.email}</Td>
                  <Td>{admin.dob}</Td>
                  <Td>{admin.role}</Td>
                  <Td>
                    <Link
                      color="#00C6F7"
                      onClick={() => openDrawer(admin.fullDetails)}
                    >
                      Details
                    </Link>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}
      {selectedAdmin && (
        <AdminDetailsDrawer
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          admin={selectedAdmin}
        />
      )}

      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent bg="#4B4B4B" color="white">
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            Register New Admin
          </DrawerHeader>
          <DrawerBody>
            <VStack mb="50px" spacing={4}>
              <FormControl id="email" isRequired>
                <FormLabel>Email</FormLabel>
                <Input
                  bg="#4B4B4B"
                  color="white"
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                />
              </FormControl>
              <FormControl id="firstName" isRequired>
                <FormLabel>First Name</FormLabel>
                <Input
                  bg="#4B4B4B"
                  color="white"
                  value={formData.firstName}
                  onChange={(e) =>
                    setFormData({ ...formData, firstName: e.target.value })
                  }
                />
              </FormControl>
              <FormControl id="lastName" isRequired>
                <FormLabel>Last Name</FormLabel>
                <Input
                  bg="#4B4B4B"
                  color="white"
                  value={formData.lastName}
                  onChange={(e) =>
                    setFormData({ ...formData, lastName: e.target.value })
                  }
                />
              </FormControl>
              <FormControl id="phoneNumber" isRequired>
                <FormLabel>Phone Number</FormLabel>
                <Input
                  bg="#4B4B4B"
                  color="white"
                  value={formData.phoneNumber}
                  onChange={(e) =>
                    setFormData({ ...formData, phoneNumber: e.target.value })
                  }
                />
              </FormControl>
              <FormControl id="gender" isRequired>
                <FormLabel>Gender</FormLabel>
                <Select
                  color="#4B4B4B"
                  bg="white"
                  value={formData.gender}
                  onChange={(e) =>
                    setFormData({ ...formData, gender: e.target.value })
                  }
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </Select>
              </FormControl>
              <FormControl id="role" isRequired>
                <FormLabel>Role</FormLabel>
                <Select
                  color="#4B4B4B"
                  bg="white"
                  value={formData.role}
                  onChange={(e) =>
                    setFormData({ ...formData, role: e.target.value })
                  }
                >
                  <option value="">Select Role</option>
                  <option value="SUPER_ADMIN">SUPER ADMIN</option>
                  <option value="ADMIN">ADMIN</option>
                  <option value="ADMIN_SUPPORT">ADMIN SUPPORT</option>
                </Select>
              </FormControl>
            </VStack>
          </DrawerBody>
          <DrawerFooter borderTopWidth="1px">
            <Button
              colorScheme="red"
              variant="outline"
              mr={3}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleRegisterAdmin}
              isLoading={isRegistering}
              loadingText="Registering..."
            >
              Register
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default AdminManagement;
