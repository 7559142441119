import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import LeftSideBar from "../authLayouts/LeftSideBar";
import NavBar from "../authLayouts/NavBar";
import { useNavigate } from "react-router-dom";
import {
  ChakraProvider,
  VStack,
  Button,
  Box,
  Text,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  extendTheme,
} from "@chakra-ui/react";

import SettingsSideBar from "../authLayouts/SettingsSideBar";
import MobileFooter from "../authLayouts/MobileFooter";

const customTheme = extendTheme({
  components: {
    Link: {
      baseStyle: {
        _focus: {
          boxShadow: "none",
        },
      },
    },
  },
  fonts: {
    body: "Montserrat, sans-serif",
    heading: "Gill Sans MT, sans-serif",
  },
});

const HelpPage = () => {
  const navigate = useNavigate();
  const handleback = () => {
    navigate("/settings");
  };

  const settingsContainerStyle = {
    animation: "slideInUp 0.9s ease-in-out",
  };

  return (
    <ChakraProvider theme={customTheme}>
      <LeftSideBar />
      <VStack
        style={settingsContainerStyle}
        ml={{ md: "450px" }}
        height="100vh"
        w={{ base: "95%", md: "70vh" }}
      >
        <Box w="100%" position="sticky" top="0" zIndex="1000">
          <NavBar />
        </Box>

        <Box
          mb={{ base: "100px", md: "0" }}
          overflow="scroll"
          display={{ base: "block", md: "none" }}
          marginTop="20px"
          ml={{ md: "150px" }}
        >
          <Box>
            <VStack>
              <Box>
                <Flex justifyContent="space-between">
                  <Text
                    textAlign="left"
                    fontSize={{ base: "18px" }}
                    color="#A210C6"
                    fontFamily="heading"
                  >
                    Frequently Asked Questions
                  </Text>

                  <Button
                    onClick={handleback}
                    borderColor="#A210C6"
                    borderWidth="1px"
                    color="#A210C6"
                    fontFamily="body"
                    _hover={{ color: "" }}
                    fontSize={{ base: "12px" }}
                    h="3vh"
                    marginLeft="10px"
                    borderRadius="100px"
                  >
                    Back
                  </Button>
                </Flex>

                <Text fontFamily="body" fontStyle="italic" fontSize="12px">
                  Click on a question to see more details
                </Text>
              </Box>
              <Box marginLeft="15px">
                <Accordion allowToggle fontSize="10px" w={{ base: "95%" }}>
                  <AccordionItem
                    p={-6}
                    my={5}
                    fontSize="14px"
                    className="custom-accordion-item"
                  >
                    <h2>
                      <AccordionButton>
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          fontSize="14px"
                          fontFamily="body"
                        >
                          How do I add my loved ones as beneficiaries?
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      textAlign="justify"
                      fontSize="14px"
                      fontFamily="body"
                      className="custom-accordion-panel"
                    >
                      To add a loved one as a beneficiary, navigate to the
                      "Beneficiary" section from your dashboard and click on
                      "View All." From there, you'll see an option to add a new
                      beneficiary. Alternatively, you can add a beneficiary
                      while booking an appointment for "Others" by toggling the
                      "Add to Beneficiary List" switch.
                    </AccordionPanel>
                  </AccordionItem>

                  <AccordionItem
                    p={-6}
                    my={5}
                    fontSize="12px"
                    className="custom-accordion-item"
                  >
                    <h2>
                      <AccordionButton>
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          fontSize="14px"
                          fontFamily="body"
                        >
                          I entered the wrong details while booking my
                          appointment. How do I change it?
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      textAlign="justify"
                      fontSize="14px"
                      fontFamily="body"
                      className="custom-accordion-panel"
                    >
                      To edit the details of a pending appointment, go to the
                      "Appointments" page and click on the specific appointment
                      you'd like to update. Once you have the appointment
                      details in full view, you will find an "Edit" button.
                      However, if you need to edit details for an active or
                      completed appointment, please contact Admin Support for
                      assistance.
                    </AccordionPanel>
                  </AccordionItem>

                  <AccordionItem
                    p={-6}
                    my={5}
                    fontSize="14px"
                    className="custom-accordion-item"
                  >
                    <h2>
                      <AccordionButton>
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          fontSize="14px"
                          fontFamily="body"
                        >
                          I would like to book multiple services at the same
                          time. How do I do that?
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      textAlign="justify"
                      fontSize="14px"
                      fontFamily="body"
                      className="custom-accordion-panel"
                    >
                      To book multiple appointments, simply click the "Book
                      Appointment" button. From there, you can choose to book
                      for "Yourself", a "Beneficiary", or "Others". While there
                      is no limit to the number of appointments you can book,
                      each one must be booked individually. To manage your
                      appointments, visit the "Appointments" page.
                    </AccordionPanel>
                  </AccordionItem>

                  <AccordionItem
                    p={-6}
                    my={5}
                    fontSize="14px"
                    className="custom-accordion-item"
                  >
                    <h2>
                      <AccordionButton>
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          fontSize="14px"
                          fontFamily="body"
                        >
                          I am no longer interested in using this service. How
                          can I cancel?
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      textAlign="justify"
                      fontSize="14px"
                      fontFamily="body"
                      className="custom-accordion-panel"
                    >
                      To cancel an unpaid pending appointment, you can easily do
                      so directly. However, if the appointment has been paid
                      for, please contact the admin to request a cancellation.
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Box>
            </VStack>
            <VStack overflow="scroll" marginLeft="20px">
              <Box textAlign="left">
                <Text fontFamily="body" color="#A210C6" fontSize="20px">
                  Contact us
                </Text>
                <VStack textAlign="left" fontSize="14px">
                  <Text fontFamily="body">
                    If you have any issues, our Mikul Customer Care agents are
                    always happy to help. You can reach us via:
                  </Text>
                  <Text fontFamily="body">
                    Email:{" "}
                    <a
                      href="mailto:support@mikulhealth.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      support@mikulhealth.com
                    </a>{" "}
                    <br></br>
                    Phone: <a href="tel:+2349160596636">+2349160596636</a>
                  </Text>
                </VStack>
              </Box>
            </VStack>
          </Box>
        </Box>

        <Box
          ml="215px"
          display={{ base: "none", md: "block" }}
          marginTop="20px"
        >
          <SettingsSideBar />
          <Flex>
            <VStack marginLeft="150px">
              <Box marginTop="-370px">
                <Text fontFamily="heading" color="#A210C6" fontSize="24px">
                  Frequently Asked Questions
                </Text>
                <Text fontFamily="body" fontStyle="italic" fontSize="16px">
                  Click on a question to see more details
                </Text>
              </Box>
              <Box marginLeft="170px">
                <Accordion
                  allowToggle
                  w="500px"
                  data-aos="fade-down"
                  data-aos-duration="10000"
                >
                  <AccordionItem
                    p={-6}
                    my={5}
                    fontSize="14px"
                    className="custom-accordion-item"
                  >
                    <h2>
                      <AccordionButton>
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          fontSize="14px"
                          fontFamily="body"
                        >
                          How do I add my loved ones as beneficiaries?
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      textAlign="justify"
                      fontSize="14px"
                      fontFamily="body"
                      className="custom-accordion-panel"
                    >
                      To add a loved one as a beneficiary, navigate to the
                      "Beneficiary" section from your dashboard and click on
                      "View All." From there, you'll see an option to add a new
                      beneficiary. Alternatively, you can add a beneficiary
                      while booking an appointment for "Others" by toggling the
                      "Add to Beneficiary List" switch.
                    </AccordionPanel>
                  </AccordionItem>

                  <AccordionItem
                    p={-6}
                    my={5}
                    fontSize="12px"
                    className="custom-accordion-item"
                  >
                    <h2>
                      <AccordionButton>
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          fontSize="14px"
                          fontFamily="body"
                        >
                          I entered the wrong details while booking my
                          appointment. How do I change it?
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      textAlign="justify"
                      fontSize="14px"
                      fontFamily="body"
                      className="custom-accordion-panel"
                    >
                      To edit the details of a pending appointment, go to the
                      "Appointments" page and click on the specific appointment
                      you'd like to update. Once you have the appointment
                      details in full view, you will find an "Edit" button.
                      However, if you need to edit details for an active or
                      completed appointment, please contact Admin Support for
                      assistance.
                    </AccordionPanel>
                  </AccordionItem>

                  <AccordionItem
                    p={-6}
                    my={5}
                    fontSize="14px"
                    className="custom-accordion-item"
                  >
                    <h2>
                      <AccordionButton>
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          fontFamily="body"
                          fontSize="14px"
                        >
                          I would like to book multiple services at the same
                          time. How do I do that?
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      textAlign="justify"
                      fontFamily="body"
                      fontSize="14px"
                      className="custom-accordion-panel"
                    >
                      To book multiple appointments, simply click the "Book
                      Appointment" button. From there, you can choose to book
                      for "Yourself", a "Beneficiary", or "Others". While there
                      is no limit to the number of appointments you can book,
                      each one must be booked individually. To manage your
                      appointments, visit the "Appointments" page.
                    </AccordionPanel>
                  </AccordionItem>

                  <AccordionItem
                    p={-6}
                    my={5}
                    fontSize="14px"
                    className="custom-accordion-item"
                  >
                    <h2>
                      <AccordionButton>
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          fontSize="14px"
                          fontFamily="body"
                        >
                          I am no longer interested in using this service. How
                          can I cancel?
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      textAlign="justify"
                      fontSize="14px"
                      fontFamily="body"
                      className="custom-accordion-panel"
                    >
                      To cancel an unpaid pending appointment, you can easily do
                      so directly. However, if the appointment has been paid
                      for, please contact the admin to request a cancellation.
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Box>
            </VStack>
            <VStack marginLeft="50px" marginTop="-285px">
              <Box textAlign="left">
                <Text fontFamily="body" color="#A210C6" fontSize="20px">
                  Contact us
                </Text>
                <VStack fontSize="14px">
                  <Text fontFamily="body">
                    If you have any issues, our Mikul Customer
                  </Text>
                  <Text fontFamily="body">
                    Care agents are always happy to help. You can reach us via:
                  </Text>

                  <Text fontFamily="body">
                    Email:
                    <a
                      href="mailto:support@mikulhealth.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      support@mikulhealth.com
                    </a>{" "}
                    <br></br>
                    Phone: <a href="tel:+2349160596636">+2349160596636</a>
                  </Text>
                </VStack>
              </Box>
            </VStack>
          </Flex>
        </Box>
        <MobileFooter />
      </VStack>
    </ChakraProvider>
  );
};
export default HelpPage;
