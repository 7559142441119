import React, { useState } from "react";
import { Box, Flex, Text, VStack, Button, IconButton } from "@chakra-ui/react";
import { CopyIcon, CheckIcon } from "@chakra-ui/icons";
import { FaEye, FaEyeSlash } from "react-icons/fa"; 

const WalletSummaryBox = ({
  walletCreated,
  balance,
  formatAmount,
  accountName,
  accountNumber,
  hasCopied,
  onCopy,
  handleOpenFundWalletModal,
  onOpen,
  walletTotalCredit,
  walletTotalDebit,
}) => {
  const [showBalance, setShowBalance] = useState(false); 

  const toggleBalanceVisibility = () => setShowBalance(!showBalance); 

  return (
    <Box
      textAlign="center"
      w={{ base: "375px", md: "910px" }}
      h={{ base: "150px", md: "200px" }}
      mt={{ base: "10px", md: "0" }}
      mb={{ base: "10px", md: "30" }}
      paddingBottom={{ base: "20px", md: "" }}
      bg="linear-gradient(80deg, #A210C6, #E552FF)"
      borderRadius="20px"
    >
      <Flex w={{ base: "90vw", md: "80vw" }}>
        <Box ml={{ base: "20px", md: "40px" }} pt={{ base: "px", md: "8px" }}>
          <Text fontSize="16px" fontFamily="body" color="white" marginTop="20px">
            Mikul Health Wallet
          </Text>
          <Flex alignItems="center">
            <Text
              marginTop="2px"
              color="white"
              fontSize={{ base: "18px", md: "22px" }}
              textAlign="left"
            >
              ₦ {walletCreated ? (showBalance ? formatAmount(balance) : "******") : "__.__"}
            </Text>
            {walletCreated && (
              <IconButton
                aria-label="Toggle Balance Visibility"
                icon={showBalance ? <FaEyeSlash /> : <FaEye />}
                onClick={toggleBalanceVisibility}
                ml={2}
                size="sm"
                bg="transparent"
                color="white"
              />
            )}
          </Flex>
        </Box>
        <VStack pt={{ base: "5px", md: "15px" }}>
          <Button
            padding={{ base: "5px", md: "0" }}
            ml={{ base: "75px", md: "500px" }}
            w={{ base: "100px", md: "35%" }}
            h={{ base: "30px", md: "50%" }}
            fontSize={{ base: "12px", md: "16px" }}
            borderRadius="15px"
            color="#A210C6"
            marginTop="20px"
            onClick={walletCreated ? handleOpenFundWalletModal : onOpen}
            bg="white"
          >
            {walletCreated ? "Send funds" : "Create Wallet"}
          </Button>
        </VStack>
      </Flex>
      {walletCreated && (
        <Flex ml={{ base: "20px", md: "40px" }} mt={{ base: "30px", md: "50px" }}>
          <Box fontWeight="bold" marginBottom={{ base: "50px", md: "50px" }} color="white">
            <Text textAlign="left" fontSize={{ base: "10px", md: "16px" }}>
              Wallet ID:
            </Text>
            <Flex>
              <Text
                textAlign="left"
                fontSize={{ base: "10px", md: "16px" }}
                fontStyle={
                  !walletCreated || !accountName || !accountNumber
                    ? "italic"
                    : "normal"
                }
              >
                {walletCreated && accountName && accountNumber
                  ? accountName
                  : "Processing, please wait..."}
              </Text>
              <Text
                ml="10px"
                textAlign="left"
                fontSize={{ base: "10px", md: "16px" }}
                fontStyle={
                  !walletCreated || !accountName || !accountNumber
                    ? "italic"
                    : "normal"
                }
              >
                {walletCreated && accountName && accountNumber
                  ? accountNumber
                  : ""}
              </Text>
              {walletCreated && accountName && accountNumber && (
                <IconButton
                  icon={hasCopied ? <CheckIcon /> : <CopyIcon />}
                  onClick={onCopy}
                  mt="-5px"
                  size="sm"
                  aria-label="Copy account number"
                  color="white"
                  bg={hasCopied ? "#A210C6" : "transparent"}
                  _hover={{ bg: "transparent" }}
                />
              )}
            </Flex>
          </Box>

          <Flex marginLeft={{ base: "50px", md: "400px" }}>
            {/* <Box color="white">
              <Text textAlign="left" fontSize="10px">
                Total Made
              </Text>
              <Text textAlign="left" color="white" fontSize="10px">
                ₦ {formatAmount(walletTotalCredit)}
              </Text>
            </Box>
            <Box color="white" marginLeft="10px">
              <Text textAlign="left" fontSize="10px">
                Total Withdraw
              </Text>
              <Text textAlign="left" color="white" fontSize="10px">
                ₦ {formatAmount(walletTotalDebit)}
              </Text>
            </Box> */}
          </Flex>
        </Flex>
      )}
    </Box>
  );
};

export default WalletSummaryBox;
