import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  InputGroup,
  InputLeftElement,
  Th,
  Td,
} from "@chakra-ui/react";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { SearchIcon } from "@chakra-ui/icons";
import PatientDetailsDrawer from "../../sections/admin/PatientDetailsDrawer";
import { baseUrl } from "../../../apiCalls/config";

const PatientsManagement = () => {
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [hasSearched, setHasSearched] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    fetchBeneficiaries();
  }, []);

  const fetchBeneficiaries = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, redirecting to login.");
      window.location.href = "/login";
      return;
    }

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${baseUrl}/api/admin/beneficiaries`,
        config
      );
      const data = response.data.data
        .map((item) => ({
          id: item.policyNumber,
          name: `${item.recipientFirstName} ${item.recipientLastName}`,
          admit: new Date(item.createdAt).toLocaleDateString(),
          type: item.id,
          status: item.saved ? "Saved" : "Not Saved",
          statusColor: item.saved ? "green.200" : "red.300",
          details: item,
          createdAt: new Date(item.createdAt),
        }))
        .sort((a, b) => b.createdAt - a.createdAt);
      setBeneficiaries(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching beneficiaries:", error);
      setLoading(false);
    }
  };

  const handleSearch = async () => {
    if (hasSearched) {
      setSearchQuery("");
      setHasSearched(false);
      fetchBeneficiaries();
    } else {
      if (searchQuery.trim() === "") return;

      setLoading(true);
      try {
        const token = localStorage.getItem("token");
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        let response;

        // Determine if the search term is a policy number (e.g., MH0001) or a recipient name
        if (/^MH\d+$/i.test(searchQuery)) {
          // Search by policy number if the search term matches the pattern 'MH' followed by digits
          response = await axios.get(
            `${baseUrl}/api/admin/searchBeneficiaryByPolicyNumber?policyNumber=${searchQuery}`,
            config
          );

          // If the response contains a single beneficiary, convert it to an array
          if (response.data && response.data.data) {
            const data = response.data.data;
            const beneficiary = {
              id: data.policyNumber,
              name: `${data.recipientFirstName} ${data.recipientLastName}`,
              admit: new Date(data.createdAt).toLocaleDateString(),
              type: data.id,
              status: data.saved ? "Saved" : "Not Saved",
              statusColor: data.saved ? "green.200" : "red.300",
              details: data,
              createdAt: new Date(data.createdAt),
            };
            setBeneficiaries([beneficiary]);
            setHasSearched(true);
            setLoading(false);
          }
        } else {
          // Search by recipient name
          const [firstName, lastName] = searchQuery.split(" ");
          response = await axios.get(
            `${baseUrl}/api/admin/beneficiaries/by-recipientName`,
            {
              params: {
                firstName: firstName || "",
                lastName: lastName || "",
              },
              ...config,
            }
          );

          if (response.data && response.data.data) {
            const data = response.data.data.map((item) => ({
              id: item.policyNumber,
              name: `${item.recipientFirstName} ${item.recipientLastName}`,
              admit: new Date(item.createdAt).toLocaleDateString(),
              type: item.id,
              status: item.saved ? "Saved" : "Not Saved",
              statusColor: item.saved ? "green.200" : "red.300",
              details: item,
              createdAt: new Date(item.createdAt),
            }));
            setBeneficiaries(data);
            setHasSearched(true);
            setLoading(false);
          }
        }
      } catch (error) {
        console.error("Error searching beneficiaries:", error);
        setLoading(false);
      }
    }
  };

  const handleInputChange = async (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    if (query.trim() === "") {
      // If the search query is cleared, fetch the full list
      setHasSearched(false);
      fetchBeneficiaries();
    }
  };

  const openDrawer = (patient) => {
    setSelectedPatient(patient);
    setIsDrawerOpen(true);
  };

  const handleRowClick = (beneficiary) => {
    openDrawer(beneficiary.details);
  };

  return (
    <Box bg="#4B4B4B" borderRadius="10px" p={4} color="white" w="100%">
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Text fontSize="md" fontWeight="bold">
          Patients Management
        </Text>
      </Flex>
      <Flex mb={4}>
        <InputGroup>
          <Input
            placeholder="Search patients by name, policy number"
            backgroundColor="#4B4B4B"
            color="white"
            borderRadius="10px"
            width="500px"
            value={searchQuery}
            onChange={handleInputChange} 
            onKeyDown={(e) => {
              if (e.key === "Enter") handleSearch(); 
            }}
          />
          <InputLeftElement
            children={<SearchIcon color="white" />}
            pointerEvents="none"
          />
        </InputGroup>
        <Box ml={4}>
          <Text
            as="button"
            bg="#00C6F7"
            color="white"
            borderRadius="10px"
            fontSize="12px"
            p={2}
            onClick={handleSearch}
          >
            {hasSearched ? "Clear" : "Search"}
          </Text>
        </Box>
      </Flex>
      {loading ? (
        <Flex justifyContent="center" alignItems="center" h="200px">
          <ClipLoader color="#00C6F7" />
        </Flex>
      ) : (
        <Box maxH="400px" overflowY="auto">
          <Table variant="simple" colorScheme="whiteAlpha">
            <Thead
              css={{
                position: "sticky",
                top: 0,
                background: "#4B4B4B",
                zIndex: 1,
              }}
            >
              <Tr>
                <Th color="purple.200">MH Policy Number</Th>
                <Th color="purple.200">Name</Th>
                <Th color="purple.200">Created on</Th>
                <Th color="purple.200">Appointment ID</Th>
                <Th color="purple.200">Status</Th>
              </Tr>
            </Thead>
            <Tbody>
              {beneficiaries.map((beneficiary, index) => (
                <Tr
                  key={index}
                  onClick={() => handleRowClick(beneficiary)}
                  cursor="pointer"
                  fontSize="13px"
                  _hover={{ bg: "#3B3B3B" }}
                >
                  <Td>{beneficiary.id}</Td>
                  <Td>{beneficiary.name}</Td>
                  <Td>{beneficiary.admit}</Td>
                  <Td>{beneficiary.type}</Td>
                  <Td color={beneficiary.statusColor}>{beneficiary.status}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}
      {selectedPatient && (
        <PatientDetailsDrawer
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          patient={selectedPatient}
        />
      )}
    </Box>
  );
};

export default PatientsManagement;
