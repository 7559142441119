import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Checkbox,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Input,
} from "@chakra-ui/react";
import { ChevronDownIcon, ArrowUpIcon, ArrowDownIcon } from "@chakra-ui/icons";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TransactionDetailsDrawer from "./TransactionDetailsDrawer";
import { baseUrl } from "../../../apiCalls/config";

const AllTransactions = () => {
  const [transactionData, setTransactionData] = useState([]);
  const [originalTransactionData, setOriginalTransactionData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [filterType, setFilterType] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    fetchAllTransactions();
  }, []);

  const fetchAllTransactions = async () => {
    setLoading(true);
  
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, redirecting to login.");
      window.location.href = "/login";
      return;
    }
  
    try {
      const response = await axios.get(
        `${baseUrl}/api/admin/transactions`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.data.success) {
        const sortedData = response.data.data.sort(
          (a, b) => new Date(b.transactionDate) - new Date(a.transactionDate)
        );
        setTransactionData(sortedData);
        setOriginalTransactionData(sortedData); 
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  
  const handleSelectTransaction = (transactionId) => {
    if (selectedTransactions.includes(transactionId)) {
      setSelectedTransactions(
        selectedTransactions.filter((id) => id !== transactionId)
      );
    } else {
      setSelectedTransactions([...selectedTransactions, transactionId]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedTransactions([]);
    } else {
      setSelectedTransactions(
        transactionData.map((transaction) => transaction.id)
      );
    }
    setSelectAll(!selectAll);
  };

  const handleSendReceipts = () => {
    alert(`Sending receipts: ${selectedTransactions.join(", ")}`);
  };

  const handleExportReceipts = () => {
    alert(`Exporting receipts: ${selectedTransactions.join(", ")}`);
  };

  const openDrawer = (transaction) => {
    setSelectedTransaction(transaction);
    setIsDrawerOpen(true);
  };

  const handleFilterChange = async (filterType) => {
    setFilterType(filterType);

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, redirecting to login.");
      window.location.href = "/login";
      return;
    }

    setLoading(true);
    try {
      let response;

      if (filterType === "date") {
        const formattedDate = selectedDate.toISOString().split("T")[0];
        response = await axios.get(
          `${baseUrl}/api/admin/transactions/search?date=${formattedDate}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else if (filterType === "type") {
        response = await axios.get(
          `${baseUrl}/api/admin/transactions/by-type?type=${searchQuery}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else if (filterType === "status") {
        response = await axios.get(
          `${baseUrl}/api/admin/transactions/by-status?status=${searchQuery}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      if (response.data.success) {
        const sortedData = response.data.data.sort(
          (a, b) => new Date(b.transactionDate) - new Date(a.transactionDate)
        );
        setTransactionData(sortedData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClearSearch = () => {
    setFilterType(null); // Reset filter type
    setSearchQuery(""); // Clear search query
    setSelectedDate(new Date()); // Reset date picker
    setTransactionData(originalTransactionData); // Restore original transaction data
  };

  const formatType = (type) => {
    if (!type) return "N/A";
    return type.toLowerCase();
  };

  const getTypeColor = (type) => {
    if (!type) return "white";
    return type.toLowerCase() === "credit" ? "green.200" : "red.300";
  };

  const formatStatus = (status) => {
    if (!status) return "N/A";
    const lowerCaseStatus = status.toLowerCase();
    return lowerCaseStatus.charAt(0).toUpperCase() + lowerCaseStatus.slice(1);
  };

  const getStatusColor = (status) => {
    if (!status) return "white";
    switch (status.toLowerCase()) {
      case "pending":
        return "yellow.200";
      case "completed":
      case "success":
        return "green.200";
      case "failed":
        return "red.300";
      default:
        return "white";
    }
  };

  const formatAmount = (amount) => {
    const num = Number(amount);
    return num.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };
  

  return (
    <Box bg="#4B4B4B" borderRadius="10px" p={4} color="white" w="100%">
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Text fontSize="md" fontWeight="bold">
          All Transactions
        </Text>
        <Menu>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />} zIndex="10">
            Advanced Filter
          </MenuButton>
          <MenuList color="#4B4B4B" zIndex="20">
            <MenuItem onClick={() => setFilterType("date")}>Filter by Date</MenuItem>
            <MenuItem onClick={() => setFilterType("type")}>Filter by Type</MenuItem>
            <MenuItem onClick={() => setFilterType("status")}>Filter by Status</MenuItem>
          </MenuList>
        </Menu>
      </Flex>

      {filterType === "date" && (
        <Flex mb={4}>
          <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            dateFormat="yyyy-MM-dd"
          />
          <Button ml={2} onClick={() => handleFilterChange("date")} colorScheme="blue">
            Search by Date
          </Button>
          <Button ml={2} onClick={handleClearSearch} colorScheme="gray">
            Clear
          </Button>
        </Flex>
      )}

      {filterType === "type" && (
        <Flex mb={4}>
          <Input
            placeholder="Enter type (debit/credit)"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleFilterChange("type");
            }}
            maxW="200px"
            bg="white"
            color="black"
            borderRadius="md"
          />
          <Button ml={2} onClick={() => handleFilterChange("type")} colorScheme="blue">
            Search by Type
          </Button>
          <Button ml={2} onClick={handleClearSearch} colorScheme="gray">
            Clear
          </Button>
        </Flex>
      )}

      {filterType === "status" && (
        <Flex mb={4}>
          <Input
            placeholder="Enter status (Pending/Success/Failed)"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleFilterChange("status");
            }}
            maxW="310px"
            bg="white"
            color="black"
            borderRadius="md"
          />
          <Button ml={2} onClick={() => handleFilterChange("status")} colorScheme="blue">
            Search by Status
          </Button>
          <Button ml={2} onClick={handleClearSearch} colorScheme="gray">
            Clear
          </Button>
        </Flex>
      )}

      {selectedTransactions.length > 0 && (
        <Flex justifyContent="flex-start" mb={4}>
          <Button colorScheme="blue" mr={2} onClick={handleSendReceipts}>
            Send Selected Receipt(s)
          </Button>
          <Button colorScheme="green" onClick={handleExportReceipts}>
            Export Selected Receipt(s)
          </Button>
        </Flex>
      )}

      {loading ? (
        <Flex justifyContent="center" alignItems="center" h="200px">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <Box maxH="400px" overflowY="auto">
          <Table variant="simple" colorScheme="whiteAlpha">
            <Thead
              css={{
                position: "sticky",
                top: 0,
                background: "#4B4B4B",
                zIndex: 1,
              }}
            >
              <Tr>
                <Th color="#A210C6">
                  <Checkbox
                    colorScheme="blue"
                    isChecked={selectAll}
                    onChange={handleSelectAll}
                  />
                </Th>
                <Th color="purple.200">Type</Th>
                <Th color="purple.200">Amount</Th>
                <Th color="purple.200">Status</Th>
                <Th color="purple.200">Transaction Date</Th>
                <Th color="purple.200">Details</Th>
              </Tr>
            </Thead>
            <Tbody>
              {transactionData.map((transaction) => (
                <Tr
                  fontSize="13px"
                  key={transaction.id}
                  _hover={{ bg: "#3B3B3B" }}
                >
                  <Td>
                    <Checkbox
                      colorScheme="blue"
                      isChecked={selectedTransactions.includes(transaction.id)}
                      onChange={(e) => {
                        e.stopPropagation();
                        handleSelectTransaction(transaction.id);
                      }}
                    />
                  </Td>
                  <Td color={getTypeColor(transaction.type)}>
                    {transaction.type === "credit" ? (
                      <>
                        <ArrowDownIcon /> {formatType(transaction.type)}
                      </>
                    ) : (
                      <>
                        <ArrowUpIcon /> {formatType(transaction.type)}
                      </>
                    )}
                  </Td>
                  <Td>₦{formatAmount(transaction.amount)}</Td>
                  <Td color={getStatusColor(transaction.status)}>
                    {formatStatus(transaction.status)}
                  </Td>
                  <Td>
                    {transaction.transactionDate
                      ? new Date(transaction.transactionDate).toLocaleString()
                      : "N/A"}
                  </Td>
                  <Td>
                    <Button
                      size="sm"
                      colorScheme="blue"
                      onClick={() => openDrawer(transaction)}
                    >
                      View
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}
      <TransactionDetailsDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        transaction={selectedTransaction}
      />
    </Box>
  );
};

export default AllTransactions;
