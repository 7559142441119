import React, { useState, useEffect } from "react";
import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Box,
  Flex,
  Progress,
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { baseUrl } from "../../apiCalls/config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppointmentDetails, AppointmentList, MedicDetailsDrawer } from "../authLayouts/AppointmentsComponents";

const SearchAppointmentsModal = ({ isOpen, onClose }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noAppointmentsFound, setNoAppointmentsFound] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [searchTrigger, setSearchTrigger] = useState(false);
  const [medicDetailsOpen, setMedicDetailsOpen] = useState(false);

  const formatDateToUTC = (selectedDate) => {
    if (!selectedDate) return "";

    // Add one day to the selected date
    const adjustedDate = new Date(selectedDate);
    adjustedDate.setDate(adjustedDate.getDate() + 1);

    return adjustedDate.toISOString().split("T")[0];
  };

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        if (!searchTrigger) return;

        const token = localStorage.getItem("token");
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(
   

        `${baseUrl}/appointment/customerAppointmentsByDate?date=${formatDateToUTC(
            selectedDate
          )}`,
          config
        );

        if (response.data.success) {
          toast.success(response.data.message);
          setAppointments(response.data.data);
          setNoAppointmentsFound(response.data.data.length === 0);
        } else {
          toast.success(response.data.message);
          setAppointments([]);
          console.error("Failed to fetch appointments");
        }
      } catch (error) {
        console.error("Error fetching appointments:", error);
      } finally {
        setLoading(false);
        setSearchTrigger(false);
      }
    };

    if (searchTrigger) {
      setLoading(true);
      fetchAppointments();
    }
  }, [selectedDate, searchTrigger, isOpen]);

 
  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSearchTrigger(true);
  };

  const handleViewMore = (appointment) => {
    setSelectedAppointment(appointment);
    setDetailsModalOpen(true);
  };

  const handleViewMedicDetails = () => {
    setMedicDetailsOpen(true);
  };

  const closeMedicDetailsDrawer = () => {
    setMedicDetailsOpen(false);
  };


  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setSelectedDate(null);
          setAppointments([]);
        }}
        size={{base:"sm", md: "4xl"}}
        // w={{base: "70%", md: ""}}
        blockScrollOnMount={false}
        isCentered
      >
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <ModalOverlay />
        <ModalContent maxH={{base: "400px", md: "500px"}} overflow="auto">
          <ModalHeader color="#A210C6">Search appointments</ModalHeader>
          <ModalCloseButton />
          <ModalBody >
            {loading ? (
              <Progress size="xs" isIndeterminate />
            ) : (
              <Box mb="40px">
                <DatePicker
                  _hover={{ color: "#A210C6" }}
                  selected={selectedDate}
                  onChange={handleDateChange}
                  maxDate={new Date()}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  dateFormat="dd-MM-yyyy"
                  placeholderText="Select a date"
                  className="form-control"
                  
                />

                <Flex justifyContent="center" mt="20px">
                  {noAppointmentsFound ? (
                    <Text>No appointments found for the selected date.</Text>
                  ) : (

                    <AppointmentList
                    appointments={appointments}
                    handleViewMore={handleViewMore}
                  />
                 
                  )}
                </Flex>
              </Box>
            )}
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      {detailsModalOpen && selectedAppointment && (
        <AppointmentDetails
          isOpen={detailsModalOpen}
          onClose={() => {
            setDetailsModalOpen(false);
            handleDateChange(null);
          }}
          appointment={selectedAppointment}
          handleViewMedicDetails={handleViewMedicDetails}
        />
      )}
       {selectedAppointment && selectedAppointment.matchedMedic && (
        <MedicDetailsDrawer
          isOpen={medicDetailsOpen}
          onClose={closeMedicDetailsDrawer}
          medic={selectedAppointment.matchedMedic}
        />
      )}
    </>
  );
};

export default SearchAppointmentsModal;
