import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import axios from "axios";
import { baseUrl } from "../../../apiCalls/config";
import RecordPaymentModal from "../../sections/admin/RecordPaymentModal "; 

const UnpaidAppointments = () => {
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedAppointment, setSelectedAppointment] = useState(null); // State to hold selected appointment
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility

  useEffect(() => {
    fetchUnpaidAppointments();
  }, []);

  const fetchUnpaidAppointments = async () => {
    setLoading(true);

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, redirecting to login.");
      window.location.href = "/login";
      return;
    }

    try {
      const response = await axios.get(`${baseUrl}/api/admin/appointments/unpaid`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.success) {
        setAppointments(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const formatAmount = (amount) => {
    const num = Number(amount);
    return num.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  // Handle row click to open modal and pass appointment ID
  const handleRowClick = (appointment) => {
    setSelectedAppointment(appointment); // Set selected appointment
    setIsModalOpen(true); // Open modal
  };

  // Close modal handler
  const handleCloseModal = () => {
    setIsModalOpen(false); // Close modal
    setSelectedAppointment(null); // Clear selected appointment
  };

  return (
    <Box bg="#4B4B4B" borderRadius="10px" p={4} color="white" w="100%">
      <Text textAlign="left" fontSize="md" fontWeight="bold">
        Unpaid Appointments
      </Text>

      {loading ? (
        <Flex justifyContent="center" alignItems="center" h="200px">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <Box maxH="400px" overflowY="auto">
          <Table variant="simple" colorScheme="whiteAlpha">
            <Thead
              css={{
                position: "sticky",
                top: 0,
                background: "#4B4B4B",
                zIndex: 1,
              }}
            >
              <Tr>
                <Th color="purple.200">Customer Name</Th>
                <Th color="purple.200">Amount</Th>
                <Th color="purple.200">Service Plan</Th>
                <Th color="purple.200">Appointment ID</Th>
                <Th color="purple.200">Start Date</Th>
                <Th color="purple.200">Booked on</Th>
              </Tr>
            </Thead>
            <Tbody>
              {appointments.map((appointment) => (
                <Tr
                  fontSize="13px"
                  key={appointment.id}
                  _hover={{ bg: "#3B3B3B" }}
                  onClick={() => handleRowClick(appointment)} // Add click handler
                  cursor="pointer" // Change cursor to pointer
                >
                  <Td>{`${appointment?.customerName || "N/A"}`}</Td>
                  <Td>₦{formatAmount(appointment.costOfService)}</Td>
                  <Td>{appointment.servicePlan}</Td>
                  <Td>{appointment.id}</Td>
                  <Td>
                    {appointment.startDate
                      ? new Date(appointment.startDate).toLocaleString()
                      : "N/A"}
                  </Td>
                  <Td>
                    {appointment.createdAt
                      ? new Date(appointment.createdAt).toLocaleString()
                      : "N/A"}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}

      {/* Record Payment Modal */}
      {selectedAppointment && (
        <RecordPaymentModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          appointmentId={selectedAppointment.id} // Pass appointment ID to the modal
        />
      )}
    </Box>
  );
};

export default UnpaidAppointments;
