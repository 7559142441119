import React, { useState } from "react";
import { UpdateCustomer } from "../../apiCalls/UserApis";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { FaCamera } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  ChakraProvider,
  VStack,
  Input,
  Icon,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Avatar,
  Image,
  Box,
  Text,
  Flex,
  FormControl,
  extendTheme,
  FormLabel,
  Select,
  Divider,
  FormErrorMessage,
} from "@chakra-ui/react";
import LoadingSpinner from "../../utils/Spiner";
import MedicMobileFooter from "../authLayouts/MedicFooter";
import MedicSettingsSideBar from "../authLayouts/MedicSettingsSidebar";
import MedicNavBar from "../authLayouts/MedicNavBar";
import MedicSideBar from "../authLayouts/MedicSideBar";
import MedicPhone from "../sections/UpdateMedicPhone";

const customTheme = extendTheme({
  components: {
    Link: {
      baseStyle: {
        _focus: {
          boxShadow: "none",
        },
      },
    },
  },
  fonts: {
    body: "Montserrat, sans-serif",
    heading: "Gill Sans MT, sans-serif",
  },
});

const MedicEdithProfilePage = () => {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userReducer);
  const [isPhoneModalOpen, setPhoneModalOpen] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [image] = useState();
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [cvCopy, setCvCopy] = useState();
  const [license, setLicense] = useState();
  const [cvLoading, setCvLoading] = useState(false);
  const [licenseLoading, setLicenseLoading] = useState(false);
  const inputRef = React.useRef();

  const handleClick = () => {
    inputRef.current.click();
  };

  const maritalStatuses = ["Single", "Married", "Divorced", "Widowed"];

  const [formData, setFormData] = useState({
    firstName: user?.firstName,
    lastName: user?.lastName,
    dob: user?.dob.split("T")[0],
    email: user?.email,
    phoneNumber: user?.phoneNumber,
    address: user?.address,
    gender: user?.gender,
    maritalStatus: user?.maritalStatus,
    spouseFullName: user?.spouseFullName,
    spousePhoneNumber: user?.spousePhoneNumber,
    spouseEmail: user?.spouseEmail,
    spouseAddress: user?.spouseAddress,
    spouseHomeAddress: user?.spouseHomeAddress,
    guarantorFirstName: user?.guarantorFirstName,
    guarantorLastName: user?.guarantorLastName,
    guarantorPhone: user?.guarantorPhone,
    guarantorEmail: user?.guarantorEmail,
    guarantorHomeAddress: user?.guarantorHomeAddress,
    relationshipWithGuarantor: user?.relationshipWithGuarantor,
    lastEmployerFullName: user?.lastEmployerFullName,
    lastEmployerPhoneNumber: user?.lastEmployerPhoneNumber,
    lastEmployerEmail: user?.lastEmployerEmail,
    lastEmployerAddress: user?.lastEmployerAddress,
    image: user?.image,
    medicHomeAddress: user?.medicHomeAddress,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    // Handle email validation in real-time
    if (name === "guarantorEmail" || name === "lastEmployerEmail") {
      if (!validateEmail(value)) {
        setEmailErrors((prevErrors) => ({
          ...prevErrors,
          [`${name}Error`]: "Invalid email format",
        }));
      } else {
        setEmailErrors((prevErrors) => ({
          ...prevErrors,
          [`${name}Error`]: "",
        }));
      }
    }
  };

  const handleDOBChange = (e) => {
    const dateValue = e.target.value;
    setFormData({ ...formData, dob: dateValue });
  };

  const handleOpenConfirmationModal = () => {
    setConfirmationModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };

  const handlePhoneModalOpen = () => {
    setPhoneModalOpen(true);
  };

  const handlePhoneModalClose = () => {
    setPhoneModalOpen(false);
  };

  const postLicense = async (license, formData, setFormData) => {
    setLicenseLoading(true);
    if (license === undefined) {
      setLicenseLoading(false);
      return;
    }
    if (
      license.type === "image/jpeg" ||
      license.type === "image/png" ||
      license.type === "application/pdf"
    ) {
      const data = new FormData();
      data.append("file", license);
      data.append("upload_preset", "license");
      data.append("cloud_name", "dmfewrwla");

      try {
        const response = await fetch(
          "https://api.cloudinary.com/v1_1/dmfewrwla/image/upload",
          {
            method: "post",
            body: data,
          }
        );

        const imageData = await response.json();

        setFormData({
          ...formData,
          license: imageData.url.toString(),
        });
        setLicenseLoading(false);
      } catch (err) {
        console.log(err);
        setLicenseLoading(false);
      }
    } else {
      setLicenseLoading(false);
      return;
    }
  };

  const postCv = async (cvCopy, formData, setFormData) => {
    setCvLoading(true);
    if (cvCopy === undefined) {
      setCvLoading(false);
      return;
    }
    if (
      cvCopy.type === "image/jpeg" ||
      cvCopy.type === "image/png" ||
      cvCopy.type === "application/pdf"
    ) {
      const data = new FormData();
      data.append("file", cvCopy);
      data.append("upload_preset", "medicCv");
      data.append("cloud_name", "dmfewrwla");

      try {
        const response = await fetch(
          "https://api.cloudinary.com/v1_1/dmfewrwla/image/upload",
          {
            method: "post",
            body: data,
          }
        );

        const imageData = await response.json();

        setFormData({
          ...formData,
          cvCopy: imageData.url.toString(),
        });
        setCvLoading(false);
      } catch (err) {
        console.log(err);
        setCvLoading(false);
      }
    } else {
      setCvLoading(false);
      return;
    }
  };

  const [emailErrors, setEmailErrors] = useState({
    guarantorEmailError: "",
    lastEmployerEmailError: "",
  });

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleImageChange = async (image, formData, setFormData) => {
    setImageLoading(true);

    if (image === undefined) {
      // toast.error("Please select an image")
      return;
    }

    console.log(image);

    if (image.type === "image/jpeg" || image.type === "image/png") {
      const data = new FormData();
      data.append("file", image);
      data.append("upload_preset", "profileImage");
      data.append("cloud_name", "dmfewrwla");

      try {
        const response = await fetch(
          "https://api.cloudinary.com/v1_1/dmfewrwla/image/upload",
          {
            method: "post",
            body: data,
          }
        );

        const imageData = await response.json();

        setFormData({
          ...formData,
          image: imageData.url.toString(),
        });
        setImageLoading(false);
        console.log(imageData.url.toString());
      } catch (err) {
        console.log(err);
        setImageLoading(false);
      }
    } else {
      return;
    }
  };

  const formatDateToUTC = (selectedDate) => {
    if (!selectedDate) return "";

    if (isNaN(new Date(selectedDate))) {
      console.error("Invalid date:", selectedDate);
      return "";
    }

    const adjustedDate = new Date(selectedDate);
    adjustedDate.setDate(adjustedDate.getDate() + 1);

    return adjustedDate.toISOString().split("T")[0];
  };

  const handleSubmit = async () => {
    handleCloseConfirmationModal();
    if (
      !validateEmail(formData.guarantorEmail) ||
      !validateEmail(formData.lastEmployerEmail)
    ) {
      toast({
        title: "Validation Error",
        description: "Please provide valid email addresses.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }
    setLoading(true);
    try {
      await handleImageChange(image, formData, setFormData);

      setLoading(true);
      await postCv(cvCopy, formData, setFormData);
      await postLicense(license, formData, setFormData);

      const formatDateWithDayAdjustment = (selectedDate) =>
        formatDateToUTC(new Date(selectedDate));

      const formDataWithDate = {
        ...formData,
        startDate: formatDateWithDayAdjustment(formData.dob),
      };

      const response = await UpdateCustomer(
        formDataWithDate,
        toast,
        setLoading,
        "You will be re-directed to the dashboard"
      );

      if (response.success) {
        setLoading(false);
        toast.success("Update Successfull");
        setTimeout(() => {
          navigate("/medic-dashboard");
        }, 5000);
      } else {
        console.error("Failed to update user details:", response.error);
        toast.error("Failed to update user details");
      }
    } catch (error) {
      console.error("Failed to update user details:", error);
      toast.error("Error updating user details");
    }
  };
  const settingsContainerStyle = {
    animation: "slideInUp 0.9s ease-in-out",
  };

  const handleback = () => {
    navigate("/medic-settings");
  };

  return (
    <ChakraProvider theme={customTheme}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <MedicSideBar />
      <VStack
        style={settingsContainerStyle}
        position="fixed"
        ml={{ base: "10px", md: "180px" }}
        w="90%"
        h="100vh"
      >
        <MedicNavBar />
        <Flex
          display={{ base: "none", md: "flex" }}
          mt={{ md: "30px" }}
          ml={{ base: "50px", md: "40px" }}
          overflow="scroll"
        >
          <MedicSettingsSideBar />

          <Box ml="10px" spacing={-10}>
            <Text fontFamily="heading" fontWeight="bold" fontSize="20px">
              Edit profile
            </Text>
            <FormControl>
              <FormLabel fontFamily="body" fontSize="16px">
                First Name
              </FormLabel>
              <Input
                type="text"
                name="firstName"
                value={formData?.firstName}
                onChange={handleInputChange}
                borderColor="black"
                _hover={{ color: "" }}
              />
            </FormControl>
            <FormControl marginTop="15px">
              <FormLabel fontFamily="body" fontSize="16px">
                Last Name
              </FormLabel>
              <Input
                type="text"
                name="lastName"
                value={formData?.lastName}
                onChange={handleInputChange}
                borderColor="black"
                _hover={{ color: "" }}
              />
            </FormControl>
            <FormControl
              fontSize={{ base: "16px", md: "20px" }}
              fontFamily="body"
              isRequired
            >
              <FormLabel>Date of birth</FormLabel>
              <Input
                type="date"
                value={formData.dob}
                onChange={handleDOBChange}
                max={new Date().toISOString().split("T")[0]}
                name="dob"
                borderColor="black"
              />
            </FormControl>
            <FormControl marginTop="15px">
              <FormLabel fontFamily="body" fontSize="16px">
                Email Address
              </FormLabel>
              <Input
                type="email"
                name="email"
                value={formData?.email}
                onChange={handleInputChange}
                borderColor="black"
                _hover={{ color: "" }}
              />
            </FormControl>
            <FormControl marginTop="15px">
              <FormLabel fontSize="16px">Home Address</FormLabel>
              <Input
                type="text"
                name="address"
                value={formData?.address}
                onChange={handleInputChange}
                borderColor="black"
                _hover={{ color: "" }}
              />
            </FormControl>
            <FormControl marginTop="15px">
              <FormLabel>Gender </FormLabel>
              <Select
                name="gender"
                placeholder="Select your gender"
                value={formData?.gender}
                w="240px"
                borderColor="black"
                onChange={handleInputChange}
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </Select>
            </FormControl>
            <Button
              marginTop="10px"
              mb="50px"
              color="white"
              fontFamily="body"
              bg="#A210C6"
              onClick={handleOpenConfirmationModal}
              _hover={{ color: "white" }}
            >
              Save changes
            </Button>
          </Box>

          <Box marginLeft="30px" width="20%">
            <Text fontFamily="body" textAlign="left">
              Click on the image to select a new picture before the change
              picture button
            </Text>
            <Box
              borderRadius="10px"
              marginTop="30px"
              marginLeft="40px"
              p={3}
              h="150px"
              w="180px"
              bg="white"
              boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
            >
              <Avatar
                cursor="pointer"
                h="120px"
                w="100px"
                src={formData?.image}
                name={formData?.firstName}
                bg="#A210C6"
                onClick={handleClick}
              />
              <Input
                ref={inputRef}
                type="file"
                accept="image/*"
                onChange={(e) =>
                  handleImageChange(e.target.files[0], formData, setFormData)
                }
                hidden
                id="fileInput"
                name="image"
              />
              <Divider />

              <Box mt="30px">
                {imageLoading && <LoadingSpinner size={20} />}
                <Button
                  fontSize="15px"
                  ml="10px"
                  borderColor="#A210C6"
                  borderWidth="2px"
                  bg="white"
                  fontFamily="body"
                  _hover={{
                    bg: "gray.100",
                    borderColor: "purple.800",
                  }}
                  onClick={handleOpenConfirmationModal}
                >
                  Change picture
                </Button>
              </Box>
              <Button
                borderColor="#A210C6"
                borderWidth="2px"
                bg="white"
                mt="10px"
                ml="-25px"
                fontFamily="body"
                style={{}}
                _hover={{
                  bg: "gray.100",
                  borderColor: "purple.800",
                }}
                onClick={handlePhoneModalOpen}
              >
                Change phone number
              </Button>
            </Box>
          </Box>
        </Flex>

        <Flex
          overflow="scroll"
          display={{ base: "block", md: "none" }}
          mt={{ md: "30px" }}
          ml={{ base: "30px" }}
          mb={{ base: "60px" }}
          w="360px"
        >
          <Box mt="15px" justifyContent="right">
            <Button
              ml="80%"
              onClick={handleback}
              borderColor="#A210C6"
              borderWidth="1px"
              fontFamily="body"
              color="#A210C6"
              _hover={{ color: "" }}
              fontSize={{ base: "12px" }}
              h="4vh"
              borderRadius="100px"
            >
              Back
            </Button>
          </Box>

          <Flex p={3} h="80px" w="250px" bg="white" position="relative">
            <Box position="relative" w="80px" h="80px">
              <Image
                cursor="pointer"
                border="2px solid #A210C6"
                h="80px"
                w="80px"
                src={formData?.image}
                name={formData?.firstName}
                bg="#A210C6"
                onClick={handleClick}
                borderRadius="full"
              />
              <Icon
                as={FaCamera}
                boxSize={5}
                color="white"
                bg="#A210C6"
                borderRadius="full"
                position="absolute"
                bottom={1}
                right={1}
                transform="translate(25%, 25%)"
              />
              <Input
                ref={inputRef}
                type="file"
                accept="image/*"
                onChange={(e) =>
                  handleImageChange(e.target.files[0], formData, setFormData)
                }
                hidden
                id="fileInput"
                name="image"
              />
            </Box>
            {imageLoading && <LoadingSpinner size={20} />}
            <Flex ml={4}>
              <Text fontWeight="bold" fontSize="18px">
                {formData?.firstName}
              </Text>
              <Text ml="5px" fontWeight="bold" fontSize="18px">
                {formData?.lastName}
              </Text>
            </Flex>
          </Flex>
          <Text
            textAlign="left"
            fontSize={{ base: "18px" }}
            marginTop="30px"
            marginBottom="10px"
            fontWeight="bold"
            fontFamily="heading"
          >
            Edit Profile
          </Text>

          <VStack color="#00000080" spacing={-10}>
            <Box marginTop="15px" w="100%">
              <FormControl w="350px">
                <FormLabel fontFamily="body" fontSize="16px">
                  First Name
                </FormLabel>
                <Input
                  type="text"
                  name="firstName"
                  w="full"
                  value={formData?.firstName}
                  onChange={handleInputChange}
                  borderColor="black"
                  _hover={{ color: "" }}
                />

                <FormLabel fontFamily="body" marginTop="15px" fontSize="16px">
                  Last Name
                </FormLabel>
                <Input
                  type="text"
                  name="lastName"
                  w="full"
                  value={formData?.lastName}
                  onChange={handleInputChange}
                  borderColor="black"
                  _hover={{ color: "" }}
                />

                <FormControl
                  fontSize={{ base: "16px", md: "20px" }}
                  fontFamily="body"
                  isRequired
                >
                  <FormLabel>Date of birth</FormLabel>
                  <Input
                    type="date"
                    w="full"
                    value={formData.dob}
                    onChange={handleDOBChange}
                    max={new Date().toISOString().split("T")[0]}
                    name="dob"
                    borderColor="black"
                  />
                </FormControl>
                <FormLabel fontFamily="body" marginTop="15px" fontSize="16px">
                  Email Address
                </FormLabel>
                <Input
                  type="email"
                  name="email"
                  w="full"
                  value={formData?.email}
                  onChange={handleInputChange}
                  borderColor="black"
                  _hover={{ color: "" }}
                />
                <FormLabel marginTop="15px" fontSize="16px">
                  Home Address
                </FormLabel>
                <Input
                  type="text"
                  name="address"
                  w="full"
                  value={formData?.medicHomeAddress}
                  onChange={handleInputChange}
                  borderColor="black"
                  _hover={{ color: "" }}
                />
              </FormControl>
              <FormControl marginTop="15px">
                <FormLabel>Gender </FormLabel>
                <Select
                  borderColor="black"
                  name="gender"
                  placeholder="Select your gender"
                  w="full"
                  value={formData?.gender}
                  onChange={handleInputChange}
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </Select>
              </FormControl>

              <Button
                borderColor="#A210C6"
                borderWidth="2px"
                bg="white"
                fontFamily="body"
                color="#00000080"
                mt="20px"
                w="full"
                // style={{}}
                _hover={{
                  bg: "gray.100",
                  borderColor: "purple.800",
                }}
                onClick={handlePhoneModalOpen}
              >
                Change phone number
              </Button>
              <Divider />
              <Text
                color="#00000080"
                fontSize="lg"
                mt="6"
                mb="4"
                fontWeight="bold"
              >
                More Details
              </Text>

              <Box
                color="#00000080"
                mt="6"
                spacing={4}
                marginTop="20px"
                flex="1"
              >
                <FormLabel>Marital Status</FormLabel>
                <Select
                  name="maritalStatus"
                  placeholder="Select Marital Status"
                  value={formData.maritalStatus}
                  onChange={handleInputChange}
                >
                  {maritalStatuses.map((status) => (
                    <option key={status} value={status}>
                      {status}
                    </option>
                  ))}
                </Select>
                {formData.maritalStatus === "Married" && (
                  <Box mt={4}>
                    <FormLabel>Spouse's Full Name</FormLabel>
                    <Input
                      name="spouseFullName"
                      placeholder="Enter full name"
                      value={formData.spouseFullName}
                      onChange={handleInputChange}
                    />
                    <FormLabel mt={4}>Spouse's Phone Number</FormLabel>
                    <Input
                      name="spousePhoneNumber"
                      placeholder="Enter phone number"
                      value={formData.spousePhoneNumber}
                      onChange={handleInputChange}
                    />
                    <FormLabel mt={4}>Spouse's Email</FormLabel>
                    <Input
                      name="spouseEmail"
                      type="email"
                      placeholder="Enter email"
                      value={formData.spouseEmail}
                      onChange={handleInputChange}
                    />
                    <FormLabel mt={4}>Spouse's Home Address</FormLabel>
                    <Input
                      name="spouseHomeAddress"
                      placeholder="Enter home address"
                      value={formData.spouseHomeAddress}
                      onChange={handleInputChange}
                    />
                  </Box>
                )}
              </Box>

              <FormLabel marginTop="20px">
                Upload CV (only JPG and PDF files are accepted)
              </FormLabel>
              <Input
                name="cvCopy"
                type="file"
                onChange={(e) => {
                  setCvCopy(e.target.files[0]);
                  postCv(e.target.files[0], formData, setFormData);
                }}
              />
              {cvLoading && <LoadingSpinner size={20} />}
              <FormLabel marginTop="20px">
                Upload valid licence/ certificate for Certfied Nurse Assistant
                or any relevant certificate to your training (only JPG and PDF
                files are accepted)
              </FormLabel>
              <Input
                name="license"
                type="file"
                onChange={(e) => {
                  setLicense(e.target.files[0]);
                  postLicense(e.target.files[0], formData, setFormData);
                }}
              />
              {licenseLoading && <LoadingSpinner size={20} />}
              <Divider />

              <Text
                color="#00000080"
                fontSize="lg"
                mt="6"
                mb="4"
                fontWeight="bold"
              >
                Guarantor Details
              </Text>

              <FormControl color="#00000080" isRequired marginTop="20px">
                <Box display="flex" marginBottom="20px">
                  <Box flex="1" marginRight="10px">
                    <FormLabel>Guarantor's First Name</FormLabel>
                    <Input
                      name="guarantorFirstName"
                      placeholder="First Name"
                      value={formData.guarantorFirstName}
                      onChange={handleInputChange}
                      isRequired
                    />
                  </Box>
                  <Box flex="1" marginLeft="10px">
                    <FormLabel>Guarantor's Last Name</FormLabel>
                    <Input
                      name="guarantorLastName"
                      placeholder="Last Name"
                      value={formData.guarantorLastName}
                      onChange={handleInputChange}
                      isRequired
                    />
                  </Box>
                </Box>

                <Box display="flex" marginBottom="20px">
                  <Box flex="1" marginRight="10px">
                    <FormLabel>Guarantor's Email Address</FormLabel>
                    <Input
                      name="guarantorEmail"
                      placeholder="Email Address"
                      onChange={handleInputChange}
                      type="email"
                      value={formData.guarantorEmail}
                      isInvalid={emailErrors.guarantorEmailError}
                      isRequired
                    />
                    <FormErrorMessage>
                      {emailErrors.guarantorEmailError}
                    </FormErrorMessage>
                  </Box>
                  <Box flex="1" marginLeft="10px">
                    <FormLabel>Guarantor's Phone Number</FormLabel>
                    <Input
                      name="guarantorPhone"
                      value={formData.guarantorPhone}
                      placeholder="Phone Number"
                      onChange={handleInputChange}
                      isRequired
                    />
                  </Box>
                </Box>

                {/* Relationship with Guarantor Dropdown */}
                <Box marginBottom="20px">
                  <FormLabel>Relationship with Guarantor</FormLabel>
                  <Select
                    name="relationshipWithGuarantor"
                    placeholder="Select Relationship"
                    onChange={handleInputChange}
                    value={formData.relationshipWithGuarantor}
                    isRequired
                  >
                    <option value="Parent">Parent</option>
                    <option value="Sibling">Sibling</option>
                    <option value="Friend">Friend</option>
                    <option value="Spouse">Spouse</option>
                    <option value="Uncle/Aunt">Uncle/Aunt</option>
                    <option value="Cousin">Cousin</option>
                    <option value="Colleague">Colleague</option>
                    <option value="Mentor">Mentor</option>
                    <option value="Employer">Employer</option>
                    <option value="Other">Other</option>
                  </Select>
                </Box>

                <FormLabel>Guarantor's Home Address</FormLabel>
                <Input
                  name="guarantorHomeAddress"
                  placeholder="Home Address"
                  onChange={handleInputChange}
                  marginBottom="20px"
                  value={formData.guarantorHomeAddress}
                  isRequired
                />
                <Divider />

                <Text fontSize="lg" mt="6" mb="4" fontWeight="bold">
                  Last Employer Details
                </Text>
                <Box marginBottom="20px">
                  <FormLabel>Last Employer's Full Name</FormLabel>
                  <Input
                    name="lastEmployerFullName"
                    value={formData.lastEmployerFullName}
                    placeholder="Full Name"
                    onChange={handleInputChange}
                    isRequired
                  />
                </Box>

                <Box display="flex" marginBottom="20px">
                  <Box flex="1" marginRight="10px">
                    <FormLabel>Phone Number</FormLabel>
                    <Input
                      name="lastEmployerPhoneNumber"
                      placeholder="Phone Number"
                      value={formData.lastEmployerPhoneNumber}
                      onChange={handleInputChange}
                      isRequired
                    />
                  </Box>
                  <Box flex="1" marginLeft="10px">
                    <FormLabel>Email</FormLabel>
                    <Input
                      name="lastEmployerEmail"
                      placeholder="Email Address"
                      onChange={handleInputChange}
                      value={formData.lastEmployerEmail}
                      type="email"
                      isInvalid={emailErrors.lastEmployerEmailError}
                      isRequired
                    />
                    <FormErrorMessage>
                      {emailErrors.lastEmployerEmailError}
                    </FormErrorMessage>
                  </Box>
                </Box>

                <FormLabel>Employer/Hospital/Company Address</FormLabel>
                <Input
                  name="lastEmployerAddress"
                  placeholder="Address"
                  onChange={handleInputChange}
                  marginBottom="20px"
                  isRequired
                  value={formData.lastEmployerAddress}
                />
              </FormControl>

              <Button
                marginTop="10px"
                marginBottom="150px"
                color="white"
                fontFamily="body"
                bg="#A210C6"
                onClick={handleOpenConfirmationModal}
                _hover={{ color: "white" }}
              >
                Save changes
              </Button>
            </Box>
          </VStack>
        </Flex>
        <MedicMobileFooter />
      </VStack>

      <Modal
        isOpen={isConfirmationModalOpen}
        onClose={handleCloseConfirmationModal}
        size="sm"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontFamily="heading">Confirm Changes</ModalHeader>
          <ModalCloseButton />
          <ModalBody fontFamily="body">
            Are you sure you want to save the changes?
          </ModalBody>
          <Box display="flex" justifyContent="flex-end" p="2">
            <Button
              mr={3}
              onClick={handleCloseConfirmationModal}
              colorScheme="gray"
              color="#A210C6"
              fontFamily="body"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              color="white"
              fontFamily="body"
              bg="#A210C6"
              isLoading={loading}
            >
              Confirm
            </Button>
          </Box>
        </ModalContent>
      </Modal>
      <MedicPhone isOpen={isPhoneModalOpen} onClose={handlePhoneModalClose} />
      <VStack />
    </ChakraProvider>
  );
};
export default MedicEdithProfilePage;
