import React, { useState, useEffect } from "react";
import axios from "axios";
import LoadingSpinner from "../../utils/Spiner";
import { VStack, useToast, Box, Text, Flex, Image } from "@chakra-ui/react";
import CreditIcon from "../../assets/CreditIcon.svg";
import DebitIcon from "../../assets/DebitIcon.svg";
import { baseUrl } from "../../apiCalls/config";
import { formatDistanceToNow } from "date-fns";
import CustomerTransactionDetailsDrawer from "../sections/CustomerTransDetails";

export default function TransactionTab() {
  const toast = useToast();
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTransaction, setSelectedTransaction] = useState(null); 
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await axios.get(
          `${baseUrl}/api/wallets/transactions`,
          config
        );

        if (response.data) {
          const sortedTrans = response.data.data.sort(
            (a, b) => new Date(b.transactionDate) - new Date(a.transactionDate)
          );
          setTransactions(sortedTrans);
        } else {
          console.error("Failed to fetch transactions");
        }
      } catch (error) {
        console.error("Error fetching transactions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [toast]);

  const formatAmount = (amount) => {
    const num = Number(amount);
    return num.toLocaleString("en-US");
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case "PENDING":
        return { color: "yellow.500", bg: "yellow.100" };
      case "SUCCESS":
        return { color: "green.500", bg: "green.100" };
      case "FAILED":
        return { color: "red.500", bg: "red.100" };
      default:
        return { color: "gray.500", bg: "gray.100" };
    }
  };

  const handleTransactionClick = (transaction) => {
    setSelectedTransaction(transaction); 
    setIsDrawerOpen(true); 
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false); 
    setSelectedTransaction(null); 
  };

  return (
    <>
      <Box
        className="all-transaction"
        sx={{
          w: { base: "100%", md: "40vw" },
          h: { base: "60vh", md: "30vh" },
          overflowY: "auto",
          overflowX: "hidden",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          msOverflowStyle: "none", // IE and Edge
          scrollbarWidth: "none", // Firefox
        }}
      >
        <VStack align="start" spacing={4}>
          {loading ? (
            <LoadingSpinner />
          ) : transactions.length === 0 ? (
            <Text
              fontSize={{ base: "10px", md: "16px" }}
              ml={{ base: "10px", md: "35px" }}
            >
              You have no transaction yet.
            </Text>
          ) : (
            <Box>
              <Flex
                mt="-10px"
                mb="50px"
                w={{ base: "90vw", md: "600px" }}
                position="relative"
                ml={{ base: "40px", md: "-20px" }}
                justifyContent="space-between"
                bg="#D087E2"
                p={4}
                borderRadius="md"
                color="white"
                fontSize={{ base: "10px", md: "14px" }}
              >
                <Text ml={{ md: "40px" }} fontWeight="bold">
                  Name
                </Text>
                <Text fontWeight="bold">Amount</Text>
                <Text fontWeight="bold">Time</Text>
                <Text mr={{ md: "40px" }} fontWeight="bold">
                  Status
                </Text>
              </Flex>
              <Box
                mb={{ base: "50", md: "50px" }}
                w={{ base: "100vw", md: "600px" }}
                ml={{ base: "20px", md: "-16px" }}
                justifyContent="space-between"
                mt={{ base: "-50px", md: "-40px" }}
                align="start"
                spacing={4}
              >
                {transactions.map((transaction) => {
                  const statusStyle = getStatusStyle(transaction.status);

                  return (
                    <Box
                      w={{ base: "90vw", md: "550px", lg: "550px" }}
                      p={4}
                      borderBottom="1px solid #e2e8f0"
                      key={transaction.id}
                      onClick={() => handleTransactionClick(transaction)} 
                      cursor="pointer" 
                    >
                      <Flex
                        fontSize={{ base: "10px", md: "14px" }}
                        textAlign="left"
                        justifyContent="space-between"
                        wrap="wrap"
                      >
                        <Flex ml={{ base: "5px", md: "0px" }}>
                          <Image
                            src={
                              transaction.type === "CREDIT"
                                ? CreditIcon
                                : DebitIcon
                            }
                            w={{ base: "30px", md: "25px" }}
                            h={{ base: "15px", md: "25px" }}
                            borderRadius="100px"
                          />
                          <Text
                            ml={{ base: "-5px", md: "5px" }}
                            color={
                              transaction.type === "CREDIT"
                                ? "green.500"
                                : "red.500"
                            }
                            maxW={{ base: "80px", md: "100px" }}
                            wordWrap="break-word"
                          >
                            {transaction.type === "CREDIT"
                              ? "MH incoming payment"
                              : "MH outgoing payment"}
                          </Text>
                        </Flex>

                        <Text
                          ml={{ base: "-20px", md: "-20px" }}
                          color="black"
                          maxW={{ base: "80px", md: "100px" }}
                          wordWrap="break-word"
                        >
                          {formatAmount(transaction.amount)}
                        </Text>
                        <Text
                          ml={{ base: "8px", md: "50px" }}
                          color="black"
                          maxW={{ base: "80px", md: "100px" }}
                          wordWrap="break-word"
                        >
                          {formatDistanceToNow(
                            new Date(transaction.transactionDate),
                            {
                              addSuffix: true,
                            }
                          )}
                        </Text>
                        <Box
                          mr={{ base: "-30px", md: "-25px" }}
                          ml={{ md: "0px" }}
                          w={{ base: "60px", md: "80px" }}
                          h={{ base: "28px", md: "25px" }}
                          textAlign="center"
                          borderRadius="10px"
                          p="5px"
                          bg={statusStyle.bg}
                        >
                          <Text
                            fontSize={{ base: "10px", md: "12px" }}
                            color={statusStyle.color}
                            textAlign="center"
                            maxW={{ base: "50px", md: "100px" }}
                            wordWrap="break-word"
                          >
                            {transaction.status}
                          </Text>
                        </Box>
                      </Flex>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          )}
        </VStack>
      </Box>

      <CustomerTransactionDetailsDrawer
        isOpen={isDrawerOpen}
        onClose={handleCloseDrawer}
        transaction={selectedTransaction} 
      />
    </>
  );
}
