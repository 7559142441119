import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  InputLeftAddon,
  FormErrorMessage,
  Button,
  Select,
  Checkbox,
  extendTheme,
  Stack,
  Box,
  Text,
} from "@chakra-ui/react";
import "react-datepicker/dist/react-datepicker.css";
import LoadingSpinner from "../../utils/Spiner";

const customTheme = extendTheme({
  components: {
    Link: {
      baseStyle: { _focus: { boxShadow: "none" } },
    },
  },
  fonts: {
    body: "Montserrat, sans-serif",
    heading: "Gill Sans MT, sans-serif",
  },
});

const MedicRegistrationForm = ({
  formData,
  handleInputChange,
  handleDobChange,
  handleClick,
  show,
  handleSubmit,
  validationErrors,
  agreeToTerms,
  handleTermsChange,
  loading,
  imageLoading,
  setImage,
  postImage,
}) => {
  const [emailError, setEmailError] = useState("");

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    handleInputChange(e);

    if (name === "email") {
      if (!validateEmail(value)) {
        setEmailError("Invalid email format");
      } else {
        setEmailError("");
      }
    }
  };

  return (
    <Box theme={customTheme}>
      <form onSubmit={handleSubmit}>
        <Stack spacing={4} marginTop="20px">
          <FormControl
            color="#00000080"
            fontSize={{ base: "16px", md: "20px" }}
            fontFamily="body"
            isRequired
            isInvalid={validationErrors.firstName}
          >
            <FormLabel>First name</FormLabel>
            <Input
              name="firstName"
              placeholder="First name"
              onChange={handleInputChange}
            />
            {validationErrors.firstName && (
              <FormErrorMessage>
                <Text as="i">{validationErrors.firstName}</Text>
              </FormErrorMessage>
            )}
          </FormControl>

          <FormControl
            fontSize={{ base: "16px", md: "20px" }}
            fontFamily="body"
            isRequired
            isInvalid={validationErrors.lastName}
          >
            <FormLabel>Last name</FormLabel>
            <Input
              name="lastName"
              placeholder="Last name"
              onChange={handleInputChange}
            />
            {validationErrors.lastName && (
              <FormErrorMessage>
                <Text as="i">{validationErrors.lastName}</Text>
              </FormErrorMessage>
            )}
          </FormControl>

          <FormControl
            fontSize={{ base: "16px", md: "20px" }}
            fontFamily="body"
            isRequired
            isInvalid={validationErrors.email || emailError}
          >
            <FormLabel>Email address</FormLabel>
            <Input
              name="email"
              type="email"
              placeholder="Email"
              isRequired
              onChange={handleEmailChange}
            />
           
            {emailError && (
              <FormErrorMessage>
                <Text as="i">{emailError}</Text>
              </FormErrorMessage>
            )}
          </FormControl>

          <FormControl
            fontSize={{ base: "16px", md: "20px" }}
            fontFamily="body"
            isRequired
            isInvalid={validationErrors.phoneNumber}
          >
            <FormLabel>Phone number</FormLabel>
            <InputGroup>
              <InputLeftAddon children="+234" />
              <Input
                name="phoneNumber"
                type="tel"
                placeholder="Enter a valid Nigerian phone number"
                onChange={handleInputChange}
              />
            </InputGroup>
            {validationErrors.phoneNumber && (
              <FormErrorMessage>
                <Text as="i">{validationErrors.phoneNumber}</Text>
              </FormErrorMessage>
            )}
          </FormControl>

          <FormControl
            fontSize={{ base: "16px", md: "20px" }}
            fontFamily="body"
            isRequired
            isInvalid={validationErrors.gender}
          >
            <FormLabel>Gender</FormLabel>
            <Select
              name="gender"
              placeholder="Select your gender"
              onChange={handleInputChange}
            >
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </Select>
            {validationErrors.gender && (
              <FormErrorMessage>
                <Text as="i">{validationErrors.gender}</Text>
              </FormErrorMessage>
            )}
          </FormControl>

          <FormControl
            fontSize={{ base: "16px", md: "20px" }}
            fontFamily="body"
            isRequired
            isInvalid={validationErrors.dob}
          >
            <FormLabel>Date of birth</FormLabel>
            <Input
              type="date"
              value={formData.dob}
              onChange={handleDobChange}
              max={new Date().toISOString().split("T")[0]} 
              name="dob"
            />
            {validationErrors.dob && (
              <FormErrorMessage>
                <Text as="i">{validationErrors.dob}</Text>
              </FormErrorMessage>
            )}
          </FormControl>

          <FormControl
            fontSize={{ base: "16px", md: "20px" }}
            fontFamily="body"
          >
            <FormLabel marginTop="20px">
              Upload headshot (only PNG and JPG files are accepted)
            </FormLabel>
            <Input
              name="image"
              placeholder="Headshot"
              type="file"
              accept="image/*"
              onChange={(e) => {
                setImage(e.target.files[0]);
                postImage(e.target.files[0], formData);
              }}
            />
            {imageLoading && <LoadingSpinner size={20} />}
          </FormControl>

          <FormControl
            fontSize={{ base: "16px", md: "20px" }}
            fontFamily="body"
            isRequired
            isInvalid={validationErrors.password}
          >
            <FormLabel mt="4">Password</FormLabel>
            <InputGroup size="md">
              <Input
                name="password"
                pr="4.5rem"
                type={show ? "text" : "password"}
                placeholder="Enter password"
                onChange={handleInputChange}
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleClick}>
                  {show ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            </InputGroup>
            {validationErrors.password && (
              <FormErrorMessage>
                <Text as="i">{validationErrors.password}</Text>
              </FormErrorMessage>
            )}
          </FormControl>

          <FormControl
            fontSize={{ base: "16px", md: "20px" }}
            fontFamily="body"
            isRequired
            isInvalid={validationErrors.confirmPassword}
          >
            <FormLabel mt="4">Confirm password</FormLabel>
            <InputGroup size="md">
              <Input
                name="confirmPassword"
                pr="4.5rem"
                type={show ? "text" : "password"}
                placeholder="Confirm password"
                onChange={handleInputChange}
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleClick}>
                  {show ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            </InputGroup>
            {validationErrors.confirmPassword && (
              <FormErrorMessage>
                <Text as="i">{validationErrors.confirmPassword}</Text>
              </FormErrorMessage>
            )}
          </FormControl>

          <Checkbox
            fontFamily="body"
            isChecked={agreeToTerms}
            onChange={handleTermsChange}
            mt="4"
            style={{
              fontSize: "6px",
              fontStyle: "italic",
              cursor: "pointer",
            }}
          >
            Click to view and accept{" "}
            <Text as="span" color="#A210C6">
              Mikul Health's Terms and conditions
            </Text>
          </Checkbox>

          <Button
            mt="6"
            type="submit"
            w="full"
            bg="#A210C6"
            color="white"
            isLoading={loading}
            fontFamily="body"
            loadingText="Registering..."
          >
            Submit
          </Button>
        </Stack>
      </form>
    </Box>
  );
};

export default MedicRegistrationForm;
