import React, { useState } from "react";
import {
  Text,
  Button,
  Drawer,
  Flex,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Image,
  Link,
  extendTheme,
} from "@chakra-ui/react";
import { WarningIcon } from "@chakra-ui/icons";
import BookingImage from "../../assets/booking.webp";
import { NavLink } from "react-router-dom";
import TermsModal from "./TermsModal";

const customTheme = extendTheme({
  components: {
    Link: {
      baseStyle: {
        _focus: {
          boxShadow: "none",
        },
      },
    },
  },
  fonts: {
    body: "Montserrat, sans-serif",
    heading: "Gill Sans MT, sans-serif",
  },
});

const BookingInstructions = ({ isOpen, onClose }) => {
  const [isTermsOpen, setTermsOpen] = useState(false);

  return (
    <Drawer
      theme={customTheme}
      size={{ base: "sm", md: "lg" }}
      isOpen={isOpen}
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader color="#A210C6" fontFamily="heading">
          Just a quick note{" "}
          <WarningIcon mb="5px" w={10} h={10} color="yellow.400" />
        </DrawerHeader>
        <DrawerBody>
          <Text color="#00000080" fontFamily="body" mb="10px">
            All services under our <strong>Service Plan</strong> are monthly
            subscriptions with 24-hour or 8-hour (day) shifts and expire after
            one month, except for <strong>Short Home Visit</strong> and{" "}
            <strong>Custom Plans.</strong> If you would like to create a custom
            plan, click{" "}
            <NavLink
              to="/customize-service"
              style={{
                color: "#A210C6",
                fontWeight: "bold",
                fontStyle: "italic",
              }}
            >
              here
            </NavLink>{" "}
          </Text>
          <Text color="#00000080" fontFamily="body" mb="10px">
            Please note: All our cargivers are entitled to  <strong>4 days off in a month</strong> for
            service that are up to a month.
          </Text>

          <Text color="#00000080" fontFamily="body" mb="10px">
            For services outside Lagos, please contact our Admin{" "}
            <Link
              href="https://wa.me/2347032579006"
              color="#A210C6"
              fontWeight="bold"
              fontStyle="italic"
              isExternal
            >
              here
            </Link>
            .
          </Text>
          <Image
            borderRadius="20px"
            src={BookingImage}
            alt="Booking an Appointment"
            height={{ base: "600px", md: "auto" }}
            width="100%"
            objectFit="cover"
          />
        </DrawerBody>
        <DrawerFooter as={Flex} justifyContent="space-between">
          <Text color="#00000080" fontWeight="bold" fontStyle="italic">
            By continuing, you agree to our{" "}
            <Link
              color="#A210C6"
              textDecoration="underline"
              onClick={() => setTermsOpen(true)}
              cursor="pointer"
            >
              terms of service...
            </Link>
          </Text>
          <Button
            bg="linear-gradient(80deg, #A210C6, #E552FF)"
            color="white"
            onClick={onClose}
          >
            Continue
          </Button>
        </DrawerFooter>
      </DrawerContent>
      <TermsModal isOpen={isTermsOpen} onClose={() => setTermsOpen(false)} />
    </Drawer>
  );
};

export default BookingInstructions;
