import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { baseUrl } from "./apiCalls/config";

const InactivityLogout = () => {
  const navigate = useNavigate();
  const logoutTimer = useRef(null);
  const { user } = useSelector((state) => state.userReducer);

  useEffect(() => {
    if (!user?.email) return;

    const isAdminRole = (roles) => {
      const adminRoles = ["SUPER_ADMIN", "ADMIN", "ADMIN_SUPPORT"];
      return roles.some(role => adminRoles.includes(role));
    };

    const resetTimer = () => {
      clearTimeout(logoutTimer.current);
      const timeoutDuration = isAdminRole(user.setOfRole) ? 1800000 : 600000;
      logoutTimer.current = setTimeout(async () => {
        try {
          await axios.post(
            `${baseUrl}/angel/logout`,
            {},
            {
              params: {
                email: user.email,
              },
            }
          );
        } catch (error) {
          console.error("Logout failed:", error);
        }
        localStorage.removeItem("token");
        localStorage.removeItem("phoneNumber");
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      }, timeoutDuration); 
    };

    resetTimer();
    const events = ["click", "mousemove", "keypress", "scroll", "touchstart"];
    events.forEach((event) => window.addEventListener(event, resetTimer));
    return () => {
      clearTimeout(logoutTimer.current);
      events.forEach((event) => window.removeEventListener(event, resetTimer));
    };
  }, [navigate, user?.email, user?.setOfRole]); 

  return null;
};

export default InactivityLogout;
