import React from "react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Box,
  Text,
  Button,
  VStack,
  Divider,
} from "@chakra-ui/react";
import {
  exportTransactionAsPDF,
  exportTransactionAsExcel,
} from "../sections/admin/exportTransactionFunctions";

const CustomerTransactionDetailsDrawer = ({
  isOpen,
  onClose,
  transaction,
  selectedTransactions = [],
  handleSendReceipts,
  handleExportReceipts,
}) => {

  if (!transaction) return null;
  const handleExportClick = (format) => {
    const transactionsToExport =
      selectedTransactions.length > 0 ? selectedTransactions : [transaction];

    if (format === "pdf") {
      exportTransactionAsPDF(transactionsToExport);
    } else if (format === "excel") {
      exportTransactionAsExcel(transactionsToExport);
    }
  };

  return (
    <>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            Transaction Details
          </DrawerHeader>

          <DrawerBody>
            <VStack align="left" spacing={4}>
              <Box>
                <Text fontWeight="bold">Transaction ID:</Text>
                <Text>{transaction.id || "N/A"}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Appointment ID:</Text>
                <Text>{transaction.appointmentId || "N/A"}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Amount:</Text>
                <Text>₦{transaction.amount?.toLocaleString() || "N/A"}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Status:</Text>
                <Text>{transaction.status || "N/A"}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Method:</Text>
                <Text>{transaction.method || "N/A"}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Type:</Text>
                <Text>{transaction.type || "N/A"}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Beneficiary Name:</Text>
                <Text>{transaction.beneficiaryName || "N/A"}</Text>
              </Box>
              <Box>
                <Text fontWeight="bold">Benefactor Name:</Text>
                <Text>{transaction.benefactorName || "N/A"}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Narration:</Text>
                <Text>{transaction.narration || "N/A"}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Transaction Date:</Text>
                <Text>
                  {transaction.transactionDate
                    ? new Date(transaction.transactionDate).toLocaleString()
                    : "N/A"}
                </Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Reference:</Text>
                <Text>{transaction.reference || "N/A"}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Update Date:</Text>
                <Text>
                  {transaction.updateDate
                    ? new Date(transaction.updateDate).toLocaleString()
                    : "N/A"}
                </Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Subscription Payment:</Text>
                <Text
                  color={transaction.subscription ? "black" : "black"}
                >
                  {transaction.subscription ? "Yes" : "No"}
                </Text>
              </Box>
            </VStack>
          </DrawerBody>

          <DrawerFooter justifyContent="space-between">
            <Button
              colorScheme="green"
              onClick={() => handleExportClick("pdf")}
            >
              Export Receipt
            </Button>
            <Button bg="gray" color="white" onClick={onClose}>
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default CustomerTransactionDetailsDrawer;
