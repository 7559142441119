import React, { useState, useEffect } from "react";
import { Box, Flex, Text, HStack, IconButton } from "@chakra-ui/react";
import {
  AiOutlineUser,
  AiOutlineCalendar,
  AiOutlineDollarCircle,
  // AiOutlineHeart,
  AiOutlineMedicineBox,
  AiFillEye,
  AiFillEyeInvisible,
} from "react-icons/ai";
import axios from "axios";
import { useSpring, animated } from "@react-spring/web";
import { baseUrl } from "../../../apiCalls/config";

const formatNumber = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const StatisticsBar = () => {
  const [statistics] = useState([
    {
      id: 1,
      title: "Users",
      count: 0,
      percentage: "0%",
      bgColor: "#00C6F7",
      lastMonth: "Last Month",
      icon: AiOutlineUser,
    },
    {
      id: 2,
      title: "Bookings",
      count: 0,
      percentage: "0%",
      color: "black.500",
      bgColor: "yellow.400",
      lastMonth: "Last Month",
      icon: AiOutlineCalendar,
    },

    {
      id: 4,
      title: "Medics",
      count: 0,
      percentage: "0%",
      bgColor: "#6FBACA",
      lastMonth: "Last Month",
      icon: AiOutlineMedicineBox,
    },
    {
      id: 5,
      title: "Revenue",
      count: 0,
      percentage: "0%",
      bgColor: "linear-gradient(80deg, #A210C6, #E552FF)",
      lastMonth: "Last Month",
      icon: AiOutlineDollarCircle,
    },
    {
      id: 3,
      title: "COS",
      count: 0,
      percentage: "0%",
      bgColor: "red.400",
      lastMonth: "Last Month",
      icon: AiOutlineDollarCircle,
    },
  ]);

  const [fetchedData, setFetchedData] = useState(null);
  const [showRevenue, setShowRevenue] = useState(true);
  const [showCOS, setShowCOS] = useState(true); // New state for COS

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found, redirecting to login.");
        window.location.href = "/login";
        return;
      }

      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(`${baseUrl}/api/statistics`, config);
        const data = response.data[0];

        setFetchedData({
          totalUsers: data.totalUsers,
          totalAppointments: data.totalAppointments,
          totalCos: data.totalCostOfService,
          totalMedics: data.totalMedics,
          totalAmountMade: data.totalAmountMade,
        });
      } catch (error) {
        console.error("Error fetching statistics:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowRevenue(false);
      setShowCOS(false); // Automatically hide COS after the same interval
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const userSpring = useSpring({
    from: { value: 0 },
    value: fetchedData ? fetchedData.totalUsers : 0,
  });
  const appointmentSpring = useSpring({
    from: { value: 0 },
    value: fetchedData ? fetchedData.totalAppointments : 0,
  });

  const medicSpring = useSpring({
    from: { value: 0 },
    value: fetchedData ? fetchedData.totalMedics : 0,
  });
  const revenueSpring = useSpring({
    from: { value: 0 },
    value: fetchedData ? fetchedData.totalAmountMade : 0,
  });
  const cosSpring = useSpring({
    from: { value: 0 },
    value: fetchedData ? fetchedData.totalCos : 0,
  });

  return (
    <Flex justifyContent="space-between" w="100%" p={4}>
      {statistics.map((stat, index) => {
        const springValue =
          index === 0
            ? userSpring.value
            : index === 1
            ? appointmentSpring.value
            : index === 2
            ? medicSpring.value
            : index === 3
            ? revenueSpring.value
            : cosSpring.value;

        return (
          <Box
            key={stat.id}
            bg={stat.bgColor}
            borderRadius="20px"
            p={4}
            w="19%"
            color="white"
          >
            <Flex justifyContent="space-between" alignItems="center">
              <Text fontSize="lg" fontWeight="bold">
                {stat.title}
              </Text>
              <stat.icon size={24} />
            </Flex>
            <Flex flexWrap="wrap" alignItems="center" mt={2}>
              <Text fontSize="2xl" fontWeight="bold">
                <animated.span>
                  {(index === 4 && !showRevenue) || (index === 3 && !showCOS)
                    ? "xxxxx"
                    : springValue.to((val) =>
                        index === 4 || index === 3
                          ? `₦${formatNumber(val.toFixed(2))}`
                          : formatNumber(Math.floor(val))
                      )}
                </animated.span>
              </Text>
              {/* Toggle Button for Revenue */}
              {index === 4 && (
                <IconButton
                  aria-label="Toggle Revenue"
                  icon={showRevenue ? <AiFillEyeInvisible /> : <AiFillEye />}
                  onClick={() => setShowRevenue(!showRevenue)}
                  ml={2}
                  size="sm"
                  colorScheme="whiteAlpha"
                />
              )}
              {/* Toggle Button for COS */}
              {index === 3 && (
                <IconButton
                  aria-label="Toggle COS"
                  icon={showCOS ? <AiFillEyeInvisible /> : <AiFillEye />}
                  onClick={() => setShowCOS(!showCOS)}
                  ml={2}
                  size="sm"
                  colorScheme="whiteAlpha"
                />
              )}
            </Flex>
            <HStack justifyContent="space-between" mt={2}>
              <Text bg="rgba(255, 255, 255, 0.3)" borderRadius="10px" p={1}>
                {stat.percentage}
              </Text>
              <Text>{stat.lastMonth}</Text>
            </HStack>
          </Box>
        );
      })}
    </Flex>
  );
};

export default StatisticsBar;
