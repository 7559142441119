import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import LeftSideBar from "../authLayouts/MedicSideBar";
import { useSelector } from "react-redux";
import TransactionPinModal from "../sections/TransactionPinModal";
import { useSwipeable } from "react-swipeable";
import { SearchIcon, AddIcon, ChevronRightIcon } from "@chakra-ui/icons";
import AllTransactionTabs from "../../components/authLayouts/AllTransactionTabs";
import DebitTransactionTabs from "../../components/authLayouts/DebitTransaction";
import CreditTransactionTabs from "../../components/authLayouts/CreditTransaction";
import SearchTransactionModal from "../sections/SearchTransationByDate";
import WalletModal from "../sections/CreateWalletModal";
import { baseUrl } from "../../apiCalls/config";
import {
  ChakraProvider,
  VStack,
  Input,
  Button,
  Skeleton,
  useClipboard,
  Select,
  useToast,
  Spinner,
  Image,
  Box,
  Text,
  Flex,
  useDisclosure,
  FormControl,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  extendTheme,
  ModalBody,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  useMediaQuery,
  ModalCloseButton,
  Divider,
} from "@chakra-ui/react";
import RightArrow from "../../assets/HeadsUp.svg";
import NavBar from "../authLayouts/MedicNavBar";
import MobileFooter from "../authLayouts/MedicFooter";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReviewBankModal from "../sections/ReviewBankModal";
import WalletSummaryBox from "../sections/WalletSummaryBox";

const customTheme = extendTheme({
  components: {
    Link: {
      baseStyle: {
        _focus: {
          boxShadow: "none",
        },
      },
    },
  },
  fonts: {
    body: "Montserrat, sans-serif",
    heading: "Gill Sans MT, sans-serif",
  },
});

const HeadsUpModal = ({ isOpen, onClose, onBankTransfer }) => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const modalWidth = isLargerThan768 ? "400px" : "90vw";

  const openBankList = () => {
    onClose();
    onBankTransfer();
  };

  return (
    <Modal
      blockScrollOnMount={false}
      theme={customTheme}
      isOpen={isOpen}
      onClose={onClose}
      size={{ base: "sm", sm: "md", md: "lg" }}
      borderRadius="15px"
    >
      <ModalOverlay />
      <ModalContent
        width={modalWidth}
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <ModalCloseButton />
        <ModalBody
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          p={{ base: 2, md: 4 }}
        >
          <Image
            mt={{ base: "15px", md: "25px" }}
            w={{ base: "200px", md: "250px" }}
            h={{ base: "120px", md: "150px" }}
            src={RightArrow}
            alt="HeadsUp"
          />
          <Box>
            <Text
              textAlign="left"
              mt="10px"
              fontSize={{ base: "14px", md: "16px" }}
            >
              - Remember, by keeping your funds in your wallet, you earn *5%
              interest p.a* on your balance, helping your money grow over
              time.
            </Text>
            <Text
              textAlign="left"
              mt="15px"
              fontSize={{ base: "14px", md: "16px" }}
            >
              - The longer you save, the more you earn. Let your savings work
              for you, and consider leaving funds in your account to benefit
              from this interest.
            </Text>
          
          </Box>
          <Button
            mb="10px"
            mt="10px"
            bg="#A210C6"
            color="white"
            borderRadius="50px"
            onClick={openBankList}
          >
            Continue
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const ChooseBankModal = ({
  isOpen,
  onClose,
  onOnlinePayment,
  onOpenAddBankModal,
  onSelectBank,
}) => {
  const { user } = useSelector((state) => state.userReducer);
  const [banks, setBanks] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchBanks = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${baseUrl}/api/wallets/${user?.userId}/banks`
      );
      const fetchedBanks = Array.isArray(response.data.data)
        ? response.data.data
        : [];
      setBanks(fetchedBanks);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching banks:", error);
      setBanks([]);
      setLoading(false);
    }
  }, [user?.userId]);

  useEffect(() => {
    if (isOpen) {
      fetchBanks();
    }
  }, [isOpen, fetchBanks]);

  const handleBankClick = (bank) => {
    onSelectBank(bank);
    onOnlinePayment();
  };

  const handleAddBankClick = () => {
    onOpenAddBankModal();
    fetchBanks();
  };

  return (
    <Modal
      size={{ base: "sm", sm: "md", md: "lg" }}
      isOpen={isOpen}
      onClose={onClose}
      blockScrollOnMount={false}
      style={{ zIndex: 1400 }}
    >
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color="#A210C6" fontFamily="heading">
          Choose bank account
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody fontFamily="body">
          {loading ? (
            <Skeleton height="20px" my="10px" />
          ) : banks.length === 0 ? (
            <Box textAlign="center">
              <Text mt="20px">No bank found.</Text>
            </Box>
          ) : (
            <Box maxH="300px" overflowY="auto">
              {banks.map((bank) => (
                <Box key={bank.id}>
                  <Flex
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => handleBankClick(bank)}
                    justifyContent="space-between"
                  >
                    <Box p="5px">
                      <Text>{bank.bankName}</Text>
                      <Text>{bank.accountName}</Text>
                    </Box>
                    <ChevronRightIcon w="30px" h="45px" mt="10px" />
                  </Flex>
                  <Divider my={4} borderColor="gray.500" />
                </Box>
              ))}
            </Box>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            mt="10px"
            mb="10px"
            colorScheme="ghost"
            color="#A210C6"
            leftIcon={<AddIcon />}
            onClick={handleAddBankClick}
          >
            Add bank account
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const AddBankModal = ({ isOpen, onClose, onReviewBank }) => {
  const { user } = useSelector((state) => state.userReducer);
  const [bankList, setBankList] = useState([]);
  const [selectedBank, setSelectedBank] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountNumberWarning, setAccountNumberWarning] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [hasError, setHasError] = useState(false);
  const toast = useToast();

  const userId = user?.userId;

  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const token = localStorage.getItem("token");
        const link = `${baseUrl}/api/wallets/bank-list`;
        const response = await axios.get(link, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.status) {
          setBankList(response.data.data);
        } else {
          throw new Error("Failed to fetch bank list");
        }
      } catch (error) {
        toast.error("Error: " + error.message, {
          duration: 5000,
          isClosable: true,
        });
      }
    };

    if (isOpen) {
      fetchBanks();
    }
  }, [isOpen, toast]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const fetchAccountName = async () => {
      if (accountNumber.length === 10 && selectedBank) {
        setIsFetching(true);
        try {
          const response = await axios.get(
            `${baseUrl}/api/wallets/name-enquiry`,
            {
              params: {
                bankCode: selectedBank,
                accountNumber: accountNumber,
              },
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (response.status || response.data.data.account_name) {
            setAccountName(response.data.data.account_name);
            setHasError(false);
          } else {
            setAccountName(
              "Account name not found, please double check the number"
            );
            setHasError(true);
          }
        } catch (error) {
          toast.error("Error: " + error.message, {
            duration: 5000,
            isClosable: true,
          });
          setHasError(true);
        } finally {
          setIsFetching(false);
        }
      }
    };

    fetchAccountName();
  }, [accountNumber, selectedBank, toast, user?.token]);

  const handleAccountNumberChange = (e) => {
    const value = e.target.value;
    if (/^[0-9]{0,10}$/.test(value)) {
      setAccountNumber(value);
      if (value.length !== 10) {
        setAccountNumberWarning("Account number must be exactly 10 digits");
      } else {
        setAccountNumberWarning("");
      }
    }
  };

  const handleAddBank = () => {
    const selectedBankDetails = bankList.find(
      (bank) => bank.code === selectedBank
    );

    const newBank = {
      userId,
      bankName: selectedBankDetails ? selectedBankDetails.name : "",
      accountNumber,
      accountName,
      bankCode: selectedBank,
    };
    onReviewBank(newBank);
  };

  return (
    <Modal
      size={{ base: "sm", sm: "md", md: "lg" }}
      isOpen={isOpen}
      onClose={onClose}
      blockScrollOnMount={false}
      style={{ zIndex: 1400 }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color="#A210C6" fontFamily="heading">
          Add Bank Account
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Bank Name</FormLabel>
            <Select
              placeholder="Select bank"
              value={selectedBank}
              onChange={(e) => setSelectedBank(e.target.value)}
            >
              {bankList.map((bank) => (
                <option key={bank.code} value={bank.code}>
                  {bank.name}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Account Number</FormLabel>
            <Input
              value={accountNumber}
              onChange={handleAccountNumberChange}
              placeholder="Enter account number"
            />
            {accountNumberWarning && (
              <Text fontSize="sm" color="red.500" fontStyle="italic">
                {accountNumberWarning}
              </Text>
            )}
          </FormControl>
          {isFetching ? (
            <Spinner size="sm" mt={4} />
          ) : (
            accountName && (
              <Text
                mt={4}
                fontSize="sm"
                color={hasError ? "red.500" : "green.500"}
              >
                {accountName}
              </Text>
            )
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            bg="#A210C6"
            color="white"
            onClick={handleAddBank}
            ml={3}
            disabled={isFetching}
          >
            Save
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const WithdrawModal = ({ isOpen, onClose, onOpenConfirmation, setAmount }) => {
  const [inputAmount, setInputAmount] = useState("");
  const [pinModalOpen, setPinModalOpen] = useState(false);
  const [pinVerified, setPinVerified] = useState(false);
  const { user } = useSelector((state) => state.userReducer);
  const toast = useToast();

  const formatAmount = (value) => {
    const cleanedValue = value.replace(/[^0-9.]/g, "");
    const formattedValue = Number(cleanedValue).toLocaleString();
    return formattedValue;
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const formattedValue = formatAmount(inputValue);
    setInputAmount(formattedValue);
  };

  const handleWithdrawClick = () => {
    if (!pinVerified) {
      setPinModalOpen(true);
    } else {
      setAmount(inputAmount.replace(/,/g, ""));
      onOpenConfirmation();
    }
  };

  const handlePinSubmit = async (pin) => {
    const userId = user?.userId;

    if (user?.transactionPinCreated) {
      try {
        const response = await axios.post(
          `${baseUrl}/api/wallets/transaction-pin/match`,
          {
            userId,
            pin,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (response.data.success) {
          setPinVerified(true);
          setTimeout(() => {
            setPinModalOpen(false);
          }, 2000);
          setAmount(inputAmount.replace(/,/g, ""));
          onOpenConfirmation();
        } else {
          toast({
            title: "Error",
            description: "Incorrect transaction pin",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        }
      } catch (error) {
        toast({
          title: "Error",
          description: "Error verifying transaction pin",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
    } else {
      try {
        const response = await axios.post(
          `${baseUrl}/api/wallets/transaction-pin/create`,
          {
            userId,
            pin,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (response.data.success) {
          setPinVerified(true);
          setTimeout(() => {
            setPinModalOpen(false);
          }, 2000);
          setAmount(inputAmount.replace(/,/g, ""));
          onOpenConfirmation();
        } else {
          toast({
            title: "Error",
            description: "Failed to create transaction pin",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: "Error",
          description: "Error creating transaction pin",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <>
      <Modal
        size={{ base: "sm", sm: "md", md: "lg" }}
        isOpen={isOpen}
        onClose={onClose}
        style={{ zIndex: 1400 }}
        blockScrollOnMount={false}
      >
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          size={{ base: "sm", md: "md" }}
        />
        <ModalOverlay />
        <ModalContent
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          <ModalHeader textAlign="left" color="#A210C6" fontFamily="heading">
            Choose Amount
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody
            flexDirection="column"
            justifyContent="left"
            alignItems="left"
            p={{ base: 2, md: 4 }}
          >
            <FormControl mt="20px" fontFamily="body">
              <FormLabel fontFamily="body" textAlign="left">
                Input amount:
              </FormLabel>
              <Input
                type="text"
                value={inputAmount}
                border="1px solid black"
                placeholder="₦5,000"
                onChange={handleInputChange}
                width={{ base: "full", md: "auto" }}
              />
            </FormControl>
            <Button
              mt="10px"
              mb="20px"
              bg="#A210C6"
              color="white"
              onClick={handleWithdrawClick}
              width={{ base: "full", md: "auto" }}
            >
              Send
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>

      <TransactionPinModal
        isOpen={pinModalOpen}
        onClose={() => setPinModalOpen(false)}
        handleSubmit={handlePinSubmit}
      />
    </>
  );
};

const ConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  amount,
  bankDetails,
}) => {
  const [isConfirming, setIsConfirming] = useState(false);

  const formatAmount = (amount) => {
    const num = Number(amount);
    return num.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const calculateCharge = (amount) => {
    if (amount <= 5000) {
      return 10;
    } else if (amount <= 50000) {
      return 25;
    } else {
      return 50;
    }
  };

  const handleConfirm = async () => {
    setIsConfirming(true);
    await onConfirm();
    setIsConfirming(false);
  };

  // Debugging logs to ensure onClose is defined
  useEffect(() => {
    if (typeof onClose !== "function") {
      console.error("onClose is not a function or is undefined", onClose);
    }
  }, [onClose]);

  return (
    <Modal
      size={{ base: "sm", sm: "md", md: "lg" }}
      isOpen={isOpen}
      onClose={onClose}
      blockScrollOnMount={false}
      style={{ zIndex: 1400 }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color="#A210C6" fontFamily="heading">
          Confirmation
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody fontFamily="body" textAlign="left">
          <Text>
            You are about to transfer ₦{formatAmount(amount)} to{" "}
            {bankDetails?.accountName} ( {bankDetails?.accountNumber}{" "}
            {bankDetails?.bankName}).
          </Text>
          <Text mt="10px">
            A ₦{calculateCharge(amount)} transaction charge applies.
          </Text>
        </ModalBody>
        <ModalFooter justifyContent="center">
          <Button
            fontFamily="body"
            variant="ghost"
            color="gray.500"
            onClick={() => {
              if (typeof onClose === "function") {
                onClose();
              } else {
                console.error("onClose is not a function");
              }
            }}
            isDisabled={isConfirming}
          >
            Cancel
          </Button>
          <Button
            fontFamily="body"
            colorScheme="ghost"
            color="#A210C6"
            onClick={handleConfirm}
            isDisabled={isConfirming}
          >
            {isConfirming ? <Spinner size="sm" /> : "Confirm"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const MedicWalletPage = () => {
  const navigate = useNavigate();
  const [showFundWalletModal, setShowFundWalletModal] = useState(false);
  const [showBankTransferModal, setShowBankTransferModal] = useState(false);
  const [showOnlinePaymentModal, setShowOnlinePaymentModal] = useState(false);
  const [showAddBankModal, setShowAddBankModal] = useState(false);
  const [showReviewBankModal, setShowReviewBankModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [amount, setAmount] = useState("");
  const [reviewBankDetails, setReviewBankDetails] = useState(null);
  const [selectedBankDetails, setSelectedBankDetails] = useState(null);
  const { user } = useSelector((state) => state.userReducer);
  const accountNumber = user?.walletAccountNumber;
  const accountName = user?.walletBankName;
  const { hasCopied, onCopy } = useClipboard(accountNumber);
  const [loading, setLoading] = useState(true);
  const walletCreated = user?.walletCreated;
  const balance = user?.walletBalance;
  const walletTotalCredit = user?.walletTotalCredit;
  const walletTotalDebit = user?.walletTotalDebit;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showSearchTransactionsModal, setShowSearchTransactionsModal] =
    useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const handleOpenConfirmationModal = () => {
    setShowConfirmationModal(true);
  };

  const handleOpenSearchTransactionsModal = () => {
    setShowSearchTransactionsModal(true);
  };

  const handleCloseSearchTransactionsModal = () => {
    setShowSearchTransactionsModal(false);
  };

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [user]);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => setTabIndex((prev) => (prev + 1) % 3), // 3 tabs for All, Credit, Debit
    onSwipedRight: () => setTabIndex((prev) => (prev - 1 + 3) % 3),
    trackMouse: true, // Optionally enable mouse swipe for testing on desktop
  });

  const handleOpenFundWalletModal = () => {
    setShowFundWalletModal(true);
  };

  const handleCloseFundWalletModal = () => {
    setShowFundWalletModal(false);
  };

  const handleOpenBankTransferModal = () => {
    setShowBankTransferModal(true);
  };

  const handleCloseBankTransferModal = () => {
    setShowBankTransferModal(false);
  };

  const handleOpenOnlinePaymentModal = () => {
    setShowOnlinePaymentModal(true);
  };

  const handleCloseOnlinePaymentModal = () => {
    setShowOnlinePaymentModal(false);
  };

  const handleOpenAddBankModal = () => {
    handleCloseBankTransferModal();
    setShowAddBankModal(true);
  };

  const handleCloseAddBankModal = () => {
    setShowAddBankModal(false);
  };

  const handleOpenReviewBankModal = (bankDetails) => {
    setReviewBankDetails(bankDetails);
    setShowReviewBankModal(true);
  };

  const handleCloseReviewBankModal = () => {
    setShowReviewBankModal(false);
  };

  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  const handleConfirmWithdrawal = async () => {
    setLoading(true);
    const cleanAmount = amount.replace(/,/g, "");

    const transferRequest = {
      customerId: user?.userId,
      amount: cleanAmount,
      method: "WALLET",
      narration: "Transfer to bank",
      currency: "NGN",
      bankCode: selectedBankDetails?.bankCode,
      accountNumber: selectedBankDetails?.accountNumber,
      source: user?.walletId,
    };

    const apiUrl = `${baseUrl}/api/wallets/withdraw`;

    try {
      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.post(apiUrl, transferRequest, { headers });

      if (response.data.success) {
        setLoading(false);
        toast.success("Transfer initiated");
        setTimeout(() => {
          navigate("/medic-dashboard");
        }, 3000);
      } else {
        setLoading(false);
        const errorMessage = response.data.message || "Unknown failure";
        if (errorMessage === "Insufficient funds") {
          toast.error(
            "Insufficient funds. Please check your balance and try again."
          );
        } else {
          toast.error(errorMessage);
        }
      }
    } catch (error) {
      setLoading(false);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        const errorMessage = error.response.data.message;
        if (errorMessage === "Insufficient funds") {
          toast.error(
            "Insufficient funds. Please check your balance and try again."
          );
        } else {
          toast.error(errorMessage);
        }
      } else {
        toast.error("Error making transfer");
      }
    }
  };

  const formatAmount = (amount) => {
    const num = Number(amount);
    return num.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  return (
    <ChakraProvider theme={customTheme}>
      <LeftSideBar />
      <VStack
        style={{ animation: "slideInUp 0.9s ease-in-out" }}
        position="fixed"
        ml={{ md: "230px" }}
        w={{ base: "100%", md: "80%" }}
        h={{ base: "100%", md: "100%" }}
        {...swipeHandlers} // Add swipe handlers here
      >
        <NavBar />
        {loading ? (
          <Skeleton
            justifyContent="center"
            w={{ base: "375px", md: "70vw" }}
            h={{ base: "189px", md: "40vh" }}
            startColor="#E552FF"
            endColor="#870DA5"
            fadeDuration={0.6}
            borderRadius="20px"
          />
        ) : (
          <Box>
            <Flex
              marginTop="10px"
              border="1px solid gray"
              borderRadius="md"
              padding="3px"
              w={{ base: "88vw", md: "908px" }}
              h={{ base: "7vw", md: "5vh" }}
              ml={{ base: "8px", md: "15px" }}
              mb={{ base: "5px", md: "10px" }}
            >
              <Flex ml={{ md: "10px" }}>
                <SearchIcon boxSize={4} marginRight="10px" marginTop="5px" />
                <Text
                  fontSize={{ base: "10px", md: "14px" }}
                  fontFamily="body"
                  mt={{ md: "3px" }}
                  style={{ fontStyle: "italic", cursor: "pointer" }}
                  _hover={{ color: "#A210C6" }}
                  onClick={handleOpenSearchTransactionsModal}
                >
                  Search transaction by date
                </Text>
              </Flex>
            </Flex>
            <WalletSummaryBox
              walletCreated={walletCreated}
              balance={balance}
              formatAmount={formatAmount}
              accountName={accountName}
              accountNumber={accountNumber}
              hasCopied={hasCopied}
              onCopy={onCopy}
              handleOpenFundWalletModal={handleOpenFundWalletModal}
              onOpen={onOpen}
              walletTotalCredit={walletTotalCredit}
              walletTotalDebit={walletTotalDebit}
            />
            <Text
              mb={{ base: "10px", md: "25px" }}
              mt="20px"
              textAlign="left"
              fontFamily="heading"
              fontWeight="bold"
              fontSize={{ base: "16px", md: "22px" }}
            >
              Recent activity
            </Text>

            <Flex
              w={{ base: "90vw", md: "90%" }}
              ml={{ base: "0", md: "-100px" }}
              mt={{ base: "-10px", md: "-30px" }}
              justifyContent="center"
              className="transaction-tabs"
            >
              <VStack ml={{ base: "0", md: "0px" }} w="100%">
                <Tabs
                  colorScheme="purple.100"
                  mt={{ base: "" }}
                  index={tabIndex} // Set the current tab index
                  onChange={setTabIndex} // Update the tab index on change
                >
                  <TabList ml={{ base: "50px", md: "0" }}>
                    <Tab
                      fontSize={{ base: "12px", md: "16px" }}
                      color="#A210C6"
                      fontWeight="bold"
                    >
                      All
                    </Tab>

                    <Tab
                      fontSize={{ base: "12px", md: "16px" }}
                      color="green.500"
                      fontWeight="bold"
                    >
                      Credit
                    </Tab>

                    <Tab
                      fontSize={{ base: "12px", md: "16px" }}
                      color="red.500"
                      fontWeight="bold"
                      mr={{ base: "30px", md: "0" }}
                    >
                      Debit
                    </Tab>
                  </TabList>
                  <TabPanels
                    ml={{ base: "-25px", md: "0px" }}
                    overflow={{ base: "scroll" }}
                  >
                    <TabPanel>
                      <AllTransactionTabs />
                    </TabPanel>
                    <TabPanel>
                      <CreditTransactionTabs />
                    </TabPanel>
                    <TabPanel>
                      <DebitTransactionTabs />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
                <MobileFooter />
              </VStack>
            </Flex>
          </Box>
        )}
        <MobileFooter />
      </VStack>
      <HeadsUpModal
        isOpen={showFundWalletModal}
        onClose={handleCloseFundWalletModal}
        onBankTransfer={handleOpenBankTransferModal}
      />
      <ChooseBankModal
        isOpen={showBankTransferModal}
        onClose={handleCloseBankTransferModal}
        onOnlinePayment={handleOpenOnlinePaymentModal}
        onOpenAddBankModal={handleOpenAddBankModal}
        onSelectBank={setSelectedBankDetails}
      />
      <AddBankModal
        isOpen={showAddBankModal}
        onClose={handleCloseAddBankModal}
        onBankAdded={() => {
          setShowBankTransferModal(false);
          setShowBankTransferModal(true);
        }}
        onReviewBank={handleOpenReviewBankModal}
      />
      <ReviewBankModal
        isOpen={showReviewBankModal}
        onClose={handleCloseReviewBankModal}
        bankDetails={reviewBankDetails}
        onSave={() => {
          setShowReviewBankModal(false);
          setShowBankTransferModal(true);
        }}
      />

      <WithdrawModal
        isOpen={showOnlinePaymentModal}
        onClose={handleCloseOnlinePaymentModal}
        onOpenConfirmation={handleOpenConfirmationModal}
        setAmount={setAmount}
      />
      <ConfirmationModal
        isOpen={showConfirmationModal}
        onClose={handleCloseConfirmationModal}
        onConfirm={handleConfirmWithdrawal}
        amount={amount}
        bankDetails={selectedBankDetails}
      />
      <SearchTransactionModal
        isOpen={showSearchTransactionsModal}
        onClose={handleCloseSearchTransactionsModal}
      />
      <WalletModal isOpen={isOpen} onClose={onClose} />
    </ChakraProvider>
  );
};

export default MedicWalletPage;
