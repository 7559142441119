import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import * as XLSX from "xlsx";

export const exportTransactionAsPDF = (transactions) => {
  transactions.forEach((transaction) => {
    const doc = new jsPDF();

    // Add logo if needed
    const imgData =
      "https://res.cloudinary.com/dmfewrwla/image/upload/v1720388853/MH_logo.png";
    doc.addImage(imgData, "PNG", 10, 10, 30, 10);

    // Set Title
    doc.setTextColor("#A210C6");
    doc.setFontSize(20);
    doc.text("Transaction Receipt", 70, 30);

    // Filter the transaction fields to include only the desired ones
    const filteredTransaction = {
      Status: transaction.status || "N/A",
      Amount: transaction.amount?.toLocaleString() || "N/A",
      TransactionDate: transaction.transactionDate
        ? new Date(transaction.transactionDate).toLocaleString()
        : "N/A",
      Method: transaction.method || "N/A",
      Type: transaction.type || "N/A",
      Beneficiary: transaction.beneficiaryName || "N/A",
      Benefactor: transaction.benefactorName || "N/A",
      Reference: transaction.reference || "N/A",
      Narration: transaction.narration || "N/A",
    };

    // Convert the filtered transaction object to an array of key-value pairs for autoTable
    const tableData = Object.entries(filteredTransaction).map(([key, value]) => [
      key,
      value,
    ]);

    // Add Transaction Details
    doc.setTextColor("#000000");
    autoTable(doc, {
      startY: 40,
      body: tableData,
    });

    doc.save(`Transaction_Receipt_${transaction.id}.pdf`);
  });
};

export const exportTransactionAsExcel = (transactions) => {
  transactions.forEach(transaction => {
    const worksheet = XLSX.utils.json_to_sheet([transaction]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Transaction Receipt");
    XLSX.writeFile(workbook, `Transaction_Receipt_${transaction.id}.xlsx`);
  });
};
