import React from "react";
import { Box } from "@chakra-ui/react";
import { FaWhatsapp } from "react-icons/fa";

const WhatsAppButton = () => {
  const phoneNumber = "+2347032579006"; 
  const message = "Hello, I need assistance.";

  return (
    <Box
    position="fixed"
    bottom={["80px", "30px", "40px"]} 
    right={["20px", "30px", "40px"]} 
    // bg="#A210C6"
    bg="green.500"
    borderRadius="50%"
    p="10px" 
    zIndex="1000"
    cursor="pointer"
    _hover={{ bg: "green.600" }}
  >
      <a
        href={`https://wa.me/${phoneNumber}?text=${encodeURIComponent(
          message
        )}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaWhatsapp color="white" size="35px" />
      </a>
    </Box>
  );
};

export default WhatsAppButton;
