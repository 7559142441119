import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useDisclosure,
} from "@chakra-ui/react";
import axios from "axios";
import AppointmentDetailsDrawer from "../../sections/admin/AppointmentDetailsDrawer";
import { baseUrl } from "../../../apiCalls/config";

const RematchRequests = () => {
  const [rematchRequests, setRematchRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const fetchRematchRequests = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found, redirecting to login.");
        window.location.href = "/login";
        return;
      }

      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(
          `${baseUrl}/api/admin/rematch/all`,
          config
        );

        const sortedRequests = response.data.data.sort((a, b) => {
          return new Date(b.startDate) - new Date(a.startDate);
        });

        setRematchRequests(sortedRequests);
      } catch (error) {
        console.error("Error fetching rematch requests:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRematchRequests();
  }, []);

  const handleRowClick = (appointment) => {
    setSelectedAppointment(appointment.appointment);
    onOpen();
  };

  return (
    <Box maxH="400px" overflowY="auto">
      <Box bg="#4B4B4B" borderRadius="10px" p={4} color="white" w="100%">
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <Text
            css={{
              position: "sticky",
              top: 0,
              background: "#4B4B4B",
              zIndex: 1,
            }}
            fontSize="md"
            fontWeight="bold"
          >
            Rematch Requests
          </Text>
        </Flex>
        {loading ? (
          <Flex justifyContent="center" alignItems="center" h="200px">
            <Spinner size="xl" />
          </Flex>
        ) : rematchRequests.length > 0 ? (
          <Table variant="simple" colorScheme="whiteAlpha">
            <Thead
              css={{
                position: "sticky",
                top: 0,
                background: "#4B4B4B",
                zIndex: 1,
              }}
            >
              <Tr>
                <Th color="purple.200">Recipient Name</Th>
                <Th color="purple.200">Medic Name</Th>
                <Th color="purple.200">Start Date</Th>
                <Th color="purple.200">Reason</Th>
                <Th color="purple.200">Resolved</Th>
                <Th color="purple.200">Appointment ID</Th>
              </Tr>
            </Thead>
            <Tbody>
              {rematchRequests.map((request) => (
                <Tr
                  fontSize="13px"
                  _hover={{ bg: "#3B3B3B" }}
                  key={request.id}
                  onClick={() => handleRowClick(request)}
                  cursor="pointer"
                >
                  <Td>
                    {request.appointment.recipientFirstname}{" "}
                    {request.appointment.recipientLastname}
                  </Td>
                  <Td>{request.appointment.matchedMedic.fullName}</Td>
                  <Td>{new Date(request.startDate).toLocaleDateString()}</Td>
                  <Td  maxW="350px">{request.reason}</Td>
                  <Td color={request.resolved? "green.300" : "red.300"}>
                    {request.resolved ? "Yes" : "No"}
                  </Td>
                  <Td>{request.appointmentId}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : (
          <Text>No available rematch requests at the moment</Text>
        )}
      </Box>

      {/* Drawer for displaying appointment details */}
      {selectedAppointment && (
        <AppointmentDetailsDrawer
          isOpen={isOpen}
          onClose={onClose}
          appointment={selectedAppointment}
        />
      )}
    </Box>
  );
};

export default RematchRequests;
