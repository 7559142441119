import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Td,
  Checkbox,
  Button,
  useDisclosure,
  useToast,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Input,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PaymentDetailsDrawer from "./PaymentDetailsDrawer";
import { exportAsPDF, exportAsExcel } from "./exportFunctions";
import { baseUrl } from "../../../apiCalls/config";

const AllPayment = () => {
  const [paymentData, setPaymentData] = useState([]);
  const [originalPaymentData, setOriginalPaymentData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedPayments, setSelectedPayments] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [filterType, setFilterType] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());

  const {
    isOpen: isExportModalOpen,
    onOpen: openExportModal,
    onClose: closeExportModal,
  } = useDisclosure();

  const toast = useToast();

  useEffect(() => {
    fetchAllPayments();
  }, []);

  const fetchAllPayments = async () => {
    setLoading(true);

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, redirecting to login.");
      window.location.href = "/login";
      return;
    }

    try {
      const response = await axios.get(`${baseUrl}/api/admin/payments`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.success) {
        // Sort the payments by date, with the most recent on top
        const sortedData = response.data.data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        setPaymentData(sortedData);
        setOriginalPaymentData(sortedData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectPayment = (paymentId) => {
    if (selectedPayments.includes(paymentId)) {
      setSelectedPayments(selectedPayments.filter((id) => id !== paymentId));
    } else {
      setSelectedPayments([...selectedPayments, paymentId]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedPayments([]);
    } else {
      setSelectedPayments(paymentData.map((payment) => payment.id));
    }
    setSelectAll(!selectAll);
  };

  const handleSendReceipts = () => {
    alert(`Sending receipts: ${selectedPayments.join(", ")}`);
  };

  const handleExportReceipts = (format) => {
    const paymentsToExport = paymentData
      .filter((payment) => selectedPayments.includes(payment.id))
      .map((payment) => ({
        id: payment.id,
        appointmentId: payment.appointmentId || "N/A",
        transactionId: payment.transactionId || "N/A",
        amount: `₦${payment.amount?.toLocaleString() || "N/A"}`,
        paidAt: payment.paidAt
          ? new Date(payment.paidAt).toLocaleString()
          : "N/A",
        channel: payment.channel || "N/A",
        reference: payment.reference || "N/A",
        status: formatStatus(payment.status),
        refunded: payment.refunded ? "Yes" : "No",
      }));

    if (format === "pdf") {
      exportAsPDF(paymentsToExport);
      toast({
        title: "Exported as PDF",
        description:
          "The selected payment receipts have been exported as a PDF.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-left",
      });
    } else if (format === "excel") {
      exportAsExcel(paymentsToExport);
      toast({
        title: "Exported as Excel",
        description:
          "The selected payment receipts have been exported as an Excel file.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-left",
      });
    }

    closeExportModal();
  };

  const openDrawer = (payment) => {
    setSelectedPayment(payment);
    setIsDrawerOpen(true);
  };

  const formatStatus = (status) => {
    if (!status) return "N/A";
    const lowerCaseStatus = status.toLowerCase();
    return lowerCaseStatus.charAt(0).toUpperCase() + lowerCaseStatus.slice(1);
  };

  const getStatusColor = (status) => {
    if (!status) return "white";
    switch (status.toLowerCase()) {
      case "pending":
        return "yellow.200";
      case "completed":
        return "green.200";
      case "failed":
        return "red.300";
      default:
        return "white";
    }
  };

  const handleFilterChange = async (filterType) => {
    setFilterType(filterType);

    if (filterType === "half-payments") {
      fetchAllHalfPayments();
    } else {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found, redirecting to login.");
        window.location.href = "/login";
        return;
      }

      setLoading(true);
      try {
        let response;

        if (filterType === "date") {
          const formattedDate = selectedDate.toISOString().split("T")[0]; 
          response = await axios.get(
            `${baseUrl}/api/admin/payment-by-date?date=${formattedDate}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        } else if (filterType === "channel") {
          response = await axios.get(
            `${baseUrl}/api/admin/payments/by-channel?channel=${searchQuery}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        } else if (filterType === "status") {
          response = await axios.get(
            `${baseUrl}/api/admin/payments/by-status?status=${searchQuery}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        }

        if (response.data.success) {
          const sortedData = response.data.data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setPaymentData(sortedData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleClearSearch = () => {
    setFilterType(null);
    setSearchQuery("");
    setSelectedDate(new Date());
    setPaymentData(originalPaymentData);
  };

  const formatAmount = (amount) => {
    const num = Number(amount);
    return num.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const getPaidForValue = (payment) => {
    if (payment.costOfService) {
      return "Medic";
    } else if (payment.serviceCharge) {
      return "Appointment";
    }
    return "N/A";
  };

  const isFullPayment = (payment) => {
    return (
      <Text color={payment.fullPayment ? "green.200" : "yellow.200"}>
        {payment.fullPayment ? "Yes" : "No"}
      </Text>
    );
  };

  const fetchAllHalfPayments = async () => {
    setLoading(true);

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, redirecting to login.");
      window.location.href = "/login";
      return;
    }

    try {
      const response = await axios.get(`${baseUrl}/api/admin/half-payments`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.success) {
        const sortedData = response.data.data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        setPaymentData(sortedData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box bg="#4B4B4B" borderRadius="10px" p={4} color="white" w="100%">
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Text fontSize="md" fontWeight="bold">
          All Payments
        </Text>
        <Menu>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />} zIndex="10">
            Advanced Filter
          </MenuButton>
          <MenuList color="#4B4B4B" zIndex="20">
            <MenuItem onClick={() => setFilterType("date")}>
              Filter by Date
            </MenuItem>
            <MenuItem onClick={() => setFilterType("channel")}>
              Filter by Channel
            </MenuItem>
            <MenuItem onClick={() => setFilterType("status")}>
              Filter by Status
            </MenuItem>
            <MenuItem onClick={() => handleFilterChange("half-payments")}>
              Filter Half Payments
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>

      {filterType === "date" && (
        <Flex mb={4}>
          <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            dateFormat="yyyy-MM-dd"
          />
          <Button
            ml={2}
            onClick={() => handleFilterChange("date")}
            colorScheme="blue"
          >
            Search by Date
          </Button>
          <Button ml={2} onClick={handleClearSearch} colorScheme="gray">
            Clear
          </Button>
        </Flex>
      )}

      {filterType === "channel" && (
        <Flex mb={4}>
          <Input
            placeholder="Enter channel (wallet/card)"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleFilterChange("channel");
            }}
            maxW="220px"
            bg="white"
            color="black"
            borderRadius="md"
          />
          <Button
            ml={2}
            onClick={() => handleFilterChange("channel")}
            colorScheme="blue"
          >
            Search by Channel
          </Button>
          <Button ml={2} onClick={handleClearSearch} colorScheme="gray">
            Clear
          </Button>
        </Flex>
      )}

      {filterType === "status" && (
        <Flex mb={4}>
          <Input
            placeholder="Enter status (Pending/Success/Failed)"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleFilterChange("status");
            }}
            maxW="310px"
            bg="white"
            color="black"
            borderRadius="md"
          />
          <Button
            ml={2}
            onClick={() => handleFilterChange("status")}
            colorScheme="blue"
          >
            Search by Status
          </Button>
          <Button ml={2} onClick={handleClearSearch} colorScheme="gray">
            Clear
          </Button>
        </Flex>
      )}

      {filterType === "half-payments" && (
        <Flex mb={4}>
          <Button ml={2} onClick={handleClearSearch} colorScheme="gray">
            Clear
          </Button>
        </Flex>
      )}

      {selectedPayments.length > 0 && (
        <Flex justifyContent="flex-start" mb={4}>
          <Button colorScheme="blue" mr={2} onClick={handleSendReceipts}>
            Send Selected Receipt(s)
          </Button>
          <Button colorScheme="green" onClick={openExportModal}>
            Export Selected Receipt(s)
          </Button>
        </Flex>
      )}

      {loading ? (
        <Flex justifyContent="center" alignItems="center" h="200px">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <Box maxH="400px" overflowY="auto">
          <Table variant="simple" colorScheme="whiteAlpha">
            <Thead
              css={{
                position: "sticky",
                top: 0,
                background: "#4B4B4B",
                zIndex: 1,
              }}
            >
              <Tr>
                <Th color="#A210C6">
                  <Checkbox
                    colorScheme="blue"
                    isChecked={selectAll}
                    onChange={handleSelectAll}
                  />
                </Th>
                <Th color="purple.200">Channel</Th>
                <Th color="purple.200">Amount</Th>
                <Th color="purple.200">Status</Th>
                <Th color="purple.200">Paid At</Th>
                <Th color="purple.200">Full Payment</Th>
                <Th color="purple.200">Paid For</Th>
                <Th color="purple.200">Details</Th>
              </Tr>
            </Thead>
            <Tbody>
              {paymentData.map((payment) => (
                <Tr fontSize="13px" key={payment.id} _hover={{ bg: "#3B3B3B" }}>
                  <Td>
                    <Checkbox
                      colorScheme="blue"
                      isChecked={selectedPayments.includes(payment.id)}
                      onChange={(e) => {
                        e.stopPropagation();
                        handleSelectPayment(payment.id);
                      }}
                    />
                  </Td>
                  <Td>{payment.channel || "N/A"}</Td>
                  <Td>₦{formatAmount(payment.amount)}</Td>

                  <Td color={getStatusColor(payment.status)}>
                    {formatStatus(payment.status)}
                  </Td>
                  <Td>
                    {payment.createdAt
                      ? new Date(payment.createdAt).toLocaleString()
                      : "N/A"}
                  </Td>
                  <Td>{isFullPayment(payment)}</Td>
                  <Td>{getPaidForValue(payment)}</Td>
                  <Td>
                    <Button
                      size="sm"
                      colorScheme="blue"
                      onClick={() => openDrawer(payment)}
                    >
                      View
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}

      <PaymentDetailsDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        payment={selectedPayment}
      />
      <Modal isOpen={isExportModalOpen} onClose={closeExportModal}>
        <ModalOverlay />
        <ModalContent bg="#4B4B4B" color="white">
          <ModalHeader>Export Receipt</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Select the format to export the receipt:</Text>
          </ModalBody>
          <ModalFooter justifyContent="space-between">
            <Button
              colorScheme="green"
              onClick={() => handleExportReceipts("pdf")}
            >
              Export as PDF
            </Button>
            <Button
              colorScheme="blue"
              onClick={() => handleExportReceipts("excel")}
            >
              Export as Excel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default AllPayment;
