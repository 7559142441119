import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { baseUrl } from "../../../apiCalls/config";

const SuspendCustomerModal = ({ isOpen, onClose, customerId, isSuspended }) => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const handleSuspend = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, redirecting to login.");
      window.location.href = "/login";
      return;
    }
  
    setIsLoading(true);
    axios
      .post(
        `${baseUrl}/api/admin/suspendUser/${customerId}`,
        null,
        {
          params: { suspend: !isSuspended },
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          toast({
            title: isSuspended ? "User Activated" : "User Suspended",
            description: `The User has been successfully ${
              isSuspended ? "activated" : "suspended"
            }. Kindly refresh the page to see the update.`,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
          onClose();
          // Uncomment to reload the page after operation
          // setTimeout(() => {
          //   window.location.reload(); 
          // }, 5000);
        } else {
          toast({
            title: "Operation failed",
            description: `There was an error ${
              isSuspended ? "activating" : "suspending"
            } the customer.`,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        }
      })
      .catch((error) => {
        console.error(
          `Error ${isSuspended ? "activating" : "suspending"} user:`,
          error
        );
        toast({
          title: "Operation failed",
          description: `There was an error ${
            isSuspended ? "activating" : "suspending"
          } the customer.`,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  
  return (
    <Modal size="md" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="#4B4B4B" color="white">
        <ModalHeader>
          {isSuspended ? "Activate User" : "Suspend User"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontSize="14px">
            Are you sure you want to {isSuspended ? "activate" : "suspend"}{" "}
            this user?
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button variant="outline" colorScheme="red" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme={isSuspended ? "green" : "red"}
            isLoading={isLoading}
            onClick={handleSuspend}
          >
            {isSuspended ? "Activate" : "Suspend"} User
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SuspendCustomerModal;
