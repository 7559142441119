import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
} from "@chakra-ui/react";
import AppointmentDetailsDrawer from "../../sections/admin/AppointmentDetailsDrawer";

const formatDate = (dateString) => {
  if (!dateString) return "N/A";
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const SearchResultsModal = ({ isOpen, onClose, searchResults }) => {
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleRowClick = (appointment) => {
    setSelectedAppointment(appointment);
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
    setSelectedAppointment(null);
  };

  const determineStatus = (appointment) => {
    if (appointment.appointmentMatched) return { text: "Matched", color: "cyan" };
    if (appointment.appointmentActive) return { text: "Active", color: "green.300" };
    if (appointment.appointmentCompleted) return { text: "Completed", color: "blue.200" };
    if (appointment.appointmentPending) return { text: "Pending", color: "yellow.200" };
    return { text: "Unknown", color: "gray" };
  };

  const getPaymentStatusColor = (paid) => {
    return paid ? "green.200" : "red.300";
  };

  return (
    <>
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        size="6xl"
      >
        <ModalOverlay />
        <ModalContent bg="#4B4B4B" color="white">
          <ModalHeader>Search Results</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {searchResults.length > 0 ? (
              <Table variant="simple" colorScheme="whiteAlpha">
                <Thead>
                  <Tr>
                    <Th color="purple.200">Recipient Name</Th>
                    <Th color="purple.200">Service Plan</Th>
                    <Th color="purple.200">Payment Status</Th>
                    <Th color="purple.200">Medic Name</Th>
                    <Th color="purple.200">Start Date</Th>
                    <Th color="purple.200">End Date</Th>
                    <Th color="purple.200">Appointment Status</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {searchResults.map((appointment) => (
                    <Tr
                      key={appointment.id}
                      onClick={() => handleRowClick(appointment)}
                      cursor="pointer"
                      _hover={{ bg: "#3B3B3B" }}
                    >
                      <Td>{appointment.recipientFirstname} {appointment.recipientLastname}</Td>
                      <Td>{appointment.servicePlan}</Td>
                      <Td color={getPaymentStatusColor(appointment.paid)}>
                        {appointment.paid ? "Paid" : "Unpaid"}
                      </Td>
                      <Td>{appointment.matchedMedic?.fullName || "N/A"}</Td>
                      <Td>{formatDate(appointment.startDate)}</Td>
                      <Td>{formatDate(appointment.endDate)}</Td>
                      <Td color={determineStatus(appointment).color}>
                        {determineStatus(appointment).text}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            ) : (
              <Text textAlign="center">No results found</Text>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      {selectedAppointment && (
        <AppointmentDetailsDrawer
          isOpen={isDrawerOpen}
          onClose={handleDrawerClose}
          appointment={selectedAppointment}
        />
      )}
    </>
  );
};

export default SearchResultsModal;
