import React, { useState } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Box,
  Text,
  Divider,
  Button,
  VStack,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { generatePDF } from "../pdfUtils";
const ReportDetailsDrawer = ({ isOpen, onClose, report }) => {
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();
  const [isDownloading, setIsDownloading] = useState(false);
  const toast = useToast();
  if (!report) return null;

  const handleExportClick = () => {
    generatePDF(report, setIsDownloading, toast);
  };

  const formatMedicationTime = (medication) => {
    const parts = medication.split(",");
    const timePart = parts.find((part) => part.startsWith("Time:"));
    const time = timePart
      ? new Date(timePart.replace("Time:", ""))
      : new Date(NaN);
    return `${parts[0]}, ${parts[1]}, ${parts[2]}, ${
      !isNaN(time.getTime())
        ? `Time:${time.toLocaleString()}`
        : "Time:Invalid Date"
    }`;
  };

  return (
    <>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent bg="#4B4B4B" color="white">
          <DrawerCloseButton />
          <DrawerHeader>
            <Text fontSize="2xl" fontWeight="bold">
              Medical Report Details
            </Text>
          </DrawerHeader>

          <DrawerBody>
            <VStack align="left" spacing={4}>
              {/* Medic & Recipient Details */}
              <Box>
                <Text fontWeight="bold">Medic Full Name:</Text>
                <Text>{report.medicFullName}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Recipient Full Name:</Text>
                <Text>{report.recipientFullName}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Service Plan:</Text>
                <Text>{report.servicePlan}</Text>
              </Box>
              <Divider />

              {/* Vital Signs */}
              <Text fontSize="lg" fontWeight="bold">
                Vital Signs
              </Text>
              <Box>
                <Text fontWeight="bold">Temperature:</Text>
                <Text>{report.temperature}°C</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Blood Pressure:</Text>
                <Text>{report.bloodPressure}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Pulse:</Text>
                <Text>{report.pulse} bpm</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Blood Sugar:</Text>
                <Text>{report.bloodSugar} mg/dL</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">SpO2:</Text>
                <Text>{report.sp02}%</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Respiration:</Text>
                <Text>{report.respiration} breaths/min</Text>
              </Box>
              <Divider />

              {/* Emotional and Physical State */}
              <Text fontSize="lg" fontWeight="bold">
                Emotional & Physical State
              </Text>
              <Box>
                <Text fontWeight="bold">Emotional State:</Text>
                <Text>{report.emotionalState}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Physical State:</Text>
                <Text>{report.physicalState}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Pain Level:</Text>
                <Text>{report.painLevel}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Pain Location:</Text>
                <Text>{report.painLocation || "None"}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Skin Integrity:</Text>
                <Text>{report.skinIntegrity}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Appetite:</Text>
                <Text>{report.appetite}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Fluid Intake:</Text>
                <Text>{report.fluidIntake}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Urinary Elimination:</Text>
                <Text>{report.urinaryElimination}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Bowel Elimination:</Text>
                <Text>{report.bowelElimination}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Sleep Quality:</Text>
                <Text>{report.sleepQuality}</Text>
              </Box>
              <Divider />

              {/* Medications */}
              <Text fontSize="lg" fontWeight="bold">
                Medications
              </Text>
              <VStack align="start" spacing={1}>
                {report.medications.map((medication, index) => (
                  <Text key={index}>{formatMedicationTime(medication)}</Text>
                ))}
              </VStack>
              <Divider />

              {/* Activities */}
              <Text fontSize="lg" fontWeight="bold">
                Care Provided.
              </Text>
              <Box>
                <Text>{report.activities.join(", ")}</Text>
              </Box>
              <Divider />

              {/* Comments & Recommendations */}
              <Box>
                <Text fontWeight="bold">Comments:</Text>
                <Text>{report.comments || "None"}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Recommendations:</Text>
                <Text>{report.recommendations || "None"}</Text>
              </Box>

              {/* Report Image */}
              {report.image && (
                <>
                  <Divider />
                  <Box>
                    <Text fontWeight="bold">Report Image:</Text>
                    <Image
                      src={report.image}
                      alt="Report"
                      width="100%"
                      onClick={onModalOpen}
                      cursor="pointer"
                    />
                  </Box>
                </>
              )}
            </VStack>
          </DrawerBody>

          <DrawerFooter justifyContent="space-between">
            <Button
              variant="outline"
              colorScheme="blue"
              onClick={() => alert("Sending Report")}
            >
              Send Report
            </Button>
            <Button
              variant="outline"
              colorScheme="green"
              onClick={handleExportClick}
              isLoading={isDownloading} // Show loading state during download
            >
              Export Report
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      {/* Modal for Enlarged Image */}
      {report.image && (
        <Modal isOpen={isModalOpen} onClose={onModalClose} size="xl">
          <ModalOverlay />
          <ModalContent bg="#4B4B4B" color="white">
            <ModalHeader>Enlarged Report Image</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Image src={report.image} alt="Report" width="100%" />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default ReportDetailsDrawer;
