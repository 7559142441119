import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  InputGroup,
  InputLeftElement,
  Th,
  Td,
  Button,
} from "@chakra-ui/react";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { SearchIcon } from "@chakra-ui/icons";
import { baseUrl } from "../../../apiCalls/config";
import { formatDistanceToNow } from "date-fns";

const UnverifiedUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchEmail, setSearchEmail] = useState("");
  const [hasSearched, setHasSearched] = useState(false);
  const [deletingUserId, setDeletingUserId] = useState(null); 

  const fetchUnverifiedUsers = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, redirecting to login.");
      window.location.href = "/login";
      return;
    }

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${baseUrl}/api/admin/new-users-and-medics`,
        config
      );
      const data = response.data.data.map((item) => ({
        id: item.id,
        name: `${item.firstName} ${item.lastName}`,
        phoneNumber: item.phoneNumber,
        email: item.email,
        gender: item.gender,
        role: item.roles[0], 
        timestamp: new Date(item.createdAt).toLocaleString(),
        fullDetails: item,
      }));
      setUsers(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching unverified users:", error);
      setLoading(false);
    }
  };

  const handleDeleteUser = async (userId) => {
    setDeletingUserId(userId);
    const token = localStorage.getItem("token");
  
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
  
      await axios.post(`${baseUrl}/api/admin/delete-user/${userId}`, {}, config); 
      setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
      alert("User deleted successfully.");
    } catch (error) {
      console.error("Error deleting user:", error);
      alert("Failed to delete user.");
    } finally {
      setDeletingUserId(null); 
    }
  };
  
  const handleSearchOrClear = async () => {
    if (hasSearched) {
      setSearchEmail("");
      setHasSearched(false);
      fetchUnverifiedUsers();
    } else {
      if (searchEmail.trim() === "") return;

      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found, redirecting to login.");
        window.location.href = "/login";
        return;
      }

      setLoading(true);
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        };

        const response = await axios.get(
          `${baseUrl}/api/admin/user/searchByEmail-Id?idOrEmail=${encodeURIComponent(
            searchEmail
          )}`,
          config
        );
        const data = response.data.data;
        const user = {
          id: data.id,
          name: `${data.firstName} ${data.lastName}`,
          phoneNumber: data.phoneNumber,
          email: data.email,
          gender: data.gender,
          role: data.roles[0],
          timestamp: data.createdAt,
          fullDetails: data,
        };
        setUsers([user]);
        setHasSearched(true);
        setLoading(false);
      } catch (error) {
        console.error("Error searching user:", error);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchUnverifiedUsers();
  }, []);

  const handleInputChange = async (e) => {
    const query = e.target.value;
    setSearchEmail(query);
    if (query.trim() === "") {
      setHasSearched(false);
      fetchUnverifiedUsers();
    }
  };

  return (
    <Box bg="#4B4B4B" borderRadius="10px" p={4} color="white" w="100%">
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Text fontSize="md" fontWeight="bold">
          Unverified Users
        </Text>
      </Flex>
      <Flex mb={4}>
        <InputGroup>
          <Input
            placeholder="Search user by email or id"
            backgroundColor="#4B4B4B"
            color="white"
            borderRadius="10px"
            width="500px"
            value={searchEmail}
            onChange={handleInputChange}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleSearchOrClear();
            }}
          />
          <InputLeftElement
            children={<SearchIcon color="white" />}
            pointerEvents="none"
          />
        </InputGroup>
        <Box ml={4}>
          <Text
            as="button"
            bg="#00C6F7"
            color="white"
            borderRadius="10px"
            fontSize="12px"
            p={2}
            onClick={handleSearchOrClear}
          >
            {hasSearched ? "Clear" : "Search"}
          </Text>
        </Box>
      </Flex>
      {loading ? (
        <Flex justifyContent="center" alignItems="center" h="200px">
          <ClipLoader color="#00C6F7" />
        </Flex>
      ) : (
        <Box maxH="400px" overflowY="auto">
          <Table variant="simple" colorScheme="whiteAlpha">
            <Thead
              css={{
                position: "sticky",
                top: 0,
                background: "#4B4B4B",
                zIndex: 1,
              }}
            >
              <Tr>
                <Th color="purple.200">Name</Th>
                <Th color="purple.200">Phone Number</Th>
                <Th color="purple.200">Email</Th>
                <Th color="purple.200">Gender</Th>
                <Th color="purple.200">Role</Th>
                <Th color="purple.200">Timestamp</Th>
                <Th color="purple.200">Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {users.map((user, index) => (
                <Tr key={index} fontSize="13px" _hover={{ bg: "#3B3B3B" }}>
                  <Td>{user.name}</Td>
                  <Td>{user.phoneNumber}</Td>
                  <Td>{user.email}</Td>
                  <Td>{user.gender}</Td>
                  <Td>{user.role}</Td>
                  <Td>
                    {formatDistanceToNow(new Date(user.timestamp), {
                      addSuffix: true,
                    })}
                  </Td>
                  <Td>
                    <Button
                      size="sm"
                      colorScheme="red"
                      onClick={() => handleDeleteUser(user.id)}
                      isLoading={deletingUserId === user.id} 
                      loadingText="Deleting..."
                      spinnerPlacement="start"
                    >
                      Delete User
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}
    </Box>
  );
};

export default UnverifiedUsers;
