import React, { useState, useEffect } from "react";
import {
  Box,
  VStack,
  ChakraProvider,
  Skeleton,
  extendTheme,
  // Button,
  // useDisclosure,
} from "@chakra-ui/react";
import AOS from "aos";
import NavBar from "../../authLayouts/admin/AdminNavBar";
import LeftSideBar from "../../authLayouts/admin/AdminSideBar";
import StatisticsBar from "../../authLayouts/admin/StatisticsBar";
import StatisticsSection from "../../authLayouts/admin/StatisticsSection";
import AllPayments from "../../sections/admin/AllPayments";
import AllTransactions from "../../sections/admin/AllTransactions";
import PendingMedicPayment from "../../sections/admin/PendingMedicPayments";
import UnpaidAppointments from "../../sections/admin/UnpaidAppointments ";
// import RecordPaymentModal from "../../sections/admin/RecordPaymentModal "; 
import { GetCurrentAdmin } from "../../../apiCalls/UserApis";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SetUser } from "../../../redux/userSlice";

const customTheme = extendTheme({
  components: {
    Link: {
      baseStyle: {
        _focus: {
          boxShadow: "none",
        },
      },
    },
  },
  fonts: {
    body: "Montserrat, sans-serif",
    heading: "Gill Sans MT, sans-serif",
  },
});


const Finance = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  // const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      if (localStorage.getItem("token")) {
        try {
          const response = await GetCurrentAdmin();

          if (response.success) {
            dispatch(SetUser(response.data));
          } else {
            console.error("API request failed:", response.error);
          }
        } catch (error) {
          console.error("Error in GetCurrentAdmin API:", error);
          navigate("/login");
          window.location.reload();
        } finally {
          setLoading(false);
        }
      } else {
        navigate("/login");
        window.location.reload();
      }
    };

    fetchData();
  }, [navigate, dispatch]);

  return (
    <ChakraProvider theme={customTheme} overflow="hidden">
      <Box bg="#2C2C2C" minH="100vh">
        <LeftSideBar />
        <VStack
          ml={{ md: "260px" }}
          w={{ base: "100%", md: "80%" }}
          h={{ base: "100%", md: "100%" }}
        >
          <NavBar />
          {loading ? (
            <Skeleton
              ml={{ base: "5px", md: "0" }}
              w={{ base: "375px", md: "70vw" }}
              h={{ base: "189px", md: "80vh" }}
              startColor="#E552FF"
              endColor="#870DA5"
              fadeDuration={0.6}
              borderRadius="20px"
            />
          ) : (
            <Box>
              <Box w="100%" p={4}>
                <StatisticsBar />
              </Box>
              <Box w="100%" p={4}>
                <StatisticsSection />
              </Box>
              <Box w="100%" p={4}>
                <PendingMedicPayment />
              </Box>
              <Box w="100%" p={4}>
                <UnpaidAppointments />
              </Box>

              {/* <Box w="100%" p={4}>
                <Button bg="green.500" color="white" onClick={onOpen}>
                  Record Appointment Payment
                </Button>
              </Box>
              <RecordPaymentModal isOpen={isOpen} onClose={onClose} /> */}
              <Box w="100%" p={4}>
                <AllPayments />
              </Box>
              <Box w="100%" p={4}>
                <AllTransactions />
              </Box>
            </Box>
          )}
        </VStack>
      </Box>
    </ChakraProvider>
  );
};

export default Finance;
