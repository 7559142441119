import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  VStack,
  HStack,
  Box,
  Icon,
} from "@chakra-ui/react";
import {
  FaQuestionCircle,
  FaRegMoneyBillAlt,
  FaPiggyBank,
  FaCreditCard,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const LearnMoreModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate("/wallet");
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={{ base: "full", sm: "lg", md: "xl" }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color="#A210C6">Your MH Wallet</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={4}>
            Bonus: Earn a 5% annual interest rate on your MH Wallet.
          </Text>
          <VStack align="stretch" spacing={4}>
            <Box>
              <HStack spacing={2}>
                <Icon as={FaQuestionCircle} w={5} h={5} color="blue.500" />
                <Text fontWeight="bold">What is an MH Wallet?</Text>
              </HStack>
              <Text pl={7}>
                The MH Wallet is your dedicated fund for managing home care
                expenses, allowing you to allocate, save, and spend on necessary
                home care services.
              </Text>
            </Box>
            <Box>
              <HStack spacing={2}>
                <Icon as={FaRegMoneyBillAlt} w={5} h={5} color="green.500" />
                <Text fontWeight="bold">The Need for an MH Wallet</Text>
              </HStack>
              <Text pl={7}>
                Home care costs are substantial, and it's vital to ensure that
                these expenses don't compromise your financial stability. The MH
                Wallet acts as your financial safeguard.
              </Text>
            </Box>
            <Box>
              <HStack spacing={2}>
                <Icon as={FaPiggyBank} w={5} h={5} color="green.500" />
                <Text fontWeight="bold">Save & Earn</Text>
              </HStack>
              <Text pl={7}>
                Enhance your savings while preparing for all home care needs,
                earning a competitive 55px% interest annually.
              </Text>
            </Box>
            <Box>
              <HStack spacing={2}>
                <Icon as={FaCreditCard} w={5} h={5} color="pink.500" />
                <Text fontWeight="bold">Easy Payment</Text>
              </HStack>
              <Text pl={7}>
                Simplify payments for home care services and products with your
                MH Wallet, ensuring seamless transactions with healthcare
                providers.
              </Text>
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            bgGradient="linear(to-r, #A210C6, #E552FF)"
            borderRadius="100px"
            color="white"
            onClick={handleButtonClick}
          >
            Fund MH Wallet
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LearnMoreModal;
