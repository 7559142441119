import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { baseUrl } from "../../../apiCalls/config";

const CancelAppointmentModal = ({ isOpen, onClose, appointmentId }) => {
  const [reason, setReason] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  const handleCancelAppointment = async () => {
    if (!validateField()) {
      return;
    }
    setIsSubmitting(true);
  
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, redirecting to login.");
      window.location.href = "/login";
      return;
    }
  
    try {
      const response = await axios.post(
        `${baseUrl}/api/admin/cancelAppointment`,
        {
          appointmentId,
          reason,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.data.success) {
        toast({
          title: "Appointment Canceled",
          description: "The appointment has been canceled successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-left",
        });
        onClose();
      } else {
        throw new Error(
          response.data.message || "Failed to cancel the appointment."
        );
      }
    } catch (error) {
      toast({
        title: "Error",
        description:
          error.message || "An error occurred while canceling the appointment.",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setIsSubmitting(false);
    }
  };
  
  const validateField = () => {
    if (!reason) {
      toast({
        title: "Required Field",
        description: "Please fill in reason for cancellation. Kindly refresh for update",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "top-left",
      });
      return false;
    }
    return true;
  };


  return (
    <Modal isOpen={isOpen} onClose={onClose} size="sm">
      <ModalOverlay />
      <ModalContent bg="#4B4B4B" color="white">
        <ModalHeader>Cancel Appointment</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Textarea
           variant="outline"
            placeholder="Enter reason for cancellation"
            value={reason}
            onChange={handleReasonChange}
            rows={5}
            bg="#4B4B4B"
            color="white"
            isRequired 
          />
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          <Button
            colorScheme="red"
            variant="outline"
            onClick={handleCancelAppointment}
            isLoading={isSubmitting}
            loadingText="Cancelling"
          >
            Cancel
          </Button>
          <Button
            variant="outline"
            colorScheme="white"
            ml={3}
            onClick={onClose}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CancelAppointmentModal;
