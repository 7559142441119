import React, { useState } from "react";
import { baseUrl } from "../../apiCalls/config";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  extendTheme,
  Flex,
  Input,
  Link,
  FormControl,
  FormLabel,
  Select,
  InputGroup,
  InputRightElement,
  Icon,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import axios from "axios";
import { ChevronDownIcon, WarningIcon } from "@chakra-ui/icons";
import { MdLocationOn } from "react-icons/md";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WalletModal from "./CreateWalletModal";
import { toast as reactToast } from "react-toastify";
import TermsModal from "./TermsModal";

const customTheme = extendTheme({
  components: {
    Link: {
      baseStyle: {
        _focus: {
          boxShadow: "none",
        },
      },
    },
  },
  fonts: {
    body: "Montserrat, sans-serif",
    heading: "Gill Sans MT, sans-serif",
  },
});

const stateTownsMap = {
  Lagos: [
    "Ikeja",
    "Ogudu",
    "Berger",
    "Surulere",
    "Ikorodu",
    "Epe",
    "Badagry",
    "Yaba",
    "Victoria Island",
    "Lekki",
    "Lagos Island",
    "Ajah",
    "Sangotedo",
    "Agege",
    "Ikoyi",
    "Okota",
    "Mushin",
    "Iyana Ipaja",
    "Oshodi",
    "Isolo",
    "Ikotun",
    "Festac",
    "Ijesha",
    "Maryland",
    "Ojota",
  ],
  Abuja: [
    "Asokoro",
    "Maitama",
    "Gwarinpa",
    "Wuse",
    "Garki",
    "Jabi",
    "Central Business District (CBD)",
    "Lugbe",
    "Karu",
    "Kubwa",
    "Nyanya",
    "Lokogoma",
    "Apo",
    "Utako",
    "Gudu",
    "Kuje",
    "Dutse",
    "Bwari",
    "Mpape",
    "Galadimawa",
    "Life Camp",
    "Kaura",
    "Karmo",
    "Gwagwalada",
  ],
  Nasarawa: ["Lafia", "Keffi", "Akwanga", "Doma", "Nasarawa", "Karu"],
  Ibadan: [
    "Bodija",
    "Dugbe",
    "Iwo Road",
    "Challenge",
    "Ring Road",
    "Mokola",
    "Apata",
  ],
  Porthacourt: ["Diobu", "Trans Amadi", "Eleme", "GRA", "Rumuokoro", "Rivers"],
  Enugu: ["Enugu", "Nsukka", "Udi", "Oji River", "Ezeagu"],
  Ogun: [
    "Abeokuta ",
    "Ijebu Ode",
    "Ota (Otta)",
    "Sagamu ",
    "Ilaro ",
    "Ijebu Igbo",
    "Ayetoro ",
    "Sango ",
    "Ifo ",
    "Owode ",
    "Agbara ",
    "Ikenne ",
    "Ijoko ",
    "Odogbolu ",
    "Remo ",
    "Ado-Odo",
    "Ewekoro ",
    "Igbesa ",
    "Isara-Remo",
    "Imeko ",
    "Ijebu Remo",
  ],
  Calabar: ["Big Qua", "Marian", "Akpabuyo", "Odukpani", "Ikom"],
  Delta: ["Warri", "Asaba", "Ughelli", "Sapele", "Kwale"],
};

const RequestAppointmentModal = ({ isOpen, onClose }) => {
  const [isTermsOpen, setTermsOpen] = useState(false);
  const [appointmentType, setAppointmentType] = useState("");
  const [shift, setShift] = useState("");
  const [state, setState] = useState("");  // Added state field
  const [availableTowns, setAvailableTowns] = useState([]); // Dynamically set towns based on selected state
  const [preferredTown, setPreferredTown] = useState("");
  const [currentLocation, setLocation] = useState("");
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.userReducer);
  const verificationModal = useDisclosure();
  const {
    isOpen: isWalletModalOpen,
    onOpen: onOpenWalletModal,
    onClose: onCloseWalletModal,
  } = useDisclosure();
  const walletCreated = user?.walletCreated;

  // Function to handle state selection and dynamically update available towns
  const handleStateChange = (e) => {
    const selectedState = e.target.value;
    setState(selectedState);
    setAvailableTowns(stateTownsMap[selectedState] || []);
    setPreferredTown(""); // Reset town when state changes
  };

  const handleSubmit = async () => {
    if (!user.setOfRole.includes("VERIFIED_MEDIC")) {
      verificationModal.onOpen();
      onClose();
      return;
    }

    if (!appointmentType || !shift || !currentLocation || !preferredTown || !state) {
      toast.error("Please fill in all fields");
      return;
    }

    setLoading(true);
    const requestData = {
      appointmentType,
      shift,
      currentLocation,
      preferredTown,
      state,  // Add the selected state to the request data
      specialization: user?.medicType,
      medicId: user?.userId,
    };

    try {
      const token = localStorage.getItem("token");
      const apiUrl = `${baseUrl}/appointment/request`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.post(apiUrl, requestData, { headers });

      if (response.data.success) {
        reactToast.success(response.data.message);
        onClose();
      } else {
        reactToast.error(response.data.message);
      }
    } catch (error) {
      reactToast.error("Error requesting appointment");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={8000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Modal
        theme={customTheme}
        isOpen={isOpen}
        onClose={onClose}
        size={{ base: "sm", md: "md" }}
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent justifyContent="center" borderRadius="20px">
          <ModalHeader fontFamily="heading" color="#A210C6">
            {walletCreated ? "Request Appointment" : "Create Wallet"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {walletCreated ? (
              <>
                <FormControl isRequired>
                  <FormLabel fontFamily="body">
                    Choose Appointment Type
                  </FormLabel>
                  <InputGroup>
                    <Select
                      placeholder="Select appointment type"
                      value={appointmentType}
                      onChange={(e) => setAppointmentType(e.target.value)}
                    >
                      <option value="Elderly care">Elderly care</option>
                      <option value="Recovery care">Recovery care</option>
                      <option value="Short home visit">Short home visit</option>
                      <option value="Postpartum care">Postpartum care</option>
                      <option value="Nanny services">Nanny services</option>
                    </Select>
                    <InputRightElement pointerEvents="none">
                      <ChevronDownIcon color="gray.300" />
                    </InputRightElement>
                  </InputGroup>
                </FormControl>

                <FormControl isRequired mt="4">
                  <FormLabel fontFamily="body">Choose Shift</FormLabel>
                  <InputGroup>
                    <Select
                      placeholder="Select shift"
                      value={shift}
                      onChange={(e) => setShift(e.target.value)}
                    >
                      <option value="Day Shift(8hrs)">Day Shift(8hrs)</option>
                      <option value="Night Shift(12hrs)">Night Shift (12hrs)</option>
                      <option value="Live-in(24hrs)">Live-in (24hrs)</option>
                      <option value="Any">Any of the above</option>
                    </Select>
                    <InputRightElement pointerEvents="none">
                      <ChevronDownIcon color="gray.300" />
                    </InputRightElement>
                  </InputGroup>
                </FormControl>

                <FormControl isRequired mt="4">
                  <FormLabel fontFamily="body">Select State</FormLabel>
                  <InputGroup>
                    <Select
                      placeholder="Select state"
                      value={state}
                      onChange={handleStateChange} // Call handleStateChange on state selection
                    >
                      {Object.keys(stateTownsMap).map((state) => (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      ))}
                    </Select>
                    <InputRightElement pointerEvents="none">
                      <ChevronDownIcon color="gray.300" />
                    </InputRightElement>
                  </InputGroup>
                </FormControl>

                <FormControl isRequired mt="4">
                  <FormLabel fontFamily="body">City/Town</FormLabel>
                  <InputGroup>
                    <Select
                      isRequired
                      placeholder="Select preferred town"
                      fontSize={{ base: "14px", md: "16px" }}
                      value={preferredTown}
                      onChange={(e) => setPreferredTown(e.target.value)}
                      isDisabled={availableTowns.length === 0} // Disable if no towns available
                    >
                      {availableTowns.map((town) => (
                        <option key={town} value={town}>
                          {town}
                        </option>
                      ))}
                    </Select>
                    <InputRightElement pointerEvents="none">
                      <ChevronDownIcon color="gray.300" />
                    </InputRightElement>
                  </InputGroup>
                </FormControl>

                <FormControl isRequired mt="4">
                  <FormLabel fontFamily="body">Location</FormLabel>
                  <InputGroup>
                    <Input
                      placeholder="Enter your current location"
                      value={currentLocation}
                      onChange={(e) => setLocation(e.target.value)}
                    />
                    <InputRightElement pointerEvents="none">
                      <Icon as={MdLocationOn} color="gray.300" />
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
              </>
            ) : (
              <>
                <Text fontSize="sm" color="gray.500" mb={4}>
                  Please create a wallet before you can start earning.
                </Text>
                <Flex justifyContent="flex-end" mt={4}>
                  <Button
                    mb="20px"
                    bg="#A210C6"
                    color="white"
                    onClick={onOpenWalletModal}
                  >
                    Create Wallet
                  </Button>
                </Flex>
              </>
            )}
          </ModalBody>

          {walletCreated && (
            <ModalFooter justifyContent="center">
              <Text color="#00000080" fontWeight="bold" fontStyle="italic">
                By continuing, you agree to our{" "}
                <Link
                  color="#A210C6"
                  textDecoration="underline"
                  onClick={() => setTermsOpen(true)}
                  cursor="pointer"
                >
                  terms of service...
                </Link>
              </Text>
              <Button
                isLoading={loading}
                loadingText="Loading..."
                bgGradient="linear(to-r, #A210C6, #E552FF)"
                fontFamily="body"
                color="white"
                w={{ base: "auto", md: "200px" }}
                fontSize={{ base: "12px", md: "16px" }}
                borderRadius="100px"
                mr={3}
                onClick={handleSubmit}
              >
                {loading ? "Loading..." : "Request"}
              </Button>
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
      <WalletModal isOpen={isWalletModalOpen} onClose={onCloseWalletModal} />
      <Modal
        isOpen={verificationModal.isOpen}
        onClose={verificationModal.onClose}
        size={{ base: "sm", md: "md" }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <WarningIcon mr={2} w={8} h={8} color="yellow.500" />
            Verification Required
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            You are not verified to make an appointment request yet. Please wait
            for verification or contact admin support.
          </ModalBody>
          <ModalFooter>
            <Button
              bg="gray.500"
              color="white"
              mr={3}
              onClick={verificationModal.onClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <TermsModal isOpen={isTermsOpen} onClose={() => setTermsOpen(false)} />
    </>
  );
};

export default RequestAppointmentModal;
